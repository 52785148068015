import { Component, FC, PropsWithChildren, useEffect, useState } from 'react'

// an error boundary with a reset button
class ErrorBoundary extends Component<
  PropsWithChildren<Record<string, unknown>>,
  { hasError: boolean }
> {
  constructor(props: PropsWithChildren<Record<string, unknown>>) {
    super(props)
    this.state = { hasError: false }
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError)
      return (
        <div>
          Something went wrong.
          <button onClick={() => this.setState({ hasError: false })}>
            Reset
          </button>
        </div>
      )
    return this.props.children
  }
}

export default ErrorBoundary
