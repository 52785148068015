import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApiState } from 'infrastructure/types/api'
import {
  IDicosImageMeta,
  IGroundTruth,
  IGroundTruth2DItem,
  IGroundTruthData,
  IGroundTruthItem,
  MapGroundTruthsResponseToModels,
} from 'infrastructure/types/groundTruth'
import {
  approveGroundTruth,
  loadGroundTruth,
  loadGroundTruthList,
  loadRenderedImages,
  queryFilterOptions,
  queryIndex,
  queryIndexQueue,
  querySingleItem,
  reindexFromFile,
  renderImages,
  saveGroundTruth,
  saveRenderedImages,
  seedGroundTruth,
  seedGroundTruthEnabled,
} from './groundTruthActions'
import { IGroundTruthItemResponse } from 'infrastructure/api/response/groundTruth'
import { act } from 'react-dom/test-utils'
import { Renderers } from 'infrastructure/types/rendering'
import { renderOptionsWithViews } from './util'
import {
  DicosBucketCsvIndexCommand,
  TrackedDicos,
  TrackedFilterOptions,
} from './models'

// Model Viewer controls
export type ModelViewerOptions = {
  isolateThreats: boolean // toggle to display only the threats or the whole model
  renderStyle: 'iso' | 'mip' | 'isomip' | 'dvr' // Isosurface ray tracing or Maximum Intensity Projection ray tracing
  showBoundingBox: boolean
  highlightThreats: boolean
  autoRotate: boolean
  threshold: number
  maxThreshold: number
  colourMap: 'Viridis' | 'Gray' | 'RedBlue' | 'WhiteRedBlue' | 'FSS' | 'RGB'
}

export type RenderingState = {
  state: 'unknown' | 'rendering' | 'rendered' | 'rendering-failed'
  viewCount: number
  renderParametersName: Renderers
}
export interface IGroundTruthState {
  fetchState: IApiState
  files: {
    summary: IGroundTruth
    data?:
      | IGroundTruthData
      | 'not-found'
      | 'loading'
      | 'seeding'
      | 'seeding-failed'
    rendering: RenderingState[]
  }[]
  filesIdx: {
    [id: string]: number
  }
  trackedDicos: {
    items: TrackedDicos[]
    count: number
  }
  trackedDicosIndexQueue: DicosBucketCsvIndexCommand[]
  userTracking: {
    userEditingDicos: {
      bucket: string
      folder?: string
      name: string
      userId: string
    }[]
    userViewingImages: {
      bucket: string
      folder?: string
      name: string
      userId: string
      renderParametersName: string
      viewCount: number
    }[]
  }
  renderTracking: {
    rendering: {
      bucket: string
      folder?: string
      name: string
      renderParametersName: string
      viewCount: number
    }[]
  }
  filterOptions: TrackedFilterOptions
  bucketName?: string
  folderName?: string
  focusedFile?: {
    id: string
    saveState: 'ready' | 'is-saving' | 'saved' | 'error' | 'is-modified'
    dataEdit: IGroundTruthData
    undoSteps: IGroundTruthData[]
    redoSteps: IGroundTruthData[]
  }
  focusedRenderedImages?: IDicosImageMeta[]
  focusedRenderedImagesState?:
    | 'is-loading'
    | 'ready'
    | 'is-saving'
    | 'is-error'
    | 'is-saved'
  modelViewerOptions: ModelViewerOptions
  seedEnabled: boolean
}

const initialState: IGroundTruthState = {
  fetchState: {
    loading: false,
    error: false,
  },
  files: [],
  filesIdx: {},
  trackedDicos: {
    items: [],
    count: 0,
  },
  trackedDicosIndexQueue: [],
  userTracking: {
    userEditingDicos: [],
    userViewingImages: [],
  },
  renderTracking: {
    rendering: [],
  },
  filterOptions: {
    groundTruths: [],
    buckets: [],
    folders: [],
    legacyCsvFiles: [],
    expectedItemIds: [],
  },
  modelViewerOptions: {
    isolateThreats: false,
    renderStyle: 'iso',
    threshold: 0,
    maxThreshold: 10000,
    showBoundingBox: true,
    highlightThreats: false,
    autoRotate: false,
    colourMap: 'WhiteRedBlue',
  },
  seedEnabled: false,
}

const defaultRenderingState: RenderingState[] = renderOptionsWithViews.map(
  (r) => ({ state: 'unknown', ...r }),
)

const fileByIdx = (state: IGroundTruthState, id: string) => {
  const index = state.filesIdx[id]
  if (index == null) return undefined
  return state.files[index]
}

const fileDataByIdx = (state: IGroundTruthState, id: string) => {
  const file = fileByIdx(state, id)
  if (file == null) return undefined
  return file.data
}

const newGroundTruthLoaded = (
  state: IGroundTruthState,
  action: {
    payload: {
      id: string
      groundTruth: IGroundTruthItemResponse[]
    }
  },
) => {
  const index = state.filesIdx[action.payload.id]
  const focusedFileNext =
    state.focusedFile?.id === action.payload.id
      ? {
          ...state.focusedFile,
          dataEdit:
            state.focusedFile?.id === action.payload.id
              ? action.payload
              : state.focusedFile.dataEdit,
          isSaved: false,
        }
      : state.focusedFile

  if (index !== -1)
    return {
      ...state,
      fetchState: {
        error: false,
        loading: false,
      },
      files: state.files.map((gt, i) =>
        i === index
          ? {
              ...gt,
              data: action.payload,
              rendering: defaultRenderingState,
            }
          : gt,
      ),
      focusedFile: focusedFileNext,
    } as IGroundTruthState

  return {
    ...state,
    fetchState: {
      error: false,
      loading: false,
    },
    files: [{ summary: { id: action.payload.id }, data: action.payload }],
    filesIdx: { [action.payload.id]: state.files.length },
    focusedFile: focusedFileNext,
  } as IGroundTruthState
}

const groundTruthUpdateIntermediaryState = (
  state: IGroundTruthState,
  id: string,
  intState: 'loading' | 'not-found' | 'seeding' | 'seeding-failed',
) => {
  const index = state.filesIdx[id]
  if (index !== -1)
    return {
      ...state,
      files: state.files.map((gt, i) =>
        i === index
          ? {
              ...gt,
              data: intState,
            }
          : gt,
      ),
    } as IGroundTruthState

  return {
    ...state,
    files: [{ summary: { id: id }, data: intState }],
  } as IGroundTruthState
}

const stateFetching = (state: IGroundTruthState) => ({
  ...state,
  fetchState: {
    loading: true,
    error: false,
  },
})
const stateFetched = (state: IGroundTruthState) => ({
  ...state,
  fetchState: {
    loading: false,
    error: false,
  },
})
const stateFetchError = (state: IGroundTruthState) => ({
  ...state,
  fetchState: {
    loading: false,
    error: true,
  },
})

const slice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    selectGroundTruth: (state, action: PayloadAction<string>) => {
      return state
    },
    setFocusedFile: (state, action: PayloadAction<string>) => {
      const focusedFileData = fileDataByIdx(state, action.payload)
      if (focusedFileData == null)
        return {
          ...state,
          focusedFile: {
            id: action.payload,
            dataEdit: {
              id: action.payload,
              groundTruth: [],
              approvals: [],
              seeded: false,
            },
            saveState: 'ready' as const,
            undoSteps: [],
            redoSteps: [],
          },
        }
      if (
        focusedFileData === 'not-found' ||
        focusedFileData === 'loading' ||
        focusedFileData === 'seeding' ||
        focusedFileData === 'seeding-failed'
      )
        return {
          ...state,
          focusedFile: {
            id: action.payload,
            dataEdit: {
              id: action.payload,
              groundTruth: [],
              approvals: [],
              seeded: false,
            },
            saveState: 'ready' as const,
            undoSteps: [],
            redoSteps: [],
          },
        }
      return {
        ...state,
        focusedFile: {
          id: action.payload,
          dataEdit: focusedFileData,
          saveState: 'ready' as const,
          undoSteps: [],
          redoSteps: [],
        },
      }
    },
    resetFocusedFileSavedState: (state) => {
      if (state.focusedFile == null) return state
      return {
        ...state,
        files: state.files.map((gt) =>
          gt.summary.id === state.focusedFile?.id
            ? {
                ...gt,
                data:
                  gt.data === 'not-found' ||
                  gt.data === 'loading' ||
                  gt.data === 'seeding' ||
                  gt.data === 'seeding-failed'
                    ? gt.data
                    : {
                        ...gt.data,
                        groundTruth: state.focusedFile.dataEdit.groundTruth,
                      },
              }
            : gt,
        ),
        focusedFile: {
          ...state.focusedFile,
          saveState: 'ready' as const,
        },
      } as IGroundTruthState
    },
    editSetGroundTruths: (state, action: PayloadAction<IGroundTruthItem[]>) => {
      const groundTruths = action.payload.map((gt) => ({
        ...gt,
        x1: gt.x2 > gt.x1 ? gt.x1 : gt.x2,
        x2: gt.x2 > gt.x1 ? gt.x2 : gt.x1,
        y1: gt.y2 > gt.y1 ? gt.y1 : gt.y2,
        y2: gt.y2 > gt.y1 ? gt.y2 : gt.y1,
        z1: gt.z2 > gt.z1 ? gt.z1 : gt.z2,
        z2: gt.z2 > gt.z1 ? gt.z2 : gt.z1,
      }))

      if (state.focusedFile == null) return state
      const groundTruthNext = {
        ...state.focusedFile.dataEdit,
        groundTruth: groundTruths,
      }
      const undoSteps = [
        state.focusedFile.dataEdit,
        ...state.focusedFile.undoSteps.slice(0, 30),
      ]
      return {
        ...state,
        focusedFile: {
          ...state.focusedFile,
          saveState: 'is-modified' as const,
          dataEdit: groundTruthNext,
          undoSteps,
          redoSteps: [],
        },
      } as IGroundTruthState
    },
    editUndo: (state) => {
      if (state.focusedFile == null) return state
      const undoSteps = state.focusedFile.undoSteps
      if (undoSteps.length === 0) return state
      const groundTruthNext = undoSteps[0]
      return {
        ...state,
        focusedFile: {
          ...state.focusedFile,
          dataEdit: groundTruthNext,
          undoSteps: undoSteps.slice(1),
          redoSteps: [
            state.focusedFile.dataEdit,
            ...state.focusedFile.redoSteps,
          ],
        },
      } as IGroundTruthState
    },
    editRedo: (state) => {
      if (state.focusedFile == null) return state
      const redoSteps = state.focusedFile.redoSteps
      if (redoSteps.length === 0) return state
      const groundTruthNext = redoSteps[0]
      return {
        ...state,
        focusedFile: {
          ...state.focusedFile,
          dataEdit: groundTruthNext,
          undoSteps: [
            state.focusedFile.dataEdit,
            ...state.focusedFile.undoSteps,
          ],
          redoSteps: redoSteps.slice(1),
        },
      } as IGroundTruthState
    },
    editSetfocusedRenderedImageState: (
      state,
      action: PayloadAction<{ signedUrl: string } & IGroundTruth2DItem>,
    ) => {
      if (state.focusedRenderedImages == null) return state

      return {
        ...state,
        focusedRenderedImages: state.focusedRenderedImages.map((p, i) =>
          p.signedUrl === action.payload.signedUrl
            ? {
                ...p,
                groundTruth: p.groundTruth.map((gt) =>
                  gt.label === action.payload.label &&
                  gt.x1 === action.payload.x1 &&
                  gt.x2 === action.payload.x2 &&
                  gt.y1 === action.payload.y1 &&
                  gt.y2 === action.payload.y2
                    ? {
                        ...gt,
                        ignore: action.payload.ignore,
                      }
                    : gt,
                ),
                isModified: true,
              }
            : p,
        ),
        focusedRenderedImagesState: 'ready' as const,
      }
    },
    editSetModelViewerOptions: (
      state,
      action: PayloadAction<Partial<ModelViewerOptions>>,
    ) => {
      return {
        ...state,
        modelViewerOptions: {
          ...state.modelViewerOptions,
          ...action.payload,
          maxThreshold: Math.max(
            action.payload.maxThreshold ??
              initialState.modelViewerOptions.maxThreshold,
            action.payload.threshold ??
              initialState.modelViewerOptions.maxThreshold,
          ),
        },
      }
    },
    editResetModelViewerOptions: (state) => {
      return {
        ...state,
        modelViewerOptions: {
          ...initialState.modelViewerOptions,
        },
      }
    },
    userEditingItem: (
      state,
      action: PayloadAction<{
        bucket: string
        folder?: string
        name: string
        userId: string
      }>,
    ) => {
      return {
        ...state,
        userTracking: {
          ...state.userTracking,
          userEditingDicos: state.userTracking.userEditingDicos.concat(
            action.payload,
          ),
        },
      }
    },
    userEditingItemDone: (
      state,
      action: PayloadAction<{
        bucket: string
        folder?: string
        name: string
        userId: string
      }>,
    ) => {
      return {
        ...state,
        userTracking: {
          ...state.userTracking,
          userEditingDicos: state.userTracking.userEditingDicos.filter(
            (e) =>
              !(
                e.bucket === action.payload.bucket &&
                e.folder === action.payload.folder &&
                e.name === action.payload.name &&
                e.userId === action.payload.userId
              ),
          ),
        },
      }
    },
    userViewingRenders: (
      state,
      action: PayloadAction<{
        bucket: string
        folder?: string
        name: string
        renderParametersName: string
        viewCount: number
        userId: string
      }>,
    ) => {
      return {
        ...state,
        userTracking: {
          ...state.userTracking,
          userViewingImages: state.userTracking.userViewingImages.concat(
            action.payload,
          ),
        },
      }
    },
    userViewingRendersDone: (
      state,
      action: PayloadAction<{
        bucket: string
        folder?: string
        name: string
        renderParametersName: string
        viewCount: number
        userId: string
      }>,
    ) => {
      return {
        ...state,
        userTracking: {
          ...state.userTracking,
          userViewingImages: state.userTracking.userViewingImages.filter(
            (e) =>
              !(
                e.bucket === action.payload.bucket &&
                e.folder === action.payload.folder &&
                e.name === action.payload.name &&
                e.renderParametersName ===
                  action.payload.renderParametersName &&
                e.viewCount === action.payload.viewCount &&
                e.userId === action.payload.userId
              ),
          ),
        },
      }
    },
    beginRenderingItem: (
      state,
      action: PayloadAction<{
        bucket: string
        folder?: string
        name: string
        renderParametersName: string
        viewCount: number
      }>,
    ) => {
      return {
        ...state,
        renderTracking: {
          ...state.renderTracking,
          rendering: state.renderTracking.rendering.concat(action.payload),
        },
      }
    },
    renderingItemComplete: (
      state,
      action: PayloadAction<{
        bucket: string
        folder?: string
        name: string
        renderParametersName: string
        viewCount: number
      }>,
    ) => {
      return {
        ...state,
        renderTracking: {
          ...state.renderTracking,
          rendering: state.renderTracking.rendering.filter(
            (e) =>
              !(
                e.bucket === action.payload.bucket &&
                e.folder === action.payload.folder &&
                e.name === action.payload.name &&
                e.renderParametersName ===
                  action.payload.renderParametersName &&
                e.viewCount === action.payload.viewCount
              ),
          ),
        },
      }
    },
  },
  extraReducers: {
    [loadGroundTruthList.fulfilled.type]: (
      state,
      action: ReturnType<typeof loadGroundTruthList.fulfilled>,
    ) => {
      const stateWithIdxs = {
        ...state,
        filesIdx: action.payload.reduce((acc, item, i) => {
          // Note: we mutate here deliberately for performance reasons, as spreading 10k+ times is unworkable
          acc[item.name] = i
          return acc
        }, {} as { [id: string]: number }),
      }
      return stateFetched({
        ...stateWithIdxs,
        files: MapGroundTruthsResponseToModels(action.payload).map((gt) => ({
          summary: gt,
          data: fileDataByIdx(stateWithIdxs, gt.id),
          rendering: defaultRenderingState,
        })),

        bucketName: action.meta.arg.bucketName,
        folderName: action.meta.arg.folderName,
      }) as IGroundTruthState
    },
    [loadGroundTruthList.pending.type]: (state) => stateFetching(state),
    [loadGroundTruthList.rejected.type]: (state) => stateFetchError(state),
    [queryIndex.pending.type]: (state) => stateFetching(state),
    [queryIndex.rejected.type]: (state, action) => stateFetchError(state),
    [queryIndex.fulfilled.type]: (
      state,
      action: ReturnType<typeof queryIndex.fulfilled>,
    ) => {
      return stateFetched({
        ...state,
        trackedDicos: action.payload,
      })
    },
    [querySingleItem.fulfilled.type]: (
      state,
      action: ReturnType<typeof querySingleItem.fulfilled>,
    ) => {
      const next = state.trackedDicos.items.map((q) =>
        action.payload.bucket === q.bucket &&
        action.payload.folder === q.folder &&
        action.payload.name === q.name
          ? action.payload
          : q,
      )
      return {
        ...state,
        trackedDicos: {
          ...state.trackedDicos,
          items: next,
        },
      }
    },
    [queryIndexQueue.fulfilled.type]: (
      state,
      action: ReturnType<typeof queryIndexQueue.fulfilled>,
    ) => {
      return stateFetched({
        ...state,
        trackedDicosIndexQueue: action.payload,
      })
    },
    [queryFilterOptions.pending.type]: (state) => stateFetching(state),
    [queryFilterOptions.rejected.type]: (state) => stateFetchError(state),
    [queryFilterOptions.fulfilled.type]: (
      state,
      action: ReturnType<typeof queryFilterOptions.fulfilled>,
    ) => {
      return stateFetched({
        ...state,
        filterOptions: action.payload,
      })
    },
    [reindexFromFile.pending.type]: (state) => stateFetching(state),
    [reindexFromFile.rejected.type]: (state) => stateFetchError(state),
    [reindexFromFile.fulfilled.type]: (state) => stateFetched(state),
    [loadGroundTruth.fulfilled.type]: (
      state,
      action: ReturnType<typeof loadGroundTruth.fulfilled>,
    ) => newGroundTruthLoaded(state, action),
    [loadGroundTruth.pending.type]: (
      state,
      action: ReturnType<typeof loadGroundTruth.pending>,
    ) =>
      groundTruthUpdateIntermediaryState(state, action.meta.arg.id, 'loading'),
    [loadGroundTruth.rejected.type]: (
      state,
      action: ReturnType<typeof loadGroundTruth.rejected>,
    ) =>
      groundTruthUpdateIntermediaryState(
        state,
        action.meta.arg.id,
        'not-found',
      ),
    [seedGroundTruth.pending.type]: (
      state,
      action: ReturnType<typeof seedGroundTruth.pending>,
    ) =>
      groundTruthUpdateIntermediaryState(state, action.meta.arg.id, 'seeding'),
    [seedGroundTruth.rejected.type]: (
      state,
      action: ReturnType<typeof seedGroundTruth.rejected>,
    ) =>
      groundTruthUpdateIntermediaryState(
        state,
        action.meta.arg.id,
        'seeding-failed',
      ),
    [seedGroundTruth.fulfilled.type]: (
      state,
      action: ReturnType<typeof seedGroundTruth.fulfilled>,
    ) =>
      newGroundTruthLoaded(state, {
        payload: {
          groundTruth: action.payload.groundTruth,
          id: action.meta.arg.id,
        },
      }),
    [seedGroundTruthEnabled.fulfilled.type]: (
      state,
      action: ReturnType<typeof seedGroundTruthEnabled.fulfilled>,
    ) => ({
      ...state,
      seedEnabled: true,
    }),
    [saveGroundTruth.fulfilled.type]: (
      state,
      action: ReturnType<typeof saveGroundTruth.fulfilled>,
    ) => {
      const nextFocusedFile =
        state.focusedFile != null
          ? {
              ...state.focusedFile,
              saveState: 'saved' as const,
            }
          : undefined
      const idx = state.filesIdx[nextFocusedFile?.id ?? '']
      return stateFetched({
        ...state,
        files: state.files.map((gt, i) =>
          i === idx
            ? nextFocusedFile != null
              ? {
                  ...gt,
                  data: {
                    ...nextFocusedFile.dataEdit,
                  },
                }
              : gt
            : gt,
        ),
        focusedFile: nextFocusedFile,
      })
    },
    [saveGroundTruth.pending.type]: (
      state,
      action: ReturnType<typeof saveGroundTruth.pending>,
    ) => ({
      ...state,
      focusedFile:
        state.focusedFile != null
          ? {
              ...state.focusedFile,
              saveState: 'is-saving' as const,
            }
          : undefined,
    }),
    [saveGroundTruth.rejected.type]: (
      state,
      action: ReturnType<typeof saveGroundTruth.rejected>,
    ) => ({
      ...state,
      focusedFile:
        state.focusedFile != null
          ? {
              ...state.focusedFile,
              saveState: 'error' as const,
            }
          : undefined,
    }),
    [approveGroundTruth.fulfilled.type]: (
      state,
      action: ReturnType<typeof approveGroundTruth.fulfilled>,
    ) => {
      return {
        ...state,
        focusedFile:
          state.focusedFile != null
            ? {
                ...state.focusedFile,
                saveState: 'saved' as const,
              }
            : undefined,
      }
    },
    [approveGroundTruth.pending.type]: (
      state,
      action: ReturnType<typeof approveGroundTruth.pending>,
    ) => {
      return {
        ...state,
        focusedFile:
          state.focusedFile != null
            ? {
                ...state.focusedFile,
                saveState: 'is-saving' as const,
              }
            : undefined,
      }
    },
    [approveGroundTruth.rejected.type]: (
      state,
      action: ReturnType<typeof approveGroundTruth.rejected>,
    ) => {
      return {
        ...state,
        focusedFile:
          state.focusedFile != null
            ? {
                ...state.focusedFile,
                saveState: 'error' as const,
              }
            : undefined,
      }
    },
    [loadRenderedImages.fulfilled.type]: (
      state,
      action: ReturnType<typeof loadRenderedImages.fulfilled>,
    ) => ({
      ...state,
      focusedRenderedImages: action.payload.map((p) => ({
        ...p,
        isModified: false,
      })),
      focusedRenderedImagesState: 'ready' as const,
    }),
    [loadRenderedImages.pending.type]: (
      state,
      action: ReturnType<typeof loadRenderedImages.pending>,
    ) => ({
      ...state,
      focusedRenderedImages: undefined,
      focusedRenderedImagesState: 'is-loading' as const,
    }),
    [loadRenderedImages.rejected.type]: (
      state,
      action: ReturnType<typeof loadRenderedImages.rejected>,
    ) => ({
      ...state,
      focusedRenderedImages: undefined,
      focusedRenderedImagesState: 'is-error' as const,
    }),
    [saveRenderedImages.pending.type]: (
      state,
      action: ReturnType<typeof saveRenderedImages.pending>,
    ) => ({
      ...state,
      focusedRenderedImagesState: 'is-saving' as const,
    }),
    [saveRenderedImages.rejected.type]: (
      state,
      action: ReturnType<typeof saveRenderedImages.rejected>,
    ) => ({
      ...state,
      focusedRenderedImagesState: 'is-error' as const,
    }),
    [saveRenderedImages.fulfilled.type]: (
      state,
      action: ReturnType<typeof saveRenderedImages.fulfilled>,
    ) => ({
      ...state,
      focusedRenderedImages: state.focusedRenderedImages?.map((p) => ({
        ...p,
        isModified: false,
      })),
      focusedRenderedImagesState: 'is-saved' as const,
    }),
    [renderImages.pending.type]: (
      state,
      action: ReturnType<typeof renderImages.pending>,
    ) => ({
      ...state,
      files: state.files.map((gt) =>
        gt.summary.id === action.meta.arg.id
          ? {
              ...gt,
              rendering: gt.rendering.map((r) =>
                r.viewCount === action.meta.arg.viewCount &&
                r.renderParametersName === action.meta.arg.renderParametersName
                  ? { ...r, state: 'rendering' as const }
                  : r,
              ),
            }
          : gt,
      ),
    }),
    [renderImages.rejected.type]: (
      state,
      action: ReturnType<typeof renderImages.rejected>,
    ) => ({
      ...state,
      files: state.files.map((gt) =>
        gt.summary.id === action.meta.arg.id
          ? {
              ...gt,
              rendering: gt.rendering.map((r) =>
                r.viewCount === action.meta.arg.viewCount &&
                r.renderParametersName === action.meta.arg.renderParametersName
                  ? { ...r, state: 'rendering-failed' as const }
                  : r,
              ),
            }
          : gt,
      ),
    }),
    [renderImages.fulfilled.type]: (
      state,
      action: ReturnType<typeof renderImages.fulfilled>,
    ) => ({
      ...state,
      files: state.files.map((gt) =>
        gt.summary.id === action.meta.arg.id
          ? {
              ...gt,
              rendering: gt.rendering.map((r) =>
                r.viewCount === action.meta.arg.viewCount &&
                r.renderParametersName === action.meta.arg.renderParametersName
                  ? { ...r, state: 'rendered' as const }
                  : r,
              ),
            }
          : gt,
      ),
    }),
  },
})

export const { actions, reducer } = slice
