import { useOidc } from '@axa-fr/react-oidc'
import { useNavigate } from 'react-router-dom'
import { HeaderBar, HeaderContent, HeaderLeft, HeaderRight } from './styles'

export const Header = () => {
  const navigate = useNavigate()
  const { logout } = useOidc()

  return (
    <>
      <HeaderBar>
        <HeaderContent>
          <HeaderLeft className="clickable" onClick={() => navigate('/')}>
            <img src="/icon.png"></img>
            <div>Bootcamp</div>
          </HeaderLeft>

          <HeaderRight>
            <div className="clickable" onClick={() => logout()}>
              Log Out
            </div>
          </HeaderRight>
        </HeaderContent>
      </HeaderBar>
    </>
  )
}
