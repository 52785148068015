import { createSlice } from '@reduxjs/toolkit'
import { IApiState } from 'infrastructure/types/api'
import { ITag } from 'infrastructure/types/tag'
import { toast } from 'react-toastify'
import { addTag, deleteTag, loadTags } from './tagsActions'

interface ITagsState {
  fetchState: IApiState
  upsertState: IApiState
  tags: ITag[]
}

const initialState: ITagsState = {
  fetchState: {
    loading: true,
    error: false,
  },
  upsertState: {
    loading: false,
    error: false,
  },
  tags: [],
}

const slice = createSlice({
  name: 'tags',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadTags.pending, (state) => {
      state.fetchState.loading = true
      state.fetchState.error = false
    })
    builder.addCase(loadTags.fulfilled, (state, action) => {
      state.fetchState.loading = false
      state.fetchState.error = false
      state.tags = action.payload
    })
    builder.addCase(loadTags.rejected, (state) => {
      state.fetchState.loading = false
      state.fetchState.error = true
    })

    builder.addCase(addTag.pending, (state) => {
      state.upsertState.loading = true
      state.upsertState.error = false
    })
    builder.addCase(addTag.fulfilled, (state, action) => {
      state.upsertState.loading = false
      state.upsertState.error = false
      state.tags = [...state.tags, action.payload]
    })
    builder.addCase(addTag.rejected, (state) => {
      state.upsertState.loading = false
      state.upsertState.error = true
    })

    builder.addCase(deleteTag.pending, (state) => {
      state.upsertState.loading = true
      state.upsertState.error = false
    })
    builder.addCase(deleteTag.fulfilled, (state, action) => {
      state.upsertState.loading = false
      state.upsertState.error = false
      if (state.tags) {
        state.tags = state.tags.filter((x) => x.tagId !== action.payload)
      }
    })
    builder.addCase(deleteTag.rejected, (state) => {
      state.upsertState.loading = false
      state.upsertState.error = true
    })
  },
})

export const { actions, reducer } = slice
