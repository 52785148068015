import { createAsyncThunk } from '@reduxjs/toolkit'
import { endpoint } from 'infrastructure/api/endpoints'
import { IDicosImportRequest } from 'infrastructure/api/request/dicos'
import { ITagRequest } from 'infrastructure/api/request/tag'
import { IDicosResponse } from 'infrastructure/api/response/dicos'
import { IDicosImportResponse } from 'infrastructure/api/response/import'
import { RootState } from 'infrastructure/store'
import { IApiError } from 'infrastructure/types/api'
import { loadTags } from 'views/tags/tagsActions'
import { actions } from './directoryImportReducer'
import { IDirectoryState } from './directoryReducer'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'

export const importDicos = createAsyncThunk<void, void, { state: RootState }>(
  'importDicos',
  async (_, { rejectWithValue, getState, dispatch }) => {
    try {
      const state = getState().directoryImport
      const request: IDicosImportRequest = {
        sourceBucket: state.sourceBucket || '',
        sourceFolder: state.sourceFolder || '',
        dicosFileNames: state.dicosFileNames,
        tagIds: state.tagIds,
        renderParameterNames: state.renderParameterNames,
      }
      const result = await endpoint.importDicos(request)
      dispatch(actions.initialise())
      dispatch(loadImports())
      return result
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })

      return rejectWithValue(castError.response?.data)
    }
  },
)

export const createAndAddTag = createAsyncThunk(
  'createAndAddTag',
  async (tagName: string, { rejectWithValue, dispatch }) => {
    try {
      const tag: ITagRequest = {
        name: tagName,
      }
      const result = await endpoint.upsertTag(tag)
      dispatch(loadTags())
      return result
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })

      return rejectWithValue(castError.response?.data)
    }
  },
)

export const loadImports = createAsyncThunk<
  IDicosImportResponse[],
  void,
  { state: RootState }
>('loadImports', async (_, { rejectWithValue, getState }) => {
  try {
    const state = getState().directoryImport
    return await endpoint.getDicosImports(state.pageNumber, state.pageSize)
  } catch (error) {
    var castError = error as AxiosError<IApiError>

    toast.error(castError.response?.data.message, {
      pauseOnHover: true,
    })

    return rejectWithValue(castError.response?.data)
  }
})

export const loadDicos = createAsyncThunk<
  IDicosResponse[],
  void,
  { state: RootState }
>('loadDicos', async (_, { rejectWithValue, getState }) => {
  try {
    const state = getState().directory as IDirectoryState
    return await endpoint.searchDicosImages(
      state.pageNumber,
      state.pageSize,
      state.searchText ?? '',
    )
  } catch (error) {
    var castError = error as AxiosError<IApiError>

    toast.error(castError.response?.data.message, {
      pauseOnHover: true,
    })

    return rejectWithValue(castError.response?.data)
  }
})

export const uploadTagsFile = createAsyncThunk(
  'uploadTagsFile',
  async (formData: FormData, { rejectWithValue }) => {
    try {
      return await endpoint.uploadTagsFile(formData)
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })

      return rejectWithValue(castError.response?.data)
    }
  },
)
