import { ReactElement, useEffect } from 'react'
import { Gallery, GalleryBox } from './styles'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'infrastructure/hooks'
import { actions, IGalleryFilters } from './galleryReducer'
import { loadImages } from './galleryActions'
import { Spinner } from 'components/spinner/spinner'
import { GalleryFilterBar } from './components/filter/galleryFilterBar'
import { PagingButton } from 'components/button/styles'

export const GalleryView = (): ReactElement => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { images, fetchState, pageSize, pageNumber } = useAppSelector(
    (state) => state.gallery,
  )

  useEffect(() => {
    if (images.length === 0) dispatch(loadImages())
  }, [dispatch])

  const setSearchText = (searchText: string) => {
    dispatch(actions.setSearchText(searchText))
    dispatch(loadImages())
  }

  const saveFilters = (filters: IGalleryFilters) => {
    dispatch(actions.setFilters(filters))
    dispatch(loadImages())
  }

  const onLoadMoreImages = () => {
    dispatch(actions.setPageNumber(pageNumber + 1))
    dispatch(loadImages())
  }

  return (
    <GalleryBox>
      <GalleryFilterBar
        setSearchText={setSearchText}
        saveFilters={saveFilters}
      />
      <Gallery>
        {images.map((value, index) => (
          <figure key={index} onClick={() => navigate(`${value.imageId}`)}>
            <img src={value.location} />
          </figure>
        ))}
      </Gallery>
      {fetchState.loading ? (
        <Spinner size="80px" />
      ) : (
        <>
          {images.length % pageSize === 0 && (
            <PagingButton onClick={onLoadMoreImages}>Load More</PagingButton>
          )}
        </>
      )}
    </GalleryBox>
  )
}
