import { Rect, Transformer } from 'react-konva'
import { ReactElement, RefObject, useEffect, useRef } from 'react'
import Konva from 'konva'

export interface IRectangleProps {
  x: number
  y: number
  width: number
  height: number
  colour: string
  canvasWidth: number
  canvasHeight: number
  isSelected: boolean
  onToggle: () => void
  onSelect: () => void
  setIsEditing: (isEditing: boolean) => void
  onEditRectangle: (x: number, y: number, width: number, height: number) => void
}

export const Rectangle = (props: IRectangleProps): ReactElement => {
  const {
    x,
    y,
    width,
    height,
    colour,
    isSelected,
    onSelect,
    onToggle,
    canvasWidth,
    canvasHeight,
    setIsEditing,
    onEditRectangle,
  } = props
  const shapeRef: RefObject<Konva.Rect> = useRef(null)
  const trRef: RefObject<Konva.Transformer> = useRef(null)

  useEffect(() => {
    if (isSelected && trRef && trRef.current && shapeRef && shapeRef.current) {
      // we need to attach transformer manually
      trRef.current.nodes([shapeRef.current])
      const layer = trRef.current.getLayer()
      if (layer) {
        layer.batchDraw()
      }
    }
  }, [isSelected])

  const onDragMove = (event: any) => {
    const box = event.target.getClientRect()
    const absPos = event.target.getAbsolutePosition()
    const offsetX = box.x - absPos.x
    const offsetY = box.y - absPos.y

    const newAbsPos = { ...absPos }
    if (box.x < 0) {
      newAbsPos.x = -offsetX
    }
    if (box.y < 0) {
      newAbsPos.y = -offsetY
    }
    if (box.x + box.width > canvasWidth) {
      newAbsPos.x = canvasWidth - box.width - offsetX
    }
    if (box.y + box.height > canvasHeight) {
      newAbsPos.y = canvasHeight - box.height - offsetY
    }
    event.target.setAbsolutePosition(newAbsPos)
  }

  const EditRectangle = (
    newX: number,
    newY: number,
    newWidth: number,
    newHeight: number,
  ) => {
    onEditRectangle(newX, newY, newWidth, newHeight)
  }

  return (
    <>
      <Rect
        draggable
        fill="transparent"
        stroke={colour}
        x={x}
        y={y}
        onClick={onToggle}
        onTap={onSelect}
        ref={shapeRef}
        onDragStart={onSelect}
        onDragMove={onDragMove}
        onDragEnd={(e) => {
          if (isSelected) {
            //setCoordinates({ x: e.target.x(), y: e.target.y() })
            EditRectangle(e.target.x(), e.target.y(), width, height)
          }
        }}
        onTransformStart={(e) => {
          setIsEditing(true)
        }}
        onTransformEnd={(e) => {
          setIsEditing(false)
          // transformer is changing scale of the node
          // and NOT its width or height
          // but in the store we have only width and height
          // to match the data better we will reset scale on transform end
          const node = shapeRef.current
          if (node) {
            const scaleX = node.scaleX()
            const scaleY = node.scaleY()

            // we will reset it back
            node.scaleX(1)
            node.scaleY(1)

            EditRectangle(
              node.x(),
              node.y(),
              Math.max(5, node.width() * scaleX),
              Math.max(node.height() * scaleY),
            )
            /*onChange({
              ...shapeProps,
              x: node.x(),
              y: node.y(),
              // set minimal value
              width: Math.max(5, node.width() * scaleX),
              height: Math.max(node.height() * scaleY),
            })*/
          }
        }}
        width={width}
        height={height}
        onMouseEnter={(e: any) => {
          // style stage container:
          const container = e.target.getStage().container()
          container.style.cursor = 'pointer'
        }}
        onMouseLeave={(e: any) => {
          const container = e.target.getStage().container()
          container.style.cursor = 'crosshair'
        }}
      />
      {isSelected && (
        <Transformer
          ref={trRef}
          rotateEnabled={false}
          boundBoxFunc={(oldBox, newBox) => {
            // limit resize
            if (newBox.width < 5 || newBox.height < 5) {
              return oldBox
            }
            return newBox
          }}
        />
      )}
    </>
  )
}
