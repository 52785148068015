import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { endpoint } from 'infrastructure/api/endpoints'
import { IImageResponse } from 'infrastructure/api/response/image'
import { RootState } from 'infrastructure/store'
import { IApiError } from 'infrastructure/types/api'
import { MapImageToImageRequest } from 'infrastructure/types/image'
import { toast } from 'react-toastify'

export const loadImage = createAsyncThunk(
  'loadImage',
  async (imageId: string, { rejectWithValue }) => {
    try {
      return await endpoint.getImage(imageId)
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })

      return rejectWithValue(castError.response?.data)
    }
  },
)

export const saveImage = createAsyncThunk<
  IImageResponse,
  void,
  { state: RootState }
>('saveImage', async (_, { rejectWithValue, getState }) => {
  try {
    const image = getState().image
    if (image && image.value) {
      const imageRequest = MapImageToImageRequest(image.value)
      const response = await endpoint.updateImage(imageRequest)
      return response
    } else {
      throw new Error('Image not available')
    }
  } catch (error) {
    var castError = error as AxiosError<IApiError>

    toast.error(castError.response?.data.message, {
      pauseOnHover: true,
    })

    return rejectWithValue(castError.response?.data)
  }
})
