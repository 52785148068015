import { Vector2, Vector3 } from 'three'

/**
 * This shader is described in docs/shaders.md
 * Licence: https://github.com/mrdoob/three.js/blob/dev/LICENSE
 */

const VolumeRenderShader1 = {
  uniforms: {
    u_size: { value: new Vector3(1, 1, 1) },
    u_renderstyle: { value: 0 },
    u_renderthreshold: { value: -1000 },
    u_highthreshold: { value: 40000 },
    u_clim: { value: new Vector2(1, 1) }, // colour limits
    u_data: { value: null },
    u_cmdata: { value: null }, // colour pallet as a texture
    u_rescaleslope: { value: 1.0 },
    u_rescaleintercept: { value: 0.0 },

    u_bbox: { value: { min: new Vector3(1, 1, 1), max: new Vector3(1, 1, 1) } },
    u_isolatedthreat: { value: false },
    u_palette2: { value: null }, // colour pallet to use for ISO rendering
  },

  vertexShader: /* glsl */ `
  		varying vec4 v_nearpos;
		varying vec4 v_farpos;
		varying vec3 v_position;

		void main() {
						// Prepare transforms to map to "camera view". See also:
						// https://threejs.org/docs/#api/renderers/webgl/WebGLProgram
			mat4 viewtransformf = modelViewMatrix;
			mat4 viewtransformi = inverse(modelViewMatrix);

						// Project local vertex coordinate to camera position. Then do a step
						// backward (in cam coords) to the near clipping plane, and project back. Do
						// the same for the far clipping plane. This gives us all the information we
						// need to calculate the ray and truncate it to the viewing cone.
			vec4 position4 = vec4(position, 1.0);

			vec4 pos_in_cam = viewtransformf * position4;

						// Intersection of ray and near clipping plane (z = -1 in clip coords)
			pos_in_cam.z = -pos_in_cam.w;
			v_nearpos = viewtransformi * pos_in_cam;

						// Intersection of ray and far clipping plane (z = +1 in clip coords)
			pos_in_cam.z = pos_in_cam.w;
			v_farpos = viewtransformi * pos_in_cam;

						// Set varyings and output pos
			v_position = position;
			gl_Position = projectionMatrix * viewMatrix * modelMatrix * position4;
		}`,

  fragmentShader: /* glsl */ `

				precision highp float;
				precision mediump sampler3D;

				uniform vec3 u_size;
				uniform int u_renderstyle;
				uniform float u_renderthreshold;
				uniform float u_highthreshold;
				uniform vec2 u_clim;
				uniform float u_rescaleslope;
				uniform float u_rescaleintercept;
				
				uniform bool u_isolatedthreat;
				
				// the coordinates of the bounding box of the threat
				struct BBox {
					vec3 min;
					vec3 max;
				};

				uniform BBox u_bbox;
				
				uniform sampler3D u_data;
				uniform sampler2D u_cmdata;
				
				uniform sampler2D u_palette2;

				varying vec3 v_position;
				varying vec4 v_nearpos;
				varying vec4 v_farpos;
				

				// The maximum distance through our rendering volume is sqrt(3).
				const int MAX_STEPS = 887;	// 887 for 512^3, 1774 for 1024^3
				const int REFINEMENT_STEPS = 4;
				const float relative_step_size = 1.0;
				const vec4 ambient_color = vec4(0.2, 0.4, 0.2, 1.0);
				const vec4 diffuse_color = vec4(0.8, 0.2, 0.2, 1.0);
				const vec4 specular_color = vec4(1.0, 1.0, 1.0, 1.0);
				const float shininess = 40.0;

				vec4 cast_dvr(vec3 start_loc, vec3 step, int nsteps, vec3 view_ray);
				vec4 cast_mip(vec3 start_loc, vec3 step, int nsteps, vec3 view_ray);
				vec4 cast_iso(vec3 start_loc, vec3 step, int nsteps, vec3 view_ray);

				bool is_outside_bounding_box(vec3 texcoords);
				float sample1(vec3 texcoords);
				float sample2(vec3 texcoords);
				vec4 apply_colormap(float val);
				vec4 apply_colormap_iso(float val);
				vec4 add_lighting(float val, vec3 loc, vec3 step, vec3 view_ray);

				void main() {
				
						// Normalize clipping plane info
						vec3 farpos = v_farpos.xyz / v_farpos.w;
						vec3 nearpos = v_nearpos.xyz / v_nearpos.w;

						// Calculate unit vector pointing in the view direction through this fragment.
						vec3 view_ray = normalize(nearpos.xyz - farpos.xyz);

						// Compute the (negative) distance to the front surface or near clipping plane.
						// v_position is the back face of the cuboid, so the initial distance calculated in the dot
						// product below is the distance from near clip plane to the back of the cuboid
						float distance = dot(nearpos - v_position, view_ray);
						distance = max(distance, min((-0.5 - v_position.x) / view_ray.x,
																				(u_size.x - 0.5 - v_position.x) / view_ray.x));
						distance = max(distance, min((-0.5 - v_position.y) / view_ray.y,
																				(u_size.y - 0.5 - v_position.y) / view_ray.y));
						distance = max(distance, min((-0.5 - v_position.z) / view_ray.z,
																				(u_size.z - 0.5 - v_position.z) / view_ray.z));

						// Now we have the starting position on the front surface
						vec3 front = v_position + view_ray * distance;

						// Decide how many steps to take
						int nsteps = int(-distance / relative_step_size + 0.5);
						if ( nsteps < 1 )
								discard;

						// Get starting location and step vector in texture coordinates
						vec3 step = ((v_position - front) / u_size) / float(nsteps);
						vec3 start_loc = front / u_size;
						
						if (u_renderstyle == 0) {
								gl_FragColor = cast_mip(start_loc, step, nsteps, view_ray);
						} else if (u_renderstyle == 1) {
								gl_FragColor = cast_iso(start_loc, step, nsteps, view_ray);
						} else if (u_renderstyle == 2) {
						    // experimental combination of both MIP and ISO
						    
								gl_FragColor = cast_iso(start_loc, step, nsteps, view_ray);
								vec4 mipColor = cast_mip(start_loc, step, nsteps, view_ray);

								gl_FragColor = 1.0 - ((1.0 - gl_FragColor) * (1.0 - mipColor));
						} else if (u_renderstyle == 3) {
							gl_FragColor = cast_dvr(start_loc, step, nsteps, view_ray);
						}
						
						if (gl_FragColor.a < 0.05)
								discard;
				}

				bool is_outside_bounding_box(vec3 texcoords) {
						if (texcoords.x < u_bbox.min.x)
								return true;
						else if (texcoords.x > u_bbox.max.x)
								return true;
						
						if (texcoords.y < u_bbox.min.y)
								return true;
						else if (texcoords.y > u_bbox.max.y)
								return true;
						
						if (texcoords.z < u_bbox.min.z)
								return true;
						else if (texcoords.z > u_bbox.max.z)
								return true;
						
						return false;
				}
				
				float sample1(vec3 texcoords) {
					if (u_isolatedthreat) {
						if (is_outside_bounding_box(texcoords)) {
							return 0.0;
						}
					}
					
					vec4 sliceColor = texture(u_data, texcoords.xyz);
					float greyscaleValue = (sliceColor.r + sliceColor.g * 256.0) * 255.0;
					
					// I'm using my own scaling here - tray will be faintly visible 
					float rescaledValue = (greyscaleValue * 0.025) - 2.5;
					
					float clampedValue = max(0.0, min(255.0, rescaledValue)); 
					float value = clampedValue / 255.0;
					return value;
				}

				// as u_data is an integer, this transforms it into hounsfield units by using rescale slope and intercept
				float sample2(vec3 texcoords) {
					if (u_isolatedthreat) {
						if (is_outside_bounding_box(texcoords)) {
							return 0.0;
						}
					}
					
					vec4 sliceColor = texture(u_data, texcoords.xyz);

					// // assuming this has been parsed from a little endian int16, we want to reconstruct it as a float
					float value = (sliceColor.r + sliceColor.g * 256.0) * 255.0;

					
					float valueInHoundsfieldUnits = (value * u_rescaleslope) + u_rescaleintercept;

					if(valueInHoundsfieldUnits < u_renderthreshold){
						return 0.0;
					}

					// if(valueInHoundsfieldUnits > 10000.0){
					// 	return 1.0;
					// }
					// return 0.0;

					// scale the values to be between 0 and 1 using markers
					// float air = -1000.0;
					// float maxDensity = 14000.0; // metal (copper)
					// steel is 20k but that appears to be too high for most of the tested scans
					float adjustedValue = (valueInHoundsfieldUnits - u_renderthreshold) / (u_highthreshold - u_renderthreshold);
					return max(0.0, min(1.0, adjustedValue));
				}


				vec4 apply_colormap(float val) {
				    if (u_renderstyle == 2) {
						  val = (val - 0.00) / (0.18 - 0.00);
						}
						return texture2D(u_cmdata, vec2(val, 0.5));
				}
				
				vec4 apply_colormap_iso(float val) {
						return texture2D(u_palette2, vec2(val, 0.5));
				}

				vec4 cast_dvr(vec3 start_loc, vec3 step, int nsteps, vec3 view_ray) {
					vec3 loc = start_loc;
				
					// Enter the raycasting loop. In WebGL 1 the loop index cannot be compared with
					// non-constant expression. So we use a hard-coded max, and an additional condition
					// inside the loop.
					float cumVal = 0.0;
				
					for(int iter = 0; iter < MAX_STEPS; iter++) {
						if(iter >= nsteps)
							break;
				
											// Sample from the 3D texture
						float val = sample2(loc);
				
						if(val > 0.0) { 
												// Apply transfer function
												//color = add_lighting(val, loc, step, view_ray) + color;
							cumVal += val;
						}
				
											// Advance location deeper into the volume
						loc += step;
					}

					if (cumVal <= 0.01) {
						return vec4(1.0, 1.0, 1.0, 0.0);
					}
				
					vec4 color = texture2D(u_palette2, vec2(cumVal / 8.0, 0.0));
				
					return color; // float(MAX_STEPS);
				}
				
				vec4 cast_mip(vec3 start_loc, vec3 step, int nsteps, vec3 view_ray) {

						float max_val = -1e6;
						int max_i = 100;
						vec3 loc = start_loc;

						// Enter the raycasting loop. In WebGL 1 the loop index cannot be compared with
						// non-constant expression. So we use a hard-coded max, and an additional condition
						// inside the loop.
						for (int iter=0; iter<MAX_STEPS; iter++) {
								if (iter >= nsteps)
										break;
								// Sample from the 3D texture
								float val = sample2(loc);

								if (val > 0.0) {
									max_val = max(max_val, val);
									max_i = iter;
								}
								
								// Advance location deeper into the volume
								loc += step;
						}
						
						// make low density (white) space transparent
						if (max_val <= 0.01) {
						  return vec4(1.0, 1.0, 1.0, 0.0);
						}

						// Refine location, gives crispier images
						vec3 iloc = start_loc + step * (float(max_i) - 0.5);
						vec3 istep = step / float(REFINEMENT_STEPS);
						for (int i=0; i<REFINEMENT_STEPS; i++) {
								max_val = max(max_val, sample2(iloc));
								iloc += istep;
						}
						
						return apply_colormap(max_val);
				}


				vec4 cast_iso(vec3 start_loc, vec3 step, int nsteps, vec3 view_ray) {
				    vec4 finalColor = vec4(0.0);	// init transparent
				    vec4 tintColor = vec4(0.0);	// init transparent
				    bool tint = false;
						//gl_FragColor = vec4(0.0);
						vec4 color3 = vec4(0.0);	// final color
						vec3 dstep = 1.5 / u_size;	// step to sample derivative
						vec3 loc = start_loc;

						// Enter the raycasting loop. In WebGL 1 the loop index cannot be compared with
						// non-constant expression. So we use a hard-coded max, and an additional condition
						// inside the loop.
						for (int iter = 0; iter < MAX_STEPS; iter++) {
								if (iter >= nsteps)
										break;

								// Sample from the 3D texture
								float val = sample2(loc);
								
								if (val > 0.0) {
										// Take the last interval in smaller steps
										vec3 iloc = loc - 0.5 * step;
										vec3 istep = step / float(REFINEMENT_STEPS);
										for (int i = 0; i < REFINEMENT_STEPS; i++) {
												val = sample2(iloc);
												if (val > 0.0) {
														finalColor = add_lighting(val, iloc, dstep, view_ray);
														// finalColor.rgb = 1.0 - ((1.0 - finalColor.rgb) * (1.0 - tintColor.rgb * tintColor.a));
														return finalColor; 
												}
												iloc += istep;
										}
								}
								
								// the tint will be the first low density object the ray hits
								else if (!tint && val > 0.01) {
								    tint = true;
								    tintColor = add_lighting(val, loc, dstep, view_ray);
								    tintColor.a = 0.4;
								}

								// Advance location deeper into the volume
								loc += step;
						}
						
						// return tintColor;
						return finalColor;
				}

				vec4 add_lighting(float val, vec3 loc, vec3 step, vec3 view_ray)
				{
					// Calculate color by incorporating lighting

						// View direction
						vec3 V = normalize(view_ray);

						// calculate normal vector from gradient
						vec3 N;
						float val1, val2;
						val1 = sample2(loc + vec3(-step[0], 0.0, 0.0));
						val2 = sample2(loc + vec3(+step[0], 0.0, 0.0));
						N[0] = val1 - val2;
						val = max(max(val1, val2), val);
						val1 = sample2(loc + vec3(0.0, -step[1], 0.0));
						val2 = sample2(loc + vec3(0.0, +step[1], 0.0));
						N[1] = val1 - val2;
						val = max(max(val1, val2), val);
						val1 = sample2(loc + vec3(0.0, 0.0, -step[2]));
						val2 = sample2(loc + vec3(0.0, 0.0, +step[2]));
						N[2] = val1 - val2;
						val = max(max(val1, val2), val);

						float gm = length(N); // gradient magnitude
						N = normalize(N);

						// Flip normal so it points towards viewer
						float Nselect = float(dot(N, V) > 0.0);
						N = (2.0 * Nselect - 1.0) * N;	// ==	Nselect * N - (1.0-Nselect)*N;

						// Init colors
						vec4 ambient_color = vec4(0.0, 0.0, 0.0, 0.0);
						vec4 diffuse_color = vec4(0.2, 0.0, 0.1, 0.0);
						vec4 specular_color = vec4(0.0, 0.2, 0.0, 0.0);

						// define some lights in an array
						vec3[] lightDirs = vec3[](
							vec3(0.2, 1.0, 0.2),
							vec3(-0.1, 1.0, -0.1)
							);
						// note: could allow multiple lights
						for (int i=0; i<2 ; i++)
						{
								// Get light direction (make sure to prevent zero devision)
								vec3 L = lightDirs[i];

								float lightEnabled = float( length(L) > 0.0 );
								L = normalize(L + (1.0 - lightEnabled));

								// Calculate lighting properties
								float lambertTerm = clamp(dot(N, L), 0.0, 1.0);
								vec3 H = normalize(L+V); // Halfway vector
								float specularTerm = pow(max(dot(H, N), 0.0), shininess);

								// Calculate mask
								float mask1 = lightEnabled;
								
								float intensity = 0.4;
								// Calculate colors
								ambient_color +=	mask1 * ambient_color * intensity;	// * gl_LightSource[i].ambient;
								diffuse_color +=	mask1 * lambertTerm * intensity;
								specular_color += mask1 * specularTerm * specular_color * intensity;
						}

						// Calculate final color by componing different components
						vec4 final_color;

						vec4 color = apply_colormap_iso(val);
						
						final_color = color * (ambient_color + diffuse_color) + specular_color;
						final_color.a = color.a;
						return final_color;
				}`,
}

export { VolumeRenderShader1 }
