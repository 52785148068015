import { Spinner } from 'components/spinner/spinner'
import { useAppDispatch, useAppSelector } from 'infrastructure/hooks'
import { ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Canvas } from './components/canvas/canvas'
import { SidePanel } from './components/sidePanel/sidePanel'
import { loadImage } from './imageActions'
import { ImageViewBox } from './styles'

//check image or show error icon
export const ImageView = (): ReactElement => {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const { value, fetchState } = useAppSelector((state) => state.image)
  const selectedLabel = useAppSelector((state) => state.image.selectedLabel)

  useEffect(() => {
    if (id) {
      dispatch(loadImage(id))
    }
  }, [dispatch])

  return (
    <ImageViewBox>
      {fetchState.loading ? (
        <Spinner size="80px" />
      ) : (
        <>
          <Canvas image={value} selectedLabel={selectedLabel} />
          <SidePanel image={value} selectedLabel={selectedLabel} />
        </>
      )}
    </ImageViewBox>
  )
}
