import {
  IGroundTruthResponse,
  IGroundTruthDataResponse,
  IGroundTruthItemResponse,
} from 'infrastructure/api/response/groundTruth'
import { MapArrayBufferResponsesToDataPointModel } from './rendering'

export interface IGroundTruth {
  id: string
  legacyLabels: string[]
}

export interface IGroundTruthItem {
  label: string
  x1: number
  x2: number
  y1: number
  y2: number
  z1: number
  z2: number
}

export interface IGroundTruth2DItem {
  label: string
  x1: number
  x2: number
  y1: number
  y2: number
  ignore: boolean
}

export interface IGroundTruthData {
  id: string
  groundTruth: IGroundTruthItem[]
  seeded: boolean
  approvals: {
    userId: string
    date: string
    state: 'Pending' | 'Approved' | 'Rejected'
    roles: string[]
  }[]
}

export interface IDicosImageMeta {
  fileName: string
  renderer: string
  signedUrl: string
  isModified: boolean
  groundTruth: IGroundTruth2DItem[]
  approvals: {
    userId: string
    date: string
    state: 'Pending' | 'Approved' | 'Rejected'
    roles: string[]
  }[]
}

export const MapGroundTruthResponseToModel = (
  response: IGroundTruthResponse,
): IGroundTruth => ({
  id: response.name,
  legacyLabels: response.datasetLegacyLabels,
})

export const MapGroundTruthItemResponseToModel = (
  response: IGroundTruthItemResponse,
): IGroundTruthItem => ({
  label: response.label,
  x1: response.x1,
  x2: response.x2,
  y1: response.y1,
  y2: response.y2,
  z1: response.z1,
  z2: response.z2,
})

export const MapGroundTruthDataResponseToModel = (
  response: IGroundTruthDataResponse,
): IGroundTruthData => ({
  id: response.id,
  groundTruth: response.groundTruth.map((item) =>
    MapGroundTruthItemResponseToModel(item),
  ),
  seeded: response.seeded,
  approvals: response.approvals,
})

export const MapGroundTruthsResponseToModels = (
  response: IGroundTruthResponse[],
): IGroundTruth[] => response.map((item) => MapGroundTruthResponseToModel(item))

export const DecodeDpm = MapArrayBufferResponsesToDataPointModel
