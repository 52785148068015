import {
  PrimaryButtonExtended,
  Button,
  PrimaryButton,
} from 'components/button/styles'
import { ReactElement, useState } from 'react'
import { FilterDropDown, GalleryFilter, FilterMask, CheckBox } from './styles'
import { FilterIcon, XCircleIcon } from '@heroicons/react/outline'
import { Search } from 'components/searchBar/searchBar'
import { Title } from 'styles/textography'
import { useAppDispatch, useAppSelector } from 'infrastructure/hooks'
import { ILabel } from 'infrastructure/types/label'
import { Pill } from 'components/pill/styles'
import { ITag } from 'infrastructure/types/tag'
import { SearchSelect } from 'components/searchSelect/searchSelect'
import { IGalleryFilters } from 'views/gallery/galleryReducer'
import { triggerTraining } from 'views/gallery/galleryActions'
import { HasSpecificRequiredRole } from 'infrastructure/routes'
import { Roles } from 'infrastructure/auth'
import { useOidcUser } from '@axa-fr/react-oidc'
import { OidcUserInfo } from '@axa-fr/react-oidc/dist/vanilla/vanillaOidc'

export interface IGalleryFilterProps {
  setSearchText: (text: string) => void
  saveFilters: (filters: IGalleryFilters) => void
}

export const GalleryFilterBar = (props: IGalleryFilterProps): ReactElement => {
  const dispatch = useAppDispatch()
  const { oidcUser } = useOidcUser<OidcUserInfo & { role: string[] }>()
  const tags = useAppSelector((state) => state.tags.tags)
  const labels = useAppSelector((state) => state.labels.labels)
  const activeFilters = useAppSelector((state) => state.gallery.filters)
  const { setSearchText, saveFilters } = props
  const [filterOpen, setFilterOpen] = useState(false)
  const [filteredLabels, setFilteredLabels] = useState<ILabel[]>([])
  const [filteredTags, setFilteredTags] = useState<ITag[]>([])
  const [unlabelledOnly, setUnlabelledOnly] = useState<boolean>(
    activeFilters.unlabelledOnly,
  )

  const availableLabels = labels.filter((x) => !filteredLabels.includes(x))

  const availableTags = tags.filter((x) => !filteredTags.includes(x))

  const addFilteredLabel = (label: ILabel) => {
    setFilteredLabels([...filteredLabels, label])
  }

  const removeFilteredLabel = (label: ILabel) => {
    setFilteredLabels(filteredLabels.filter((x) => x != label))
  }

  const addFilteredTag = (tag: ITag) => {
    setFilteredTags([...filteredTags, tag])
  }

  const removeFilteredTag = (tag: ITag) => {
    setFilteredTags(filteredTags.filter((x) => x != tag))
  }

  const toggleUnlabelledOnly = () => {
    setUnlabelledOnly(!unlabelledOnly)
  }

  const saveCurrentFilters = () => {
    const filters: IGalleryFilters = {
      labels: filteredLabels,
      tags: filteredTags,
      unlabelledOnly: unlabelledOnly,
    }
    saveFilters(filters)
    setFilterOpen(false)
  }
  const activeFilterCount =
    activeFilters.labels.length + activeFilters.tags.length

  return (
    <>
      {filterOpen && <FilterMask onClick={() => setFilterOpen(false)} />}
      <GalleryFilter>
        <div className="filter-control-container">
          <PrimaryButtonExtended
            className="filter-control"
            onClick={() => setFilterOpen(!filterOpen)}
          >
            <div className="filter-text">
              <FilterIcon width={18} height={18} />
              <span>Filters</span>
            </div>

            {Boolean(activeFilterCount) && (
              <span className="filter-count">{activeFilterCount}</span>
            )}
          </PrimaryButtonExtended>
          <Search placeholder="Search by name" onUpdate={setSearchText} />

          {filterOpen && (
            <FilterDropDown>
              <Title>Filters</Title>
              {Boolean(filteredLabels.length) && (
                <div className="filters">
                  {filteredLabels.map((x, index) => (
                    <Pill key={index} onClick={() => removeFilteredLabel(x)}>
                      <span>{x.name}</span>
                      <XCircleIcon width={16} height={16} />
                    </Pill>
                  ))}
                </div>
              )}
              <SearchSelect
                placeholder="Search labels"
                options={availableLabels}
                onSelect={(x) => addFilteredLabel(x)}
              />

              {Boolean(filteredTags.length) && (
                <div className="filters">
                  {filteredTags.map((x, index) => (
                    <Pill key={index} onClick={() => removeFilteredTag(x)}>
                      <span>{x.name}</span>
                      <XCircleIcon width={16} height={16} />
                    </Pill>
                  ))}
                </div>
              )}
              <SearchSelect
                placeholder="Search tags"
                options={availableTags}
                onSelect={(x) => addFilteredTag(x)}
              />

              <CheckBox>
                <label>
                  <input
                    type="checkbox"
                    name="checkbox"
                    checked={unlabelledOnly}
                    onChange={toggleUnlabelledOnly}
                  />
                  Unlabelled Only
                </label>
              </CheckBox>
              <Button onClick={saveCurrentFilters}>Save</Button>
            </FilterDropDown>
          )}
        </div>

        {oidcUser &&
          HasSpecificRequiredRole(oidcUser.role, Roles.ModelTrainer) && (
            <PrimaryButton
              disabled={activeFilters.unlabelledOnly}
              onClick={() => dispatch(triggerTraining())}
            >
              Train Model
            </PrimaryButton>
          )}
      </GalleryFilter>
    </>
  )
}
