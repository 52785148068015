import * as React from 'react'
import { FC, useEffect, useState } from 'react'
import styled from 'styled-components'

interface SliderProps {
  value: number
  onChange: (x: number) => void
  min?: number
  max?: number
}

const SlideContainer = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 10px;
`

const SliderIpt = styled.input`
  flex: 1;
  text-align: right;
  font-weight: bold;
  border-right: 1px solid #ccc; /* Add a right border to create the line effect */
  padding-right: 10px; /* Add padding to separate the line from text */
`

const Slider: FC<SliderProps> = ({ value, onChange, min, max }) => (
  <SlideContainer>
    <SliderIpt
      type="range"
      min={min ?? 1}
      max={max ?? 100}
      value={value}
      id="myRange"
      onChange={(x) => {
        const newValue = parseInt(x.target.value)
        onChange(newValue)
      }}
    />
  </SlideContainer>
)

export default Slider
