import styled from 'styled-components'
import { colours } from 'styles/colours'
import { spacing } from 'styles/spacing'

export const LabelsBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing.Small};
`

export const LabelsFilter = styled.div`
  border-bottom: 1px solid ${colours.disabled};
  margin-bottom: ${spacing.Medium};
  display: flex;
  gap: ${spacing.Small};
`

export const Labels = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: ${spacing.Small};
`

export const Label = styled.div<{ colour: string; expand?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${spacing.Medium};
  background-color: ${colours.contrastLight};
  border-left: 3px solid ${(props) => props.colour};
  padding: ${spacing.Small};
  text-transform: capitalize;
  min-width: 300px;
  ${(props) => (props.expand ? 'grid-column: 1 / -1;' : '')};

  &.add-label {
    flex-direction: row;
    gap: ${spacing.ExtraSmall};
    button {
      margin-bottom: 0;
      min-width: 50px;
    }

    input {
      flex: 1;
      padding: ${spacing.ExtraSmall};
    }
  }

  .header {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    flex: 1;

    .info {
      display: flex;
      flex-direction: column;
      gap: ${spacing.Small};
    }

    .progress {
      width: 50px;
    }
  }

  .body {
    display: ${(props) => (props.expand ? 'flex' : 'none')};
    flex-direction: column;
    gap: ${spacing.Medium};

    .variants {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: ${spacing.Small};

      .variant {
        display: flex;
        gap: ${spacing.Small};

        .name {
          width: 400px;
        }
        progress {
          width: 100%;
          border: 0;
          border-radius: 0;
        }
        progress::-webkit-progress-bar {
          border: 0;
          border-radius: 0;
          background-color: ${colours.disabled};
        }
        progress::-webkit-progress-value {
          border: 0;
          border-radius: 0;
          background-color: #3e98c7;
        }
        progress::-moz-progress-bar {
          border: 0;
          border-radius: 0;
        }
      }
    }
  }
`
