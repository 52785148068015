import { SearchIcon } from '@heroicons/react/outline'
import { useState } from 'react'
import { DebounceInput } from 'react-debounce-input'
import { colours } from 'styles/colours'
import { SearchBar, SearchBarBox, SearchResults } from './styles'

interface ISearchSelectProps<T> {
  placeholder: string
  onSelect: (option: T) => void
  options: T[]
}

export const SearchSelect = <T extends { name: string }>(
  props: ISearchSelectProps<T>,
) => {
  var { placeholder, onSelect, options } = props
  const [searchText, setSearchText] = useState('')

  const availableOptions = options.filter((x) =>
    x.name.toLocaleLowerCase().startsWith(searchText.toLocaleLowerCase()),
  )

  const onSelectOption = (option: T) => {
    setSearchText('')
    onSelect(option)
  }

  return (
    <SearchBarBox>
      <SearchBar>
        <SearchIcon width={18} height={18} color={colours.disabled} />
        <DebounceInput
          placeholder={placeholder}
          minLength={2}
          debounceTimeout={300}
          onChange={(event) => setSearchText(event.target.value)}
          value={searchText}
        />
      </SearchBar>
      {searchText && (
        <SearchResults>
          {availableOptions.map((x, index) => (
            <div
              key={index}
              className="result"
              onClick={() => onSelectOption(x)}
            >
              {x.name}
            </div>
          ))}
        </SearchResults>
      )}
    </SearchBarBox>
  )
}
