/* eslint-disable react/prop-types */
import { FC, ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useQuery } from './util'
import { useAppDispatch, useAppSelector } from 'infrastructure/hooks'
import {
  approveOrRejectRenderedImages,
  loadRenderedImages,
  renderImagesBeginViewing,
  renderImagesEndViewing,
  saveRenderedImages,
} from './groundTruthActions'
import {
  selectGroundTruthRenderedImages,
  selectGroundTruthRenderedImagesLoadSaveState,
} from './groundTruthSelectors'
import {
  IDicosImageMeta,
  IGroundTruth2DItem,
} from 'infrastructure/types/groundTruth'
import styled from 'styled-components'
import { actions } from './groundTruthReducer'
import { Button } from 'components/button/styles'
import { Spinner } from 'components/spinner/spinner'
import { CheckBoxSt } from './components/checkboxes'
import { asValidRenderer } from 'infrastructure/types/rendering'
import { useOidcUser } from '@axa-fr/react-oidc'
import { OidcUserInfo } from '@axa-fr/react-oidc/dist/vanilla/vanillaOidc'

const StringItemSelector: FC<{
  selectedItem: string | undefined
  items: string[]
  onSelectedItemChanged: (s: string | undefined) => void
}> = ({ selectedItem, items, onSelectedItemChanged }) => {
  return (
    <div>
      <select
        value={selectedItem}
        onChange={(e) => {
          onSelectedItemChanged(
            e.target.value === '' ? undefined : e.target.value,
          )
        }}
      >
        <option value={''}>all</option>
        {items.map((l, i) => (
          <option key={i} value={l}>
            {l}
          </option>
        ))}
      </select>
    </div>
  )
}

const IdxItemSelector: React.FC<{
  items: IGroundTruth2DItem[]
  selectedItem: number | undefined
  onSelectedItemChanged: (idx: number | undefined) => void
}> = ({ items, selectedItem, onSelectedItemChanged }) => {
  return (
    <div>
      <select
        value={selectedItem}
        onChange={(e) =>
          e.target.value === ''
            ? onSelectedItemChanged(undefined)
            : onSelectedItemChanged(parseInt(e.target.value))
        }
      >
        <option value={''}>all</option>
        {items.map((l, i) => (
          <option key={i} value={i}>
            {i}.{l.label}
          </option>
        ))}
      </select>
    </div>
  )
}

const colours = [
  'red',
  'green',
  'blue',
  'orange',
  'purple',
  'brown',
  'pink',
  'grey',
  'black',
]

const RenderImages: React.FC<{
  file: IDicosImageMeta
  labelConstraint: string | undefined
  setfocusedRenderedImageState: (
    pl: IGroundTruth2DItem & { signedUrl: string },
  ) => void
}> = ({ file, labelConstraint, setfocusedRenderedImageState }) => {
  // viewBox={`0 0 ${imgMeta.width} ${imgMeta.height}`}
  const width = 1280 // image.meta.width
  const height = 1280 // image.meta.height
  const filteredGt = file.groundTruth.filter(
    (gt) => labelConstraint == null || gt.label === labelConstraint,
  )
  const [focusedGts, setFocusedGts] = useState<number>()
  const focusedGt = focusedGts != null ? filteredGt[focusedGts] : undefined
  const setfocusedRenderedImageStateL = (pl: IGroundTruth2DItem) =>
    setfocusedRenderedImageState({ ...pl, signedUrl: file.signedUrl })

  return (
    <div>
      <div>{file.fileName}</div>
      <div style={{ border: '1px solid', width: 400, height: 400 }}>
        <svg
          width="100%"
          height="100%"
          viewBox={`0 0 ${width} ${height}`}
          xmlns="http://www.w3.org/2000/svg"
        >
          <image href={file.signedUrl} width="100%" height="100%" />
          {filteredGt
            .filter((gt, i) => (focusedGts != null ? i === focusedGts : true))
            .map((gt, i) => (
              <g key={i}>
                <rect
                  x={gt.x1 * width}
                  y={gt.y1 * height}
                  width={gt.x2 * width - gt.x1 * width}
                  height={gt.y2 * height - gt.y1 * height}
                  stroke={colours[i % colours.length]}
                  strokeDasharray={gt.ignore ? '5,5' : undefined}
                  strokeWidth="2"
                  fill="none"
                />
              </g>
            ))}
        </svg>
      </div>
      <div>
        {filteredGt.length > 1 ? (
          <IdxItemSelector
            items={filteredGt}
            selectedItem={focusedGts}
            onSelectedItemChanged={setFocusedGts}
          />
        ) : null}
      </div>
      <div style={{ display: 'flex' }}>
        {focusedGt != null ? (
          <>
            <CheckBoxSt
              label="ignore"
              checked={focusedGt.ignore}
              onCheckedChanged={(b) =>
                setfocusedRenderedImageStateL({ ...focusedGt, ignore: b })
              }
            />
          </>
        ) : (
          <>
            {filteredGt.map((gt, i) => (
              <div
                key={i}
                style={{
                  color: colours[i % colours.length],
                }}
              >
                <div>{gt.label}</div>
                <CheckBoxSt
                  label="ignore"
                  checked={gt.ignore}
                  onCheckedChanged={(b) =>
                    setfocusedRenderedImageStateL({ ...gt, ignore: b })
                  }
                />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  )
}

// self expanding grid
const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  grid-row-gap: 10px;
  grid-column-gap: 10px;
`

export const GroundTruthRenderedImagesView = (): ReactElement => {
  const { bucketName, id } = useParams()
  const folderName = useQuery('folderName') ?? ''
  const viewCountS = useQuery('viewCount') ?? ''
  const renderParametersNameS = useQuery('renderParametersName') ?? ''
  const viewCount = !Number.isNaN(parseInt(viewCountS))
    ? parseInt(viewCountS)
    : 6
  const renderParametersName = asValidRenderer(renderParametersNameS)
    ? renderParametersNameS
    : 'blue-red'
  const dispatch = useAppDispatch()
  const [updateCount, setUpdateCount] = useState(0)

  useEffect(() => {
    if (bucketName == null || id == null) return

    dispatch(
      renderImagesBeginViewing({
        bucketName,
        folderName,
        id,
        viewCount,
        renderParametersName,
      }),
    )

    const onBeforeUnload = () => {
      dispatch(
        renderImagesEndViewing({
          bucketName,
          folderName,
          id,
          viewCount,
          renderParametersName,
        }),
      )
    }

    // This is required to deal with the user closing tab scenario
    window.addEventListener('beforeunload', onBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload)
      onBeforeUnload()
    }
  }, [bucketName, folderName, id, viewCount, renderParametersName])
  useEffect(() => {
    if (bucketName != null)
      dispatch(
        loadRenderedImages({
          bucketName,
          folderName,
          id: id ?? '',
          viewCount,
          renderParametersName,
        }),
      )
  }, [bucketName, folderName, id, updateCount])
  const setfocusedRenderedImageState = (
    pl: IGroundTruth2DItem & { signedUrl: string },
  ) => dispatch(actions.editSetfocusedRenderedImageState(pl))
  const renderedImages = useAppSelector(selectGroundTruthRenderedImages)
  const loadSaveState = useAppSelector(
    selectGroundTruthRenderedImagesLoadSaveState,
  )
  const saveImagesStates = () =>
    dispatch(
      saveRenderedImages({
        bucketName: bucketName ?? '',
        folderName: folderName,
        id: id ?? '',
        viewCount, // kind of cheating here, we should probably thread this through properly
        data: renderedImages.filter((ri) => ri.isModified),
      }),
    ).then(() => setUpdateCount((prev) => prev + 1))
  const canSave = renderedImages.some((ri) => ri.isModified)
  const [labelConstraint, setLabelConstraint] = useState<string>()
  const possibleLabels = Array.from(
    new Set(
      renderedImages.flatMap((ri) => ri.groundTruth.map((gt) => gt.label)),
    ),
  )

  const { oidcUser } = useOidcUser<OidcUserInfo & { role: string[] }>()
  const approveImages = () =>
    dispatch(
      approveOrRejectRenderedImages({
        bucketName: bucketName ?? '',
        folderName: folderName,
        id: id ?? '',
        viewCount, // kind of cheating here, we should probably thread this through properly
        renderParametersName,
        operation: 'approve',
      }),
    ).then(() => {
      setUpdateCount((prev) => prev + 1)
    })
  const rejectImages = () =>
    dispatch(
      approveOrRejectRenderedImages({
        bucketName: bucketName ?? '',
        folderName: folderName,
        id: id ?? '',
        viewCount,
        renderParametersName,
        operation: 'reject',
      }),
    ).then(() => {
      setUpdateCount((prev) => prev + 1)
    })
  const isModified = renderedImages.some((ri) => ri.isModified)
  const thisUserHasApprovedAll = renderedImages.every((ri) =>
    ri.approvals.some(
      (a) => a.userId === oidcUser?.sub && a.state === 'Approved',
    ),
  )
  const thisUserHasRejectedAll = renderedImages.every((ri) =>
    ri.approvals.some(
      (a) => a.userId === oidcUser?.sub && a.state === 'Rejected',
    ),
  )
  const canApprove = !isModified && !thisUserHasApprovedAll
  const canReject = !isModified && !thisUserHasRejectedAll

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          justifyContent: 'space-between',
        }}
      >
        <StringItemSelector
          selectedItem={labelConstraint}
          items={possibleLabels}
          onSelectedItemChanged={setLabelConstraint}
        />
        {canSave ? (
          <Button onClick={saveImagesStates}>save</Button>
        ) : (
          <div>no changes</div>
        )}
        {canApprove ? (
          <Button onClick={approveImages}>approve</Button>
        ) : (
          <Button disabled>already approved</Button>
        )}
        {canReject ? (
          <Button onClick={rejectImages}>reject</Button>
        ) : (
          <Button disabled>already rejected</Button>
        )}
        {loadSaveState === 'is-loading' || loadSaveState === 'is-saving' ? (
          <Spinner size="80px" />
        ) : null}
        {loadSaveState === 'is-error' ? <div>error</div> : null}
        {loadSaveState === 'is-saved' ? <div>success</div> : null}
      </div>
      <Grid>
        {renderedImages.map((file, i) => (
          <RenderImages
            key={i}
            file={file}
            labelConstraint={labelConstraint}
            setfocusedRenderedImageState={setfocusedRenderedImageState}
          />
        ))}
      </Grid>
    </>
  )
}
