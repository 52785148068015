import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { endpoint } from 'infrastructure/api/endpoints'
import { ILabelRequest } from 'infrastructure/api/request/label'
import { IApiError } from 'infrastructure/types/api'
import { toast } from 'react-toastify'

export const loadLabels = createAsyncThunk(
  'loadLabels',
  async (_, { rejectWithValue }) => {
    try {
      return await endpoint.getLabels()
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })

      return rejectWithValue(castError.response?.data)
    }
  },
)

export const addLabel = createAsyncThunk(
  'addLabel',
  async (labelName: string, { rejectWithValue }) => {
    try {
      const label: ILabelRequest = {
        name: labelName,
      }
      return await endpoint.upsertLabel(label)
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })

      return rejectWithValue(castError.response?.data)
    }
  },
)

export const deleteLabel = createAsyncThunk(
  'deleteLabel',
  async (labelId: string, { rejectWithValue }) => {
    try {
      await endpoint.deleteLabel(labelId)
      return labelId
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })

      return rejectWithValue(castError.response?.data)
    }
  },
)
