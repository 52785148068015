import { PagingButton } from 'components/button/styles'
import { Spinner } from 'components/spinner/spinner'
import { ExpandableTable } from 'components/table/styles'
import { format, parseISO } from 'date-fns'
import { useAppDispatch, useAppSelector } from 'infrastructure/hooks'
import { routes } from 'infrastructure/routes'
import { TrainingStatusMapper } from 'infrastructure/types/training'
import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { loadTrainings } from '../trainingActions'
import { actions } from '../trainingReducer'

export interface IModelSummaryProps {
  allowLoadMore: boolean
}

export const TrainingListTable = (props: IModelSummaryProps) => {
  const { allowLoadMore } = props
  const navigate = useNavigate()
  const dispatch = useAppDispatch()
  const { trainings, fetchState, pageSize, pageNumber } = useAppSelector(
    (state) => state.training,
  )

  useEffect(() => {
    dispatch(actions.initialise())
    dispatch(loadTrainings())
  }, [dispatch])

  const onLoadMoreTraining = () => {
    if (allowLoadMore) {
      dispatch(actions.setPageNumber(pageNumber + 1))
      dispatch(loadTrainings())
    }
  }

  return (
    <>
      <ExpandableTable columns={6}>
        <div className="row header">
          <span>Date</span>
          <span>Status</span>
          <span>Average Precision</span>
          <span>Precision</span>
          <span>Recall</span>
          <span>Total Images</span>
        </div>

        {trainings.map((value, index) => (
          <div
            className="row clickable"
            key={index}
            onClick={() => navigate(`${routes.Training.path}/${value.id}`)}
          >
            <span>
              {format(parseISO(value.dateCreated), 'dd/MM/yyyy HH:mm')}
            </span>
            <span>{TrainingStatusMapper.get(value.status)}</span>
            <span>{value?.result?.averagePrecision ?? '-'}</span>
            <span>{value?.result?.precision ?? '-'}</span>
            <span>{value?.result?.recall ?? '-'}</span>
            <span>{value?.result?.totalImages ?? '-'}</span>
          </div>
        ))}
      </ExpandableTable>
      {fetchState.loading ? (
        <Spinner size="80px" />
      ) : (
        <>
          {allowLoadMore && trainings.length % pageSize === 0 && (
            <PagingButton onClick={onLoadMoreTraining}>Load More</PagingButton>
          )}
        </>
      )}
    </>
  )
}
