import { createAsyncThunk } from '@reduxjs/toolkit'
import { endpoint } from 'infrastructure/api/endpoints'
import { IImageResponse } from 'infrastructure/api/response/image'
import { RootState } from 'infrastructure/store'
import { IApiError } from 'infrastructure/types/api'
import { IGalleryState } from './galleryReducer'
import { AxiosError } from 'axios'
import { toast } from 'react-toastify'

export const loadImages = createAsyncThunk<
  IImageResponse[],
  void,
  { state: RootState }
>('loadImages', async (_, { rejectWithValue, getState }) => {
  try {
    const gallery = getState().gallery as IGalleryState
    const labelFilters = gallery.filters.labels.map((x) => x.name)
    const tagFilters = gallery.filters.tags.map((x) => x.name)
    return await endpoint.getImages(
      gallery.pageNumber,
      gallery.pageSize,
      gallery.searchText ?? '',
      labelFilters,
      tagFilters,
      gallery.filters.unlabelledOnly,
    )
  } catch (error) {
    var castError = error as AxiosError<IApiError>

    toast.error(castError.response?.data.message, {
      pauseOnHover: true,
    })

    return rejectWithValue(castError.response?.data)
  }
})

export const triggerTraining = createAsyncThunk<
  string,
  void,
  { state: RootState }
>('triggerTraining', async (_, { rejectWithValue, getState }) => {
  try {
    const gallery = getState().gallery as IGalleryState
    const labelFilters = gallery.filters.labels.map((x) => x.name)
    const tagFilters = gallery.filters.tags.map((x) => x.name)

    if (gallery.filters.unlabelledOnly) {
      toast.error('Cannot train on unlabelled images', {
        pauseOnHover: true,
      })

      return rejectWithValue({
        errorCode: 400,
        message: 'Cannot train on unlabelled images',
      })
    }

    return await endpoint.triggerTraining(
      gallery.searchText ?? '',
      labelFilters,
      tagFilters,
      gallery.filters.unlabelledOnly,
    )
  } catch (error) {
    var castError = error as AxiosError<IApiError>

    toast.error(castError.response?.data.message, {
      pauseOnHover: true,
    })

    return rejectWithValue(castError.response?.data)
  }
})
