import {
  IDicosImageResponse,
  IDicosResponse,
} from 'infrastructure/api/response/dicos'

export interface IDicos {
  id: string
  name: string
  vtiAvailable: string
  images: IDicosImage[]
}

export interface IDicosImage {
  imageId: string
  name: string
  location: string
  renderOrientationX: number
  renderOrientationY: number
  renderOrientationZ: number
  volumePalette: string
  signedUrl: string
}

export const MapDicosImageResponseToDicosImage = (
  response: IDicosImageResponse,
): IDicosImage => ({
  imageId: response.imageId,
  name: response.name,
  location: response.location,
  renderOrientationX: response.renderOrientationX,
  renderOrientationY: response.renderOrientationY,
  renderOrientationZ: response.renderOrientationZ,
  volumePalette: response.volumePalette,
  signedUrl: response.signedUrl,
})

export const MapDicosImageResponsesToDicosImages = (
  response: IDicosImageResponse[],
): IDicosImage[] =>
  response.map((item) => MapDicosImageResponseToDicosImage(item))

export const MapDicosResponseToDicos = (response: IDicosResponse): IDicos => ({
  id: response.id,
  name: response.name,
  vtiAvailable: response.vtiAvailable,
  images: MapDicosImageResponsesToDicosImages(response.images),
})

export const MapDicosResponsesToDicos = (
  response: IDicosResponse[],
): IDicos[] => response.map((item) => MapDicosResponseToDicos(item))
