import { useAppDispatch, useAppSelector } from 'infrastructure/hooks'
import { FC, ReactElement, useEffect, useState } from 'react'
import {
  loadGroundTruth,
  loadGroundTruthList,
  renderImages,
  seedGroundTruth,
  seedGroundTruthEnabled,
} from './groundTruthActions'
import { Link, useNavigate, useParams, useLocation } from 'react-router-dom'
import { IGroundTruthData } from 'infrastructure/types/groundTruth'
import styled from 'styled-components'
import { Spinner } from 'components/spinner/spinner'
import { Button } from 'components/button/styles'
import { renderOptionsWithViews, useQuery } from './util'
import { Roles } from 'infrastructure/auth'
import { Renderers } from 'infrastructure/types/rendering'
import { RenderingState } from './groundTruthReducer'

const GTGrid = styled.div<{ showDatasetCols: boolean }>`
  display: grid;
  // make new columns to fill the space
  grid-template-columns: repeat(auto-fill, minmax(0, 250px));
  justify-content: space-around;
  align-items: center;
  justify-items: center;
  grid-column-gap: 12px;
`

const GTMessage = styled.div``
const GTLabels = styled.div``

const GroundTruthListContainer = styled.div<{
  isApprovedByLabelAdmin: boolean
  isApprovedByLabeler: boolean
}>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${(props) =>
    props.isApprovedByLabelAdmin
      ? '#cfc'
      : props.isApprovedByLabeler
      ? '#cff'
      : '#fcc'};
  border-radius: 4px;
  padding: 0.5rem;
`

const TinyButton = styled(Button)`
  min-width: 0;
  margin: 0;
  padding: 0.5rem;
`

const GroundTruthRowDetails: FC<{
  data:
    | IGroundTruthData
    | 'not-found'
    | 'seeding'
    | 'seeding-failed'
    | 'loading'
  rendering: RenderingState[]
  id: string
  folderName: string
  seed: () => void
  seedEnabled: boolean
  renderImages: (viewCount: number, renderParametersName: Renderers) => void
}> = ({ data, rendering, id, folderName, seed, seedEnabled, renderImages }) => {
  const message =
    data === 'seeding'
      ? 'Seeding...'
      : data === 'seeding-failed'
      ? 'Seeding failed'
      : data === 'not-found'
      ? 'Not found'
      : data === 'loading'
      ? 'Loading...'
      : data.groundTruth.length === 0
      ? 'No threats found'
      : null

  if (data === 'loading')
    return (
      <>
        Loading...
        <Spinner size="40px" />
      </>
    )

  if (data === 'seeding-failed' || data === 'not-found')
    return (
      <>
        <GTMessage>{message}</GTMessage>
        <TinyButton disabled={!seedEnabled} onClick={() => seed()}>
          Seed
        </TinyButton>
      </>
    )
  if (data === 'seeding')
    return (
      <>
        <GTMessage>{message}</GTMessage>
        <Spinner size="40px" />
      </>
    )

  if (data.groundTruth.length === 0)
    return (
      <>
        <GTMessage>{message}</GTMessage>
        <TinyButton disabled={!seedEnabled} onClick={() => seed()}>
          Seed
        </TinyButton>
      </>
    )

  return (
    <>
      <GroundTruthListContainer
        isApprovedByLabelAdmin={data.approvals.some((q) =>
          q.roles.some((r) => r === Roles.LabellerAdmin),
        )}
        isApprovedByLabeler={data.approvals.some((q) =>
          q.roles.some((r) => r === Roles.Labeller),
        )}
      >
        <GTMessage>{message}</GTMessage>
        <GTLabels>{data.groundTruth.map((gt) => gt.label).join(', ')}</GTLabels>
      </GroundTruthListContainer>
      <TinyButton
        onClick={() => {
          // popup okcancel browser dialog
          if (
            window.confirm(
              'This will overwrite any existing ground truth. Are you sure?',
            )
          ) {
            seed()
          }
        }}
      >
        Reseed
      </TinyButton>

      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'auto auto',
        }}
      >
        {rendering.map((r) => {
          if (r.state === 'rendering') return <Spinner size="40px" />
          if (r.state === 'rendering-failed') return <span>Failed</span>
          return (
            <TinyButton
              key={r.renderParametersName + r.viewCount}
              onClick={() => renderImages(r.viewCount, r.renderParametersName)}
            >
              {r.renderParametersName} {r.viewCount}
            </TinyButton>
          )
        })}
      </div>
      <CLinkContainer>
        {renderOptionsWithViews.map((r, idx) => (
          <Link
            key={idx}
            to={`./${id}/rendered-images?folderName=${folderName}&viewCount=${r.viewCount}&renderParametersName=${r.renderParametersName}`}
          >
            {r.renderParametersName} {r.viewCount}
          </Link>
        ))}
      </CLinkContainer>
    </>
  )
}

const HorizFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
`

const Pager: FC<{
  page: number
  maxPages: number
  onPageChanged: (n: number) => void
}> = ({ page, maxPages, onPageChanged }) => {
  return (
    <HorizFlexContainer>
      <Button onClick={() => onPageChanged(page - 50)} disabled={page < 50}>
        {'<<<'}
      </Button>
      <Button onClick={() => onPageChanged(page - 10)} disabled={page < 10}>
        {'<<'}
      </Button>
      <Button onClick={() => onPageChanged(page - 1)} disabled={page === 0}>
        {'<'}
      </Button>
      <GTMessage>
        Page {page + 1} of {maxPages + 1}
      </GTMessage>
      <Button
        onClick={() => onPageChanged(page + 1)}
        disabled={page === maxPages}
      >
        {'>'}
      </Button>
      <Button
        onClick={() => onPageChanged(page + 10)}
        disabled={page + 10 > maxPages}
      >
        {'>>'}
      </Button>
      <Button
        onClick={() => onPageChanged(page + 50)}
        disabled={page + 50 > maxPages}
      >
        {'>>>'}
      </Button>
    </HorizFlexContainer>
  )
}

// stylised input with default text
const QueryInput = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  &:focus {
    outline: none;
    border-color: #719ece;
    box-shadow: 0 0 10px #719ece;
  }
`

const TextSearchContainer = styled.div`
  padding: 0.5rem;
  margin-bottom: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SearchLabelText = styled.label`
  margin-right: 0.5rem;
  padding: 0.5rem;
`

const TextSearch: FC<{
  query: string
  onQueryChanged: (s: string) => void
}> = ({ onQueryChanged, query }) => {
  return (
    <TextSearchContainer>
      <SearchLabelText htmlFor="text-search">Search</SearchLabelText>
      <QueryInput
        type="text"
        value={query}
        onChange={(e) => onQueryChanged(e.target.value)}
      />
    </TextSearchContainer>
  )
}

const ButtonM = styled(Button)`
  margin: 0.5rem;
`

export const DatasetFileEditor: FC<{
  datasetFile: string
  onDatasetFileChanged: (s: string) => void
}> = ({ datasetFile, onDatasetFileChanged }) => {
  const [datasetFileNext, setDatasetFileNext] = useState(datasetFile)
  return (
    <TextSearchContainer>
      <SearchLabelText htmlFor="text-search">Dataset file</SearchLabelText>
      <QueryInput
        type="text"
        value={datasetFileNext}
        onChange={(e) => setDatasetFileNext(e.target.value)}
      />
      <ButtonM onClick={() => onDatasetFileChanged(datasetFileNext)}>
        Set
      </ButtonM>
    </TextSearchContainer>
  )
}

const CLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
`

export const GroundTruthList = (): ReactElement => {
  const { bucketName } = useParams()
  const folderName = useQuery('folderName') ?? ''
  const datasetFile = useQuery('datasetFile')
  const page = useQuery('page')
  const query = useQuery('query')
  const dispatch = useAppDispatch()
  const { list, loadedFolderName, loadedBucketName, seedEnabled } =
    useAppSelector((x) => ({
      list: x.groundTruth.files,
      loadedFolderName: x.groundTruth.folderName,
      loadedBucketName: x.groundTruth.bucketName,
      seedEnabled: x.groundTruth.seedEnabled,
    }))

  const filteredList =
    query != null && query.length > 2
      ? list.filter(
          (l) =>
            l.summary.id.includes(query) ||
            l.summary.legacyLabels.some((l) => l.includes(query)),
        )
      : list
  const pageSize = 10
  const pageInt = page != null ? parseInt(page) : 0
  const nav = useNavigate()
  const navTo = (
    pageNext: number,
    datasetFileNext: string | undefined,
    queryNext: string | undefined,
  ) =>
    nav({
      search:
        `page=${pageNext}&folderName=${folderName}` +
        `${datasetFileNext != null ? '&datasetFile=' + datasetFileNext : ''}` +
        `${queryNext != null ? '&query=' + queryNext : ''}`,
      pathname: '.',
    })
  const pages = Math.floor(filteredList.length / pageSize)
  const pagedList = filteredList.slice(
    pageInt * pageSize,
    (pageInt + 1) * pageSize,
  )
  useEffect(() => {
    if (
      bucketName != null &&
      (loadedFolderName !== folderName || loadedBucketName !== bucketName)
    )
      dispatch(
        loadGroundTruthList({
          bucketName,
          folderName,
          datasetFile: datasetFile ?? undefined,
        }),
      )
  }, [bucketName, folderName, loadedFolderName, loadedBucketName, datasetFile])
  useEffect(() => {
    if (bucketName != null)
      for (const l of pagedList) {
        if (l.data == null)
          dispatch(
            loadGroundTruth({ bucketName, folderName, id: l.summary.id }),
          )
      }
  }, [pagedList.length, pageInt])
  useEffect(() => {
    dispatch(seedGroundTruthEnabled())
  }, [])
  const seed = (id: string) => {
    if (bucketName == null) return
    dispatch(
      seedGroundTruth({
        bucketName,
        folderName,
        id,
      }),
    )
  }
  const render = (
    id: string,
    viewCount: number,
    renderParametersName: Renderers,
  ) => {
    if (bucketName == null) return
    dispatch(
      renderImages({
        bucketName,
        folderName,
        id,
        viewCount,
        renderParametersName,
      }),
    )
  }
  return (
    <>
      <TextSearch
        query={query ?? ''}
        onQueryChanged={(nextQuery) =>
          navTo(0, datasetFile ?? undefined, nextQuery)
        }
      />
      <DatasetFileEditor
        datasetFile={datasetFile ?? ''}
        onDatasetFileChanged={(datasetFileNext) => {
          navTo(pageInt, datasetFileNext, query ?? undefined)
        }}
      />
      <GTGrid showDatasetCols={datasetFile != null}>
        <h5>Id</h5>
        {datasetFile != null ? <h5>Legacy label</h5> : null}
        <h5
          style={{
            gridColumnStart: 4,
          }}
        >
          Ground truth
        </h5>
        <h5
          style={{
            gridColumnStart: 5,
          }}
        >
          Seed
        </h5>
        <h5
          style={{
            gridColumnStart: 6,
          }}
        >
          Render
          {pagedList.length === 0 ? null : (
            <TinyButton
              onClick={() =>
                pagedList.forEach((item) =>
                  item.rendering.forEach((r) => {
                    if (r.state === 'rendering') return
                    if (r.state === 'rendering-failed') return
                    render(item.summary.id, r.viewCount, r.renderParametersName)
                  }),
                )
              }
            >
              All
            </TinyButton>
          )}
        </h5>

        {pagedList.map((l) => (
          <>
            <label
              style={{
                gridColumnStart: 1,
              }}
            >
              {l.summary.id}
            </label>
            <div>
              {l.summary.legacyLabels.map((ll) => (
                <label
                  key={ll}
                  style={{
                    padding: '0.5rem',
                  }}
                >
                  {ll}
                </label>
              ))}
            </div>
            <CLinkContainer>
              <Link
                to={`./${
                  l.summary.id
                }?folderName=${folderName}&scaleFactor=1&${l.summary.legacyLabels
                  .map((e) => 'expected=' + e)
                  .join('&')}`}
              >
                Edit
              </Link>
            </CLinkContainer>
            {l.data == null ? (
              'loading'
            ) : (
              <GroundTruthRowDetails
                data={l.data}
                id={l.summary.id}
                folderName={folderName}
                seed={() => seed(l.summary.id)}
                seedEnabled={seedEnabled}
                rendering={l.rendering}
                renderImages={(viewCount, renderParametersName) =>
                  render(l.summary.id, viewCount, renderParametersName)
                }
              />
            )}
          </>
        ))}
      </GTGrid>
      <Pager
        page={pageInt}
        maxPages={pages}
        onPageChanged={(pageNext) =>
          navTo(pageNext, datasetFile ?? '', query ?? undefined)
        }
      />
    </>
  )
}
