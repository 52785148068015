import styled from 'styled-components'
import { colours } from 'styles/colours'
import { spacing } from 'styles/spacing'

export const NavigationBox = styled.div`
  padding: ${spacing.Small};
  border-bottom: 1px solid ${colours.disabled};
  display: flex;
  gap: ${spacing.Medium};
`
export const NavigationItem = styled.div`
  display: flex;
  gap: ${spacing.ExtraSmall};
  justify-content: space-between;
  cursor: pointer;
  color: ${colours.primary};
  font-size: 14px;
  align-items: center;
`
