import { useAppDispatch, useAppSelector } from 'infrastructure/hooks'
import { IImage, IImageLabel } from 'infrastructure/types/image'
import { ILabel } from 'infrastructure/types/label'
import { useRef, useState, useEffect } from 'react'
import { ReactElement } from 'react'
import { actions } from 'views/image/imageReducer'
import { CanvasBox } from './styles'
import { ViewCanvas } from './viewCanvas'
import { Stage, Layer, Rect, Image } from 'react-konva'
import { v4 as uuidv4 } from 'uuid'
import { saveImage } from 'views/image/imageActions'
import { NavigationButtons } from './navigationButtons'

export interface ICanvasProps {
  image: IImage | null
  selectedLabel: ILabel | null
}

export const Canvas = (props: ICanvasProps): ReactElement => {
  const dispatch = useAppDispatch()
  const { image, selectedLabel } = props
  const isEditing = useAppSelector((state) => state.image.isEditing)
  const selectedImageLabel = useAppSelector(
    (state) => state.image.selectedImageLabel,
  )
  const labels = useAppSelector((state) => state.labels.labels)
  const [stageSize, setStageSize] = useState({ width: 0, height: 0 })
  const [isDrawing, setIsDrawing] = useState(false)
  const [drawCoordinates, setDrawCoordinates] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })
  const [originalDrawCoordinates, setOriginalDrawCoordinates] = useState({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  })

  const [img, setImage] = useState(new window.Image())

  const canvasRef = useRef<HTMLDivElement>(null)
  /*const canvasRef = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      setStageSize({
        width: node.offsetWidth,
        height: node.offsetHeight,
      })
    }
  }, [])*/

  const labelsMapped = new Map<string, string>(
    labels.map((label) => {
      return [label.labelId, label.colour]
    }),
  )

  useEffect(() => {
    if (!image) return
    const img = new window.Image()
    img.src = image.location ?? ''
    setImage(img)
  }, [])

  useEffect(() => {
    const checkSize = () => {
      if (canvasRef && canvasRef.current) {
        var canvas = canvasRef.current
        setStageSize({
          width: canvas.offsetWidth,
          height: canvas.offsetHeight,
        })
      }
    }

    window.addEventListener('resize', checkSize)
    return () => window.removeEventListener('resize', checkSize)
  }, [])

  useEffect(() => {
    if (canvasRef && canvasRef.current) {
      var canvas = canvasRef.current
      setStageSize({
        width: canvas.offsetWidth,
        height: canvas.offsetHeight,
      })
    }
  }, [canvasRef.current])

  const CheckDelete = (e: React.KeyboardEvent) => {
    if (e.key === 'Delete') {
      dispatch(actions.removeSelectedImageLabel())
      dispatch(saveImage())
    }
  }

  const AddLabel = (x: number, y: number, width: number, height: number) => {
    if (image && selectedLabel) {
      const newLabel: IImageLabel = {
        id: uuidv4(),
        labelId: selectedLabel?.labelId,
        verified: true,
        x1: x / stageSize.width,
        y1: y / stageSize.height,
        x2: (x + width) / stageSize.width,
        y2: (y + height) / stageSize.height,
      }
      dispatch(actions.addImageLabel(newLabel))
      dispatch(saveImage())
    }
  }

  const handleMouseDown = (event: any) => {
    if (!selectedImageLabel && selectedLabel) {
      setIsDrawing(true)
      const { x, y } = event.target.getStage().getPointerPosition()
      setDrawCoordinates({ x, y, width: 0, height: 0 })
      setOriginalDrawCoordinates({ x, y, width: 0, height: 0 })
    }
  }

  const handleMouseMove = (event: any) => {
    if (isDrawing && selectedLabel) {
      const { x, y } = event.target.getStage().getPointerPosition()
      const x1 = Math.min(x, originalDrawCoordinates.x)
      const y1 = Math.min(y, originalDrawCoordinates.y)
      const width = Math.abs(originalDrawCoordinates.x - x)
      const height = Math.abs(originalDrawCoordinates.y - y)

      if (width > 10 && height > 10)
        setDrawCoordinates({
          x: x1,
          y: y1,
          width,
          height,
        })
    }
  }

  const handleMouseUp = () => {
    if (
      isDrawing &&
      selectedLabel &&
      drawCoordinates.width > 10 &&
      drawCoordinates.height > 10
    ) {
      AddLabel(
        drawCoordinates.x,
        drawCoordinates.y,
        drawCoordinates.width,
        drawCoordinates.height,
      )
    }
    setIsDrawing(false)
    setDrawCoordinates({ x: 0, y: 0, width: 0, height: 0 })
    setOriginalDrawCoordinates({ x: 0, y: 0, width: 0, height: 0 })
  }

  const handleMouseClick = () => {
    if (selectedImageLabel) {
      dispatch(actions.selectImageLabel(null))
      dispatch(actions.setIsEditing(false))
    }
  }

  return (
    <>
      {image && (
        <CanvasBox
          ref={canvasRef}
          url={image.location}
          onKeyDown={CheckDelete}
          tabIndex={0}
        >
          <div className="actions"></div>
          <div className="navigation-buttons">
            <NavigationButtons image={image}></NavigationButtons>
          </div>
          <Stage
            width={stageSize.width}
            height={stageSize.height}
            onMouseDown={handleMouseDown}
            onMousemove={handleMouseMove}
            onMouseup={handleMouseUp}
            onClick={handleMouseClick}
          >
            <Layer>
              <Image
                width={stageSize.width}
                height={stageSize.height}
                image={img}
              />

              <ViewCanvas
                image={image}
                labelsMap={labelsMapped}
                canvasWidth={stageSize.width}
                canvasHeight={stageSize.height}
                selectedImageLabel={selectedImageLabel}
                onToggleImageLabel={(label: IImageLabel) =>
                  dispatch(actions.toggleImageLabel(label))
                }
                onSelectImageLabel={(label: IImageLabel) =>
                  dispatch(actions.selectImageLabel(label))
                }
                onUpdateRectangle={(label: IImageLabel) => {
                  dispatch(actions.updateImageLabel(label))
                  dispatch(saveImage())
                }}
                setIsEditing={(isEditing: boolean) =>
                  dispatch(actions.setIsEditing(isEditing))
                }
              />
              {!isEditing && (
                <Rect
                  x={drawCoordinates.x}
                  y={drawCoordinates.y}
                  width={drawCoordinates.width}
                  height={drawCoordinates.height}
                  fill="transparent"
                  stroke={selectedLabel?.colour ?? ''}
                />
              )}
            </Layer>
          </Stage>
        </CanvasBox>
      )}
    </>
  )
}
