import { Renderers } from 'infrastructure/types/rendering'

export const ApiBaseUrl = process.env.REACT_APP_API_PATH

const imageResourceUrl = `${ApiBaseUrl}/images`
const labelResourceUrl = `${ApiBaseUrl}/labels`
const tagResourceUrl = `${ApiBaseUrl}/tags`
const dicosResourceUrl = `${ApiBaseUrl}/dicos`
const trainResourceUrl = `${ApiBaseUrl}/train`
const trainingResourceUrl = `${ApiBaseUrl}/training`
const renderingPropertiesResourceUrl = `${ApiBaseUrl}/renderproperties`
const groundTruthsUrl = `${ApiBaseUrl}/ground-truths`
const hifScansUrl = `${ApiBaseUrl}/hifscans`

const images = (
  pageNumber: number,
  pageSize: number,
  searchText: string,
  labels: string[],
  tags: string[],
  unlabelledOnly: boolean,
): string => {
  const labelsQuery = labels.map((x) => `&labels=${x}`)
  const tagsQuery = tags.map((x) => `&tags=${x}`)
  return `${imageResourceUrl}?page=${pageNumber}&pageSize=${pageSize}&unlabelledOnly=${unlabelledOnly}&searchText=${searchText}${labelsQuery}${tagsQuery}`
}

const image = (imageId: string): string => `${imageResourceUrl}/${imageId}`

const labels = (): string => `${labelResourceUrl}`
const label = (labelId: string): string => `${labelResourceUrl}/${labelId}`

const tags = (): string => `${tagResourceUrl}`
const tag = (tagId: string): string => `${tagResourceUrl}/${tagId}`

const renderingProperties = (): string => `${renderingPropertiesResourceUrl}`

const importDicos = (): string => `${dicosResourceUrl}/import`
const dicosImports = (pageNumber: number, pageSize: number): string =>
  `${dicosResourceUrl}/import?page=${pageNumber}&pageSize=${pageSize}`

const searchDicos = (
  pageNumber: number,
  pageSize: number,
  searchText: string,
): string =>
  `${dicosResourceUrl}?page=${pageNumber}&pageSize=${pageSize}&searchText=${searchText}`

const getDicosVtiUri = (dicosId: string): string =>
  `${dicosResourceUrl}/${dicosId}/raw`

const train = (
  searchText: string,
  labels: string[],
  tags: string[],
  unlabelledOnly: boolean,
): string => {
  const labelsQuery = labels.map((x) => `&labels=${x}`)
  const tagsQuery = tags.map((x) => `&tags=${x}`)
  return `${trainResourceUrl}?searchText=${searchText}&unlabelledOnly=${unlabelledOnly}${labelsQuery}${tagsQuery}`
}

const training = (trainingId: string): string =>
  `${trainingResourceUrl}/${trainingId}`
const trainings = (pageNumber: number, pageSize: number): string =>
  `${trainingResourceUrl}?page=${pageNumber}&pageSize=${pageSize}`
const dicosTags = (): string => `${tagResourceUrl}/dicos`

const paramsSectionG = (p: { [key: string]: string | number | undefined }) => {
  const params = Object.entries(p)
    .map(([key, value]) => (value == null ? null : `${key}=${value}`))
    .filter((x) => x != null)

  return params.length === 0 ? '' : `?${params.join('&')}`
}

const groundTruthsList = (
  bucketName: string,
  folderName: string | undefined,
  datasetFile: string | undefined,
): string =>
  `${groundTruthsUrl}/${bucketName}${paramsSectionG({
    prefix: folderName,
    datasetFile: datasetFile,
  })}`
const groundTruth = (
  bucketName: string,
  folderName: string | undefined,
  id: string,
): string =>
  `${groundTruthsUrl}/${bucketName}/${id}${paramsSectionG({
    prefix: folderName,
  })}`
const groundTruthBeginEditing = (
  bucketName: string,
  folderName: string | undefined,
  id: string,
) =>
  `${groundTruthsUrl}/${bucketName}/${id}/begin-editing${paramsSectionG({
    prefix: folderName,
  })}`
const groundTruthEndEditing = (
  bucketName: string,
  folderName: string | undefined,
  id: string,
) =>
  `${groundTruthsUrl}/${bucketName}/${id}/end-editing${paramsSectionG({
    prefix: folderName,
  })}`

const groundTruthApprove = (
  bucketName: string,
  folderName: string | undefined,
  id: string,
): string =>
  `${groundTruthsUrl}/${bucketName}/${id}/approve${paramsSectionG({
    prefix: folderName,
  })}`
const groundTruthReject = (
  bucketName: string,
  folderName: string | undefined,
  id: string,
): string =>
  `${groundTruthsUrl}/${bucketName}/${id}/reject${paramsSectionG({
    prefix: folderName,
  })}`
const groundTruthRaw = (
  bucketName: string,
  folderName: string | undefined,
  id: string,
  scaleFactor: number,
): string =>
  `${groundTruthsUrl}/${bucketName}/${id}/raw${paramsSectionG({
    prefix: folderName,
    scaleFactor,
  })}`
const groundTruthSeed = (
  bucketName: string,
  folderName: string | undefined,
  id: string,
): string =>
  `${groundTruthsUrl}/${bucketName}/${id}/seed${paramsSectionG({
    prefix: folderName,
  })}`
const groundTruthSeedEnabled = `${groundTruthsUrl}/seed/health`
const renderImages = (
  bucketName: string,
  folderName: string | undefined,
  id: string,
): string =>
  `${groundTruthsUrl}/${bucketName}/${id}/render${paramsSectionG({
    prefix: folderName,
  })}`
const renderedImages = (
  bucketName: string,
  folderName: string | undefined,
  id: string,
  viewCount: number,
  renderParametersName: Renderers,
): string =>
  `${groundTruthsUrl}/${bucketName}/${id}/rendered-images${paramsSectionG({
    prefix: folderName,
    viewCount,
    renderParametersName,
  })}`
const renderedImagesBeginViewing = (
  bucketName: string,
  folderName: string | undefined,
  id: string,
  viewCount: number,
  renderParametersName: Renderers,
): string =>
  `${groundTruthsUrl}/${bucketName}/${id}/rendered-images/begin-viewing${paramsSectionG(
    {
      prefix: folderName,
      viewCount,
      renderParametersName,
    },
  )}`

const renderedImagesEndViewing = (
  bucketName: string,
  folderName: string | undefined,
  id: string,
  viewCount: number,
  renderParametersName: Renderers,
): string =>
  `${groundTruthsUrl}/${bucketName}/${id}/rendered-images/end-viewing${paramsSectionG(
    {
      prefix: folderName,
      viewCount,
      renderParametersName,
    },
  )}`

const groundTruthImagesApproveOrReject = (
  bucketName: string,
  folderName: string | undefined,
  id: string,
  viewCount: number,
  renderParametersName: Renderers,
  operation: 'approve' | 'reject',
): string =>
  `${groundTruthsUrl}/${bucketName}/${id}/rendered-images/${operation}${paramsSectionG(
    {
      prefix: folderName,
      viewCount,
      renderParametersName,
    },
  )}`

const queryIndex = (): string => `${ApiBaseUrl}/ground-truths-idx/filtered`

const querySingleItem = ({
  name,
  folder,
  bucketName,
}: {
  name: string
  folder?: string
  bucketName: string
}): string =>
  `${ApiBaseUrl}/ground-truths-idx/index-item${paramsSectionG({
    name,
    folder,
    bucketName,
  })}`

const queryIndexQueue = (): string =>
  `${ApiBaseUrl}/ground-truths-idx/index-queue`

const queryFilterOptions = () =>
  `${ApiBaseUrl}/ground-truths-idx/filter-options`

const rebuildIndexFromDatasetFile = (
  bucketName: string,
  datasetFile: string,
  folder?: string,
): string =>
  `${ApiBaseUrl}/ground-truths-idx/index-from-dataset-file${paramsSectionG({
    folder: folder,
    bucketName,
    datasetFile,
  })}`

const rebuildIndex = (bucketName: string): string =>
  `${ApiBaseUrl}/ground-truths-idx/index/${bucketName}`

export const urls = {
  images,
  image,
  labels,
  label,
  tags,
  tag,
  importDicos,
  searchDicos,
  train,
  dicosImports,
  training,
  trainings,
  getDicosVtiUri,
  renderingProperties,
  dicosTags,
  groundTruthsList,
  groundTruth,
  groundTruthBeginEditing,
  groundTruthEndEditing,
  groundTruthApprove,
  groundTruthReject,
  groundTruthImagesApproveOrReject,
  groundTruthRaw,
  groundTruthSeed,
  groundTruthSeedEnabled,
  renderImages,
  renderedImagesBeginViewing,
  renderedImagesEndViewing,
  renderedImages,
  queryIndex,
  querySingleItem,
  queryIndexQueue,
  queryFilterOptions,
  rebuildIndexFromDatasetFile,
  rebuildIndex,
}
