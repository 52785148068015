import * as signalR from '@microsoft/signalr'
import { ApiBaseUrl } from 'infrastructure/api/urls'
import { FC, useEffect } from 'react'
import { actions } from './groundTruthReducer'
import { querySingleItem } from './groundTruthActions'
import { useAppDispatch } from 'infrastructure/hooks'
import { useStore } from 'react-redux'
import { RootState } from 'infrastructure/store'

const GroundTruthHubConnector: FC = () => {
  const dispatch = useAppDispatch()
  const store = useStore()
  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(`${ApiBaseUrl}/hubs/ground-truths`, {
        //Confusingly this has nothing to do with the bearer token, it is for cookies and sticky sessions only
        withCredentials: false,
      })
      .withAutomaticReconnect()
      .build()

    connection.on(
      'NewItemIndexed',
      (e: { bucket: string; folder?: string; name: string }) => {
        // We must use a call to getState right here, otherwise we run risk of hitting the stale closure problem
        const currentState = store.getState() as RootState
        const isTracked = currentState.groundTruth.trackedDicos.items.some(
          (q) =>
            q.bucket === e.bucket && q.folder === e.folder && e.name === e.name,
        )

        if (!isTracked) return

        dispatch(
          querySingleItem({
            bucketName: e.bucket,
            folder: e.folder,
            name: e.name,
          }),
        )
      },
    )
    connection.on(
      'UserEditingItem',
      ({
        bucket,
        folder,
        name,
        userId,
      }: {
        bucket: string
        folder?: string
        name: string
        userId: string
      }) => {
        dispatch(actions.userEditingItem({ bucket, folder, name, userId }))
      },
    )
    connection.on(
      'UserEditingItemDone',
      ({
        bucket,
        folder,
        name,
        userId,
      }: {
        bucket: string
        folder?: string
        name: string
        userId: string
      }) => {
        dispatch(actions.userEditingItemDone({ bucket, folder, name, userId }))
      },
    )
    connection.on(
      'BeginRenderingItem',
      (e: {
        bucket: string
        folder?: string
        name: string
        renderParametersName: string
        viewCount: number
      }) => {
        dispatch(
          actions.beginRenderingItem({
            bucket: e.bucket,
            folder: e.folder,
            name: e.name,
            renderParametersName: e.renderParametersName,
            viewCount: e.viewCount,
          }),
        )
      },
    )
    connection.on(
      'RenderingItemComplete',
      (e: {
        bucket: string
        folder?: string
        name: string
        renderParametersName: string
        viewCount: number
      }) => {
        dispatch(
          actions.renderingItemComplete({
            bucket: e.bucket,
            folder: e.folder,
            name: e.name,
            renderParametersName: e.renderParametersName,
            viewCount: e.viewCount,
          }),
        )
      },
    )

    connection.on(
      'UserViewingRenders',
      ({
        bucket,
        folder,
        name,
        renderParametersName,
        viewCount,
        userId,
      }: {
        bucket: string
        folder?: string
        name: string
        renderParametersName: string
        viewCount: number
        userId: string
      }) => {
        dispatch(
          actions.userViewingRenders({
            bucket,
            folder,
            name,
            renderParametersName,
            viewCount,
            userId,
          }),
        )
      },
    )
    connection.on(
      'UserViewingRendersDone',
      ({
        bucket,
        folder,
        name,
        renderParametersName,
        viewCount,
        userId,
      }: {
        bucket: string
        folder?: string
        name: string
        renderParametersName: string
        viewCount: number
        userId: string
      }) => {
        dispatch(
          actions.userViewingRendersDone({
            bucket,
            folder,
            name,
            renderParametersName,
            viewCount,
            userId,
          }),
        )
      },
    )

    connection.start().catch((err) => {
      window.console.log('SignalR error onStart: ', err)
    })

    return () => {
      connection.stop()
    }
  }, [])

  return <></>
}

export default GroundTruthHubConnector
