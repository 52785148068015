import styled from 'styled-components'
import { colours } from 'styles/colours'
import { spacing } from 'styles/spacing'
import { RemSize } from 'styles/utility'

export const CanvasBox = styled.div<{ url: string }>`
  background-color: ${colours.contrastLight};
  flex-basis: 100%;
  cursor: crosshair;
  position: relative;

  rect {
    cursor: pointer;
  }

  circle {
    cursor: pointer;
  }

  .actions {
    position: absolute;
    display: flex;
    flex-direction: column;
    gap: ${RemSize(10)};
    cursor: default;
    z-index: 1;
  }

  .navigation-buttons {
    position: absolute;
    gap: ${spacing.ExtraSmall};
    z-index: 1;
    right: ${spacing.ExtraSmall};
  }
`
export const ButtonContainer = styled.div`
  display: flex;
  gap: ${spacing.ExtraSmall};
  padding: ${spacing.ExtraSmall};

  button {
    min-width: ${RemSize(30)};
    height: ${RemSize(40)};
  }
`
