import { Spinner } from 'components/spinner/spinner'
import { format, parseISO } from 'date-fns'
import { useAppDispatch, useAppSelector } from 'infrastructure/hooks'
import { TrainingStatusMapper } from 'infrastructure/types/training'
import { ReactElement, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { DataTable } from './styles'
import { loadTrainingRun } from './trainingRunActions'

export const TrainingRunView = (): ReactElement => {
  const dispatch = useAppDispatch()
  const { id } = useParams()
  const { trainingRun, fetchState } = useAppSelector(
    (state) => state.trainingRun,
  )

  useEffect(() => {
    if (id) {
      dispatch(loadTrainingRun(id))
    }
  }, [dispatch])

  return (
    <div>
      {fetchState.loading ? (
        <Spinner size="80px" />
      ) : (
        <>
          {trainingRun && (
            <DataTable>
              <div>Date </div>
              <div>
                {format(parseISO(trainingRun.dateCreated), 'dd/MM/yyyy HH:mm')}
              </div>

              <div>Status </div>
              <div>{TrainingStatusMapper.get(trainingRun.status)}</div>

              <div>Average precision </div>
              <div>{trainingRun.result?.averagePrecision ?? '-'}</div>

              <div>Precision </div>
              <div>{trainingRun.result?.precision ?? '-'}</div>

              <div>Recall</div>
              <div>{trainingRun.result?.recall ?? '-'}</div>

              <div>Total images</div>
              <div>{trainingRun.result?.totalImages ?? '-'}</div>

              <div>Validation images</div>
              <div>{trainingRun.result?.validationImages ?? '-'}</div>

              <div>Test images</div>
              <div>{trainingRun.result?.testImages ?? '-'}</div>

              <div>Training Images</div>
              <div>{trainingRun.result?.trainingImages ?? '-'}</div>
            </DataTable>
          )}
        </>
      )}
    </div>
  )
}
