import { ReactElement } from 'react'
import { ToggleWrapper, ToggleItem } from './styles'

export interface IToggleProps {
  active: boolean
  onToggle: (active: boolean) => void
}

export const Toggle = (props: IToggleProps): ReactElement => {
  const { active, onToggle } = props

  return (
    <ToggleWrapper>
      <ToggleItem
        onClick={() => onToggle(true)}
        className={active ? 'active' : ''}
      >
        Active
      </ToggleItem>
      <ToggleItem
        onClick={() => onToggle(false)}
        className={!active ? 'active' : ''}
      >
        Disable
      </ToggleItem>
    </ToggleWrapper>
  )
}
