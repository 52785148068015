import { FC } from 'react'
import { CheckBox } from 'views/gallery/components/filter/styles'

export const CheckBoxSt: FC<{
  label?: string
  checked: boolean
  onCheckedChanged: (b: boolean) => void
}> = ({ label, checked, onCheckedChanged }) => (
  <CheckBox>
    <input
      type="checkbox"
      checked={checked}
      onChange={(e) => onCheckedChanged(e.target.checked)}
    />
    {label}
  </CheckBox>
)
