import styled from 'styled-components'
import { colours } from 'styles/colours'
import { spacing } from 'styles/spacing'

export const Table = styled.table`
  background-color: ${colours.contrastLight};
  width: 100%;
  padding: ${spacing.Small};
  font-size: 14px;

  th {
    text-align: left;
    color: ${colours.disabled};
    font-weight: normal;
  }

  th,
  td {
    padding: ${spacing.Small};
  }

  th,
  tr:not(:last-child) td {
    border-bottom: 1px solid ${colours.disabled};
  }

  .clickable {
    cursor: pointer;
  }
`

export const ExpandableTable = styled.div<{
  columns?: number
  subColumns?: number
}>`
  width: 100%;

  .row {
    display: grid;
    grid-template-columns: repeat(${(props) => props.columns ?? 4}, 1fr);
    grid-auto-flow: dense;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid ${colours.disabled};
    span {
      padding: ${spacing.Small};
    }
  }

  .row.clickable {
    cursor: pointer;
  }

  .header {
    background-color: ${colours.primary};
    color: ${colours.contrastLight};
  }

  svg {
    cursor: pointer;
    align-self: center;
  }

  .sub-row {
    grid-column: 1 / -1;

    background-color: ${colours.contrastLight};

    .header {
      background-color: ${colours.disabled};
    }

    .row {
      grid-template-columns: repeat(${(props) => props.subColumns ?? 4}, 1fr);
    }
  }
`
