import { ReactElement } from 'react'
import { Navigation } from './components/Navigation/navigation'
import { LandingBox } from './styles'
import { TrainingListTable } from 'views/training/components/trainingListTable'

export const LandingView = (): ReactElement => {
  return (
    <LandingBox>
      <Navigation />
      <TrainingListTable allowLoadMore={false} />
    </LandingBox>
  )
}
