import styled from 'styled-components'
import { colours } from 'styles/colours'
import { spacing } from 'styles/spacing'

export const DataTable = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: ${spacing.Small};
  max-width: 30rem;

  & > div {
    border-bottom: 1px ${colours.disabled} solid;
  }
`
