import { useAppDispatch, useAppSelector } from 'infrastructure/hooks'
import { ReactElement, useEffect, useState } from 'react'
import { loadDicos } from '../directoryActions'
import { actions } from '../directoryReducer'
import { Spinner } from 'components/spinner/spinner'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import { colours } from 'styles/colours'
import { PagingButton } from 'components/button/styles'
import { ExpandableTable } from 'components/table/styles'
import { IDicos } from 'infrastructure/types/dicos'
import { Search } from 'components/searchBar/searchBar'
import { DicosTableBox, DirectoryFilter } from './styles'
import { useNavigate } from 'react-router-dom'

export const DicosTable = (): ReactElement => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { dicos, fetchState, pageSize, pageNumber } = useAppSelector(
    (state) => state.directory,
  )

  const [expandedRowIds, setExpandedRowIds] = useState<string[]>([])

  useEffect(() => {
    dispatch(actions.initialise())
    dispatch(loadDicos())
  }, [dispatch])

  const onLoadMore = () => {
    dispatch(actions.setPageNumber(pageNumber + 1))
    dispatch(loadDicos())
  }

  const setSearchText = (searchText: string) => {
    dispatch(actions.setSearchText(searchText))
    dispatch(loadDicos())
  }

  const expandRow = (id: string) => {
    setExpandedRowIds([...expandedRowIds, id])
  }

  const collapseRow = (id: string) => {
    setExpandedRowIds(expandedRowIds.filter((x) => x !== id))
  }

  const rowIsExpanded = (row: IDicos) =>
    expandedRowIds.find((x) => x === row.id)

  return (
    <DicosTableBox>
      <DirectoryFilter>
        <Search placeholder="Search DICOS by name" onUpdate={setSearchText} />
      </DirectoryFilter>
      <ExpandableTable columns={3} subColumns={6}>
        <div className="row header">
          <span>Name</span>
          <span></span>
          <span></span>
        </div>

        {dicos.map((value, index) => (
          <div className="row" key={index}>
            <span>{value.name}</span>
            <span>
              {value.vtiAvailable && <a href={`/render/${value.id}`}>3D</a>}
            </span>

            <span className="right">
              {!rowIsExpanded(value) && (
                <ChevronDownIcon
                  width={18}
                  height={18}
                  color={colours.disabled}
                  onClick={() => expandRow(value.id)}
                />
              )}
              {rowIsExpanded(value) && (
                <ChevronUpIcon
                  width={18}
                  height={18}
                  color={colours.disabled}
                  onClick={() => collapseRow(value.id)}
                />
              )}
            </span>

            {rowIsExpanded(value) && (
              <div className="sub-row">
                <div className="row header">
                  <span>Name</span>
                  <span>Render</span>
                  <span>X</span>
                  <span>Y</span>
                  <span>Z</span>
                  <span></span>
                </div>

                {value.images.map((image, imageIndex) => (
                  <div className="row" key={`sub-${imageIndex}`}>
                    <span>{image.name}</span>
                    <span>{image.volumePalette}</span>
                    <span>{image.renderOrientationX}</span>
                    <span>{image.renderOrientationY}</span>
                    <span>{image.renderOrientationZ}</span>
                    <span>
                      <figure
                        onClick={() => navigate(`gallery/${image.imageId}`)}
                      >
                        <img src={image.location} />
                      </figure>
                    </span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </ExpandableTable>
      {fetchState.loading ? (
        <Spinner size="80px" />
      ) : (
        <>
          {dicos.length % pageSize === 0 && (
            <PagingButton onClick={onLoadMore}>Load More</PagingButton>
          )}
        </>
      )}
    </DicosTableBox>
  )
}
