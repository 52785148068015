import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { endpoint } from 'infrastructure/api/endpoints'
import {
  IDicosImageMetaResponse,
  IGroundTruthDataRequest,
  IGroundTruthDataResponse,
  ITrackedDicosFilters,
} from 'infrastructure/api/response/groundTruth'
import { IApiError } from 'infrastructure/types/api'
import { Renderers } from 'infrastructure/types/rendering'
import { toast } from 'react-toastify'

export const loadGroundTruth = createAsyncThunk(
  'loadGroundTruth',
  async (
    {
      bucketName,
      folderName,
      id,
    }: { bucketName: string; folderName?: string; id: string },
    { rejectWithValue },
  ) => {
    try {
      const pl = await endpoint.getGroundTruth(bucketName, folderName, id)
      return {
        id: id,
        groundTruth: pl.groundTruth,
        seeded: pl.seeded,
        approvals: pl.approvals ?? [],
      }
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      return rejectWithValue(castError.response?.data)
    }
  },
)

export const saveGroundTruth = createAsyncThunk(
  'saveGroundTruth',
  async (
    {
      bucketName,
      folderName,
      id,
      data,
    }: {
      bucketName: string
      folderName: string | undefined
      id: string
      data: IGroundTruthDataRequest
    },
    { rejectWithValue },
  ) => {
    try {
      return await endpoint.saveGroundTruth(bucketName, folderName, id, data)
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })

      return rejectWithValue(castError.response?.data)
    }
  },
)

export const approveGroundTruth = createAsyncThunk(
  'approveGroundTruth',
  async (
    {
      bucketName,
      folderName,
      id,
    }: {
      bucketName: string
      folderName: string | undefined
      id: string
    },
    { rejectWithValue },
  ) => {
    try {
      return await endpoint.approveGroundTruth(bucketName, folderName, id)
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })

      return rejectWithValue(castError.response?.data)
    }
  },
)

export const rejectGroundTruth = createAsyncThunk(
  'rejectGroundTruth',
  async (
    {
      bucketName,
      folderName,
      id,
    }: {
      bucketName: string
      folderName: string | undefined
      id: string
    },
    { rejectWithValue },
  ) => {
    try {
      return await endpoint.rejectGroundTruth(bucketName, folderName, id)
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })

      return rejectWithValue(castError.response?.data)
    }
  },
)

export const seedGroundTruth = createAsyncThunk(
  'seedGroundTruth',
  async (
    {
      bucketName,
      folderName,
      id,
    }: {
      bucketName: string
      folderName: string | undefined
      id: string
    },
    { rejectWithValue },
  ) => {
    try {
      return await endpoint.seedGroundTruth(bucketName, folderName, id)
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })

      return rejectWithValue(castError.response?.data)
    }
  },
)
export const seedGroundTruthEnabled = createAsyncThunk(
  'seedGroundTruthEnabled',
  async (_, { rejectWithValue }) => {
    try {
      return await endpoint.seedGroundTruthEnabled()
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })

      return rejectWithValue(castError.response?.data)
    }
  },
)
export const renderImages = createAsyncThunk(
  'renderImages',
  async (
    {
      bucketName,
      folderName,
      id,
      viewCount,
      renderParametersName,
    }: {
      bucketName: string
      folderName: string | undefined
      id: string
      viewCount: number
      renderParametersName: Renderers
    },
    { rejectWithValue },
  ) => {
    try {
      return await endpoint.renderImages(bucketName, folderName, id, {
        viewCount,
        renderParametersName,
      })
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })

      return rejectWithValue(castError.response?.data)
    }
  },
)

export const loadRenderedImages = createAsyncThunk(
  'loadRenderedImages',
  async (
    {
      bucketName,
      folderName,
      id,
      viewCount,
      renderParametersName,
    }: {
      bucketName: string
      folderName: string | undefined
      id: string
      viewCount: number
      renderParametersName: Renderers
    },
    { rejectWithValue },
  ) => {
    try {
      return await endpoint.renderedImages(
        bucketName,
        folderName,
        id,
        viewCount,
        renderParametersName,
      )
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })

      return rejectWithValue(castError.response?.data)
    }
  },
)

export const loadGroundTruthList = createAsyncThunk(
  'loadGroundTruthList',
  async (
    {
      bucketName,
      folderName,
      datasetFile,
    }: { bucketName: string; folderName?: string; datasetFile?: string },
    { rejectWithValue },
  ) => {
    try {
      return await endpoint.getGroundTruths(bucketName, folderName, datasetFile)
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })

      return rejectWithValue(castError.response?.data)
    }
  },
)

export const saveRenderedImages = createAsyncThunk(
  'saveRenderedImages',
  async (
    {
      bucketName,
      folderName,
      id,
      viewCount,
      data,
    }: {
      bucketName: string
      folderName: string | undefined
      id: string
      viewCount: number
      data: IDicosImageMetaResponse[]
    },
    { rejectWithValue },
  ) => {
    try {
      const toSend = data.map((d) => ({
        fileName: d.fileName,
        renderer: d.renderer,
        groundTruth: d.groundTruth,
        // Deliberately ignoring d.signedUrl here because locally this is a base64 encoded string, which breaks the server due to upload requests being too large. It is not used anyway.
      }))
      return await endpoint.saveRenderedImages(
        bucketName,
        folderName,
        id,
        viewCount,
        toSend,
      )
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })

      return rejectWithValue(castError.response?.data)
    }
  },
)

export const approveOrRejectRenderedImages = createAsyncThunk(
  'approveRenderedImages',
  async (
    {
      bucketName,
      folderName,
      id,
      viewCount,
      renderParametersName,
      operation,
    }: {
      bucketName: string
      folderName: string | undefined
      id: string
      viewCount: number
      renderParametersName: Renderers
      operation: 'approve' | 'reject'
    },
    { rejectWithValue },
  ) => {
    try {
      return await endpoint.approveOrRejectImages(
        bucketName,
        folderName,
        id,
        viewCount,
        renderParametersName,
        operation,
      )
    } catch (error) {
      var castError = error as AxiosError<IApiError>
      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })
      return rejectWithValue(castError.response?.data)
    }
  },
)

export const queryIndex = createAsyncThunk(
  'queryIndex',
  async (filters: ITrackedDicosFilters, { rejectWithValue }) => {
    try {
      return await endpoint.queryIndex(filters)
    } catch (error) {
      var castError = error as AxiosError<IApiError>
      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })
      return rejectWithValue(castError.response?.data)
    }
  },
)

export const querySingleItem = createAsyncThunk(
  'querySingleItem',
  async (
    pl: { bucketName: string; folder?: string; name: string },
    { rejectWithValue },
  ) => {
    try {
      return await endpoint.querySingleItem(pl)
    } catch (error) {
      var castError = error as AxiosError<IApiError>
      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })
      return rejectWithValue(castError.response?.data)
    }
  },
)

export const queryIndexQueue = createAsyncThunk(
  'queryIndexQueue',
  async (x: Record<string, never>, { rejectWithValue }) => {
    try {
      return await endpoint.queryIndexQueue()
    } catch (error) {
      var castError = error as AxiosError<IApiError>
      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })
      return rejectWithValue(castError.response?.data)
    }
  },
)

export const queryFilterOptions = createAsyncThunk(
  'queryFilterOptions',
  async (filters: ITrackedDicosFilters, { rejectWithValue }) => {
    try {
      // eventually filters can be threaded to the backend to give context sensitive filters based on the current selection
      return await endpoint.queryFilterOptions()
    } catch (error) {
      var castError = error as AxiosError<IApiError>
      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })
      return rejectWithValue(castError.response?.data)
    }
  },
)

export const reindexFromFile = createAsyncThunk(
  'reindexFromFile',
  async (
    {
      bucketName,
      folder,
      datasetFile,
    }: { bucketName: string; folder?: string; datasetFile: string },
    { rejectWithValue },
  ) => {
    try {
      return await endpoint.reindexFromFile(bucketName, datasetFile, folder)
    } catch (error) {
      var castError = error as AxiosError<IApiError>
      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })
      return rejectWithValue(castError.response?.data)
    }
  },
)

export const groundTruthBeginEditing = createAsyncThunk(
  'groundTruthBeginEditing',
  async (
    {
      bucketName,
      folderName,
      id,
    }: { bucketName: string; folderName: string | undefined; id: string },
    { rejectWithValue },
  ) => {
    try {
      return await endpoint.groundTruthBeginEditing(bucketName, folderName, id)
    } catch (error) {
      var castError = error as AxiosError<IApiError>
      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })
      return rejectWithValue(castError.response?.data)
    }
  },
)

export const groundTruthEndEditing = createAsyncThunk(
  'groundTruthEndEditing',
  async (
    {
      bucketName,
      folderName,
      id,
    }: { bucketName: string; folderName: string | undefined; id: string },
    { rejectWithValue },
  ) => {
    try {
      return await endpoint.groundTruthEndEditing(bucketName, folderName, id)
    } catch (error) {
      var castError = error as AxiosError<IApiError>
      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })
      return rejectWithValue(castError.response?.data)
    }
  },
)

export const renderImagesBeginViewing = createAsyncThunk(
  'renderImagesBeginViewing',
  async (
    {
      bucketName,
      folderName,
      id,
      viewCount,
      renderParametersName,
    }: {
      bucketName: string
      folderName: string | undefined
      id: string
      viewCount: number
      renderParametersName: Renderers
    },
    { rejectWithValue },
  ) => {
    try {
      return await endpoint.renderImagesBeginViewing(
        bucketName,
        folderName,
        id,
        viewCount,
        renderParametersName,
      )
    } catch (error) {
      var castError = error as AxiosError<IApiError>
      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })
      return rejectWithValue(castError.response?.data)
    }
  },
)

export const renderImagesEndViewing = createAsyncThunk(
  'renderImagesEndViewing',
  async (
    {
      bucketName,
      folderName,
      id,
      viewCount,
      renderParametersName,
    }: {
      bucketName: string
      folderName: string | undefined
      id: string
      viewCount: number
      renderParametersName: Renderers
    },
    { rejectWithValue },
  ) => {
    try {
      return await endpoint.renderImagesEndViewing(
        bucketName,
        folderName,
        id,
        viewCount,
        renderParametersName,
      )
    } catch (error) {
      var castError = error as AxiosError<IApiError>
      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })
      return rejectWithValue(castError.response?.data)
    }
  },
)
