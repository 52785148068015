import { Button } from 'components/button/styles'
import styled from 'styled-components'
import { colours } from 'styles/colours'
import { spacing } from 'styles/spacing'
import { RemSize } from 'styles/utility'

export const GalleryBox = styled.div`
  background-color: ${colours.contrastLight};
  padding: ${spacing.Small};
`
export const Gallery = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: ${spacing.Small};
  justify-items: center;

  figure {
    margin: 0;
    padding: 0;
    border: 1px solid ${colours.disabled};
    cursor: pointer;
    min-width: ${RemSize(200)};
    max-width: ${RemSize(400)};
    background-color: transparent;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
`
