import { ReactElement } from 'react'
import { DirectoryViewBox, TabPanelContent } from './styles'
import { ImportDicosPanel } from './components/importDicosPanel'
import Tabs, { Tab, TabList, TabPanel } from '@atlaskit/tabs'
import { ImportTable } from './components/importTable'
import { DicosTable } from './components/dicosTable'
import { ImportTagsFileButton } from './components/importTagsFileButton'

export const DirectoryView = (): ReactElement => {
  return (
    <DirectoryViewBox>
      <Tabs id="default">
        <TabList>
          <Tab>Directory</Tab>
          <Tab>Imports</Tab>
        </TabList>
        <TabPanel>
          <TabPanelContent>
            <DicosTable />
          </TabPanelContent>
        </TabPanel>
        <TabPanel>
          <TabPanelContent>
            <ImportTagsFileButton />
            <ImportDicosPanel />
            <ImportTable />
          </TabPanelContent>
        </TabPanel>
      </Tabs>
    </DirectoryViewBox>
  )
}
