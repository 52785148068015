import { ILabelResponse } from 'infrastructure/api/response/label'
import { GetColorFromString } from 'infrastructure/utils/color'

export interface ILabel {
  labelId: string
  name: string
  colour: string
}

export const MapLabelsResponseToModels = (
  response: ILabelResponse[],
): ILabel[] => response.map((item) => MapLabelResponseToModel(item))

export const MapLabelResponseToModel = (response: ILabelResponse): ILabel => ({
  labelId: response.labelId,
  name: response.name,
  colour: GetColorFromString(response.name),
})
