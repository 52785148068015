import { FC } from 'react'
import { CheckBox } from 'views/gallery/components/filter/styles'
import Slider from './widgets/Slider'
import styled from 'styled-components'
import { ModelViewerOptions } from 'views/ground-truths/groundTruthReducer'
import { Button } from 'components/button/styles'

const FlexVert = styled.div`
  display: flex;
  flex-direction: column;
`

const CBPad = styled.div`
  padding-right: 4px;
`

const CBSimple: FC<{
  label: string
  checked: boolean
  onCheckedChanged: (b: boolean) => void
}> = ({ label, checked, onCheckedChanged }) => (
  <CBPad>
    <CheckBox>
      <label>
        <input
          type="checkbox"
          name="checkbox"
          checked={checked}
          onChange={(e) => onCheckedChanged(e.target.checked)}
        />
        {label}
      </label>
    </CheckBox>
  </CBPad>
)

const PlusMinusButtons = styled.div`
  display: flex;
  flex-direction: row;
`

const ButtonPL = styled.button`
  padding-left: 4px;
  padding-right: 4px;
`

const ModelViewerControls: FC<{
  options: ModelViewerOptions
  onOptionsChanged: (o: ModelViewerOptions) => void
  onReset: () => void
}> = ({ options, onOptionsChanged, onReset }) => {
  return (
    <FlexVert>
      <CBSimple
        label="Highlight threats"
        checked={options.highlightThreats}
        onCheckedChanged={(c) =>
          onOptionsChanged({ ...options, highlightThreats: c })
        }
      />
      <CBSimple
        label="Isolate threats"
        checked={options.isolateThreats}
        onCheckedChanged={(c) =>
          onOptionsChanged({ ...options, isolateThreats: c })
        }
      />
      <CBSimple
        label="Show labels"
        checked={options.showBoundingBox}
        onCheckedChanged={(c) =>
          onOptionsChanged({ ...options, showBoundingBox: c })
        }
      />
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <CBSimple
          label="ISO"
          checked={options.renderStyle === 'iso'}
          onCheckedChanged={(c) =>
            onOptionsChanged({
              ...options,
              renderStyle: c === true ? 'iso' : 'mip',
            })
          }
        />
        <CBSimple
          label="MIP"
          checked={options.renderStyle === 'mip'}
          onCheckedChanged={(c) =>
            onOptionsChanged({
              ...options,
              renderStyle: c === true ? 'mip' : 'iso',
            })
          }
        />
        <CBSimple
          label="ISO-MIP"
          checked={options.renderStyle === 'isomip'}
          onCheckedChanged={(c) =>
            onOptionsChanged({
              ...options,
              renderStyle: c === true ? 'isomip' : 'mip',
            })
          }
        />
        <CBSimple
          label="DVR"
          checked={options.renderStyle === 'dvr'}
          onCheckedChanged={(c) =>
            onOptionsChanged({
              ...options,
              renderStyle: c === true ? 'dvr' : 'iso',
            })
          }
        />
      </div>

      <PlusMinusButtons>
        <ButtonPL
          onClick={(c) =>
            onOptionsChanged({
              ...options,
              threshold: options.threshold - 10,
            })
          }
        >
          ---
        </ButtonPL>
        <ButtonPL
          onClick={(c) =>
            onOptionsChanged({
              ...options,
              threshold: options.threshold - 1,
            })
          }
        >
          -
        </ButtonPL>
        <ButtonPL
          onClick={(c) =>
            onOptionsChanged({
              ...options,
              threshold: options.threshold + 1,
            })
          }
        >
          +
        </ButtonPL>
        <ButtonPL
          onClick={(c) =>
            onOptionsChanged({
              ...options,
              threshold: options.threshold + 10,
            })
          }
        >
          +++
        </ButtonPL>
      </PlusMinusButtons>
      <Slider
        value={options.threshold}
        onChange={(v) =>
          onOptionsChanged({
            ...options,
            threshold: v,
          })
        }
        min={-1000}
        max={10000}
      />
      <input type="number" value={options.threshold} />
      <Slider
        value={options.maxThreshold}
        onChange={(v) =>
          onOptionsChanged({
            ...options,
            maxThreshold: v,
          })
        }
        min={options.threshold}
        max={20000}
      />
      <input type="number" value={options.maxThreshold} />
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
        }}
      >
        <CBSimple
          label="WRB"
          checked={options.colourMap === 'WhiteRedBlue'}
          onCheckedChanged={(c) =>
            onOptionsChanged({ ...options, colourMap: 'WhiteRedBlue' })
          }
        />
        <CBSimple
          label="Viridis"
          checked={options.colourMap === 'Viridis'}
          onCheckedChanged={(c) =>
            onOptionsChanged({ ...options, colourMap: 'Viridis' })
          }
        />
        <CBSimple
          label="Gray"
          checked={options.colourMap === 'Gray'}
          onCheckedChanged={(c) =>
            onOptionsChanged({ ...options, colourMap: 'Gray' })
          }
        />
        <CBSimple
          label="RedBlue"
          checked={options.colourMap === 'RedBlue'}
          onCheckedChanged={(c) =>
            onOptionsChanged({ ...options, colourMap: 'RedBlue' })
          }
        />
        <CBSimple
          label="FSS"
          checked={options.colourMap === 'FSS'}
          onCheckedChanged={(c) =>
            onOptionsChanged({ ...options, colourMap: 'FSS' })
          }
        />
        <CBSimple
          label="RGB"
          checked={options.colourMap === 'RGB'}
          onCheckedChanged={(c) =>
            onOptionsChanged({ ...options, colourMap: 'RGB' })
          }
        />
      </div>

      <CBSimple
        label="Auto rotate"
        checked={options.autoRotate}
        onCheckedChanged={(c) =>
          onOptionsChanged({ ...options, autoRotate: c })
        }
      />
      <ButtonPL onClick={onReset}>Reset</ButtonPL>
    </FlexVert>
  )
}

export default ModelViewerControls
