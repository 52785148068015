import { Button } from 'components/button/styles'
import { useAppDispatch } from 'infrastructure/hooks'
import { ChangeEventHandler, useState } from 'react'
import { uploadTagsFile } from '../directoryActions'

export const ImportTagsFileButton = () => {
  const dispatch = useAppDispatch()
  let inputRef: HTMLInputElement | null

  const handleFileEvent = (event: React.ChangeEvent<HTMLInputElement>) => {
    const formData = new FormData()
    const files = event.target.files

    if (!files) return

    for (let i = 0; i < files.length; i++) {
      formData.append(`files`, files[i], files[i].name)
    }

    dispatch(uploadTagsFile(formData))
  }

  return (
    <div>
      <input
        type="file"
        multiple
        onChange={handleFileEvent}
        ref={(refParam) => (inputRef = refParam)}
        hidden
      />

      <Button onClick={() => inputRef?.click()}>Upload Tags Files</Button>
    </div>
  )
}
