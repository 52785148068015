import styled from 'styled-components'
import { colours } from 'styles/colours'
import { spacing } from 'styles/spacing'

export const HeaderBar = styled.div`
  background-color: ${colours.primary};
  color: ${colours.contrastLight};
  width: 100%;
  padding: ${spacing.Small};
`

export const HeaderContent = styled.div`
  display: flex;
`

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: ${spacing.ExtraSmall};
`

export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  margin-left: auto;
`
