import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { endpoint } from 'infrastructure/api/endpoints'
import { IApiError } from 'infrastructure/types/api'
import { toast } from 'react-toastify'

export const loadTrainingRun = createAsyncThunk(
  'loadTrainingRun',
  async (trainingId: string, { rejectWithValue }) => {
    try {
      return await endpoint.getTraining(trainingId)
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })

      return rejectWithValue(castError.response?.data)
    }
  },
)
