import styled from 'styled-components'
import { colours } from 'styles/colours'
import { spacing } from 'styles/spacing'
import { RemSize } from 'styles/utility'

export const SearchBar = styled.div`
  border: 1px solid ${colours.disabled};
  background-color: ${colours.backdrop};
  width: ${RemSize(400)};
  margin-bottom: ${spacing.Small};
  display: flex;
  align-items: center;
  padding: ${spacing.Small};
  gap: ${spacing.Small};

  input {
    background-color: transparent;
    border: none;
    width: 100%;
  }

  input::placeholder {
    color: ${colours.disabled};
  }
`
