/* eslint-disable no-console */
import {
  Box,
  PivotControls,
  Resize,
  TransformControls,
} from '@react-three/drei'
import { Box3, Vector3, MeshBasicMaterial } from 'three'
import BoundingBox from './BoundingBox'
import { FC, useEffect, useRef, useState } from 'react'
import { Bounds3 } from 'views/ground-truths/models'
import { Transform } from 'stream'
import { Object3DProps, useFrame } from '@react-three/fiber'

const EditableBoundingBox: FC<{
  bounds: Bounds3
  selected: boolean
  onSelected: () => void
  onPositionChanged: (delta: { x: number; y: number; z: number }) => void
  onScaleChanged: (newpos: Bounds3) => void
}> = ({ bounds, selected, onSelected, onPositionChanged, onScaleChanged }) => {
  const transformRef: any = useRef()
  const transformRef2: any = useRef()
  const boxRef: any = useRef()

  const [focusedEditMode, setFocusedEditMode] = useState(false)

  const width = bounds.x2 - bounds.x1
  const height = bounds.y2 - bounds.y1
  const depth = bounds.z2 - bounds.z1
  const centroid = {
    x: bounds.x1 + width / 2,
    y: bounds.y1 + height / 2,
    z: bounds.z1 + depth / 2,
  }

  useEffect(() => {
    const tr: any = transformRef.current as Object3DProps
    if (tr != null) {
      tr.addEventListener('dragging-changed', () => {
        const box: any = boxRef.current as Object3DProps
        if (box == null || box.position == null) return
        const position: { x: number; y: number; z: number } = box.position
        console.log('mouse up, position changed:', position)
        // if(position.x !== 0 || position.y !== 0 || position.z !== 0)
        // onPositionChanged(position)
        if (
          position.x !== centroid.x ||
          position.y !== centroid.y ||
          position.z !== centroid.z
        )
          onPositionChanged({
            x: position.x - bounds.x1 - width / 2,
            y: position.y - bounds.y1 - height / 2,
            z: position.z - bounds.z1 - depth / 2,
          })
        else console.log('no change')
      })

      return () => {
        tr.removeEventListener('dragging-changed', () =>
          console.log('mouse up'),
        )
      }
    }
    const tr2: any = transformRef2.current as Object3DProps
    if (tr2 != null) {
      tr2.addEventListener('dragging-changed', () => {
        const box: any = boxRef.current as Object3DProps
        if (box == null || box.scale == null) {
          console.log('box ref is not available')
          return
        }
        const scale: { x: number; y: number; z: number } = box.scale
        console.log('mouse up, scale changed:', scale, width, height, depth)
        // if(position.x !== 0 || position.y !== 0 || position.z !== 0)
        // onPositionChanged(position)
        if (scale.x !== width || scale.y !== height || scale.z !== depth)
          onScaleChanged({
            x1: centroid.x - scale.x / 2 - bounds.x1,
            x2: centroid.x + scale.x / 2 - bounds.x2,
            y1: centroid.y - scale.y / 2 - bounds.y1,
            y2: centroid.y + scale.y / 2 - bounds.y2,
            z1: centroid.z - scale.z / 2 - bounds.z1,
            z2: centroid.z + scale.z / 2 - bounds.z2,
          })
        else console.log('no change')
      })

      return () => {
        tr2.removeEventListener('dragging-changed', () =>
          console.log('mouse up'),
        )
      }
    }
  }, [transformRef.current, transformRef2.current, boxRef.current, selected])

  return (
    <>
      <Box
        // key={bounds.x1 + bounds.x2 + bounds.y1 + bounds.y2 + bounds.z1 + bounds.z2}
        key={`${bounds.x1},${bounds.x2},${bounds.y1}${bounds.y2}${bounds.z1}${bounds.z2}`}
        ref={boxRef}
        position={new Vector3(centroid.x, centroid.y, centroid.z)}
        scale={
          new Vector3(
            bounds.x2 - bounds.x1,
            bounds.y2 - bounds.y1,
            bounds.z2 - bounds.z1,
          )
        }
        onClick={(e) => {
          if (!selected) onSelected()
        }}
        onContextMenu={(e) => setFocusedEditMode(!focusedEditMode)}
        onDoubleClick={() => {
          setFocusedEditMode(!focusedEditMode)
        }}
        material={
          new MeshBasicMaterial({
            color: selected ? 0x00ff00 : 'lightGrey',
            transparent: true,
            opacity: selected ? 0.5 : 0.2,
          })
        }
      >
        {/* <BoundingBox boundingBox={bounds} selected={false} /> */}
      </Box>
      {selected ? (
        focusedEditMode === false ? (
          <TransformControls
            ref={transformRef}
            mode="translate"
            object={boxRef}
          />
        ) : (
          <TransformControls ref={transformRef2} mode="scale" object={boxRef} />
        )
      ) : (
        <></>
      )}
      {/* <PivotControls
    ref={transformRef}
    object={boxRef}
    /> */}{' '}
      <BoundingBox boundingBox={bounds} selected={false} />
    </>
  )
}

export default EditableBoundingBox
