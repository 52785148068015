import { TrashIcon, CheckIcon } from '@heroicons/react/outline'
import { Toggle } from 'components/toggle/toggle'
import { useAppDispatch, useAppSelector } from 'infrastructure/hooks'
import { IImage } from 'infrastructure/types/image'
import { ILabel } from 'infrastructure/types/label'
import { GetColorFromString } from 'infrastructure/utils/color'
import { ReactElement } from 'react'
import { colours } from 'styles/colours'
import { Title } from 'styles/textography'
import { saveImage } from 'views/image/imageActions'
import { actions } from 'views/image/imageReducer'
import { FlexBoxColumn, Label, Panel, RowActions, RowSpaced } from './styles'

export interface ILabelsProps {
  image: IImage | null
  selectedLabel: ILabel | null
}

export const Labels = (props: ILabelsProps): ReactElement => {
  const dispatch = useAppDispatch()
  const { image, selectedLabel } = props
  const unverifiedLabels = image?.labels.filter((x) => !x.verified)
  const verifiedLabels = image?.labels.filter((x) => x.verified)
  const labels = useAppSelector((state) => state.labels.labels)
  const labelsMapped = new Map<string, string>(
    labels.map((label) => {
      return [label.labelId, label.name]
    }),
  )

  const getVerifiedLabelCount = (labelId: string): number => {
    if (verifiedLabels) {
      return verifiedLabels.filter((x) => x.labelId === labelId).length
    }

    return 0
  }

  const toggleActive = () => {
    if (image) {
      dispatch(actions.setActive(!image.active))
      dispatch(saveImage())
    }
  }

  return (
    <FlexBoxColumn>
      {image && (
        <>
          <Toggle active={image.active} onToggle={toggleActive} />

          {image.active && (
            <>
              <Panel className="spaced">
                <Title>Verified</Title>
                {labels.map((value, index) => (
                  <RowSpaced
                    key={index}
                    className="clickable"
                    onClick={() => dispatch(actions.selectLabel(value))}
                  >
                    <Label
                      className={
                        value.labelId === selectedLabel?.labelId ? 'active' : ''
                      }
                    >
                      <span
                        style={{
                          backgroundColor: GetColorFromString(value.name),
                        }}
                      ></span>
                      <div>{value.name}</div>
                    </Label>
                    <div>{getVerifiedLabelCount(value.labelId)}</div>
                  </RowSpaced>
                ))}
              </Panel>

              {unverifiedLabels && Boolean(unverifiedLabels.length) && (
                <Panel>
                  <Title>Verification Required</Title>
                  {unverifiedLabels.map((label, index) => (
                    <RowSpaced key={index}>
                      <Label>
                        <span
                          style={{
                            backgroundColor: GetColorFromString(
                              labelsMapped.get(label.labelId) ?? '',
                            ),
                          }}
                        ></span>
                        <div>{labelsMapped.get(label.labelId)}</div>
                      </Label>
                      <RowActions>
                        <CheckIcon
                          className="clickable"
                          width={18}
                          height={18}
                          color={colours.primary}
                          onClick={() =>
                            dispatch(actions.verifyImageLabel(label.id))
                          }
                        />
                        <TrashIcon
                          className="clickable"
                          width={18}
                          height={18}
                          color={colours.primary}
                          onClick={() =>
                            dispatch(actions.verifyImageLabel(label.id))
                          }
                        />
                      </RowActions>
                    </RowSpaced>
                  ))}
                </Panel>
              )}
            </>
          )}
        </>
      )}
    </FlexBoxColumn>
  )
}
