import { Button } from 'components/button/styles'
import { useAppDispatch, useAppSelector } from 'infrastructure/hooks'
import { ReactElement, useState } from 'react'
import { createAndAddTag, importDicos } from '../directoryActions'
import { Panel, Paths, SearchSelectCreate } from './styles'
import { actions } from '../directoryImportReducer'
import { ActionMeta } from '@atlaskit/select'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import { colours } from 'styles/colours'
import { renderOptions } from 'infrastructure/types/rendering'

export const ImportDicosPanel = (): ReactElement => {
  const dispatch = useAppDispatch()
  const [showPanel, setShowPanel] = useState(false)
  const tags = useAppSelector((state) => state.tags.tags)
  const selectedTagIds = useAppSelector((state) => state.directoryImport.tagIds)
  const selectedRenders = useAppSelector(
    (state) => state.directoryImport.renderParameterNames,
  )

  const options = tags.map((x) => ({
    label: x.name,
    value: x.tagId,
  }))

  const selectedOptions = options.filter((x) =>
    selectedTagIds.includes(x.value),
  )

  const selectedRenderOptions = renderOptions.filter((x) =>
    selectedRenders.includes(x.value),
  )

  return (
    <Panel active={showPanel}>
      <div className="toggle" onClick={() => setShowPanel(!showPanel)}>
        Import DICOS
        {!showPanel && (
          <ChevronDownIcon width={18} height={18} color={colours.disabled} />
        )}
        {showPanel && (
          <ChevronUpIcon width={18} height={18} color={colours.disabled} />
        )}
      </div>

      {showPanel && (
        <div>
          <Paths>
            <input
              type="text"
              placeholder="Source Bucket"
              onChange={(e) =>
                dispatch(actions.setSourceBucket(e.target.value))
              }
            />
            <input
              type="text"
              placeholder="Source Folder"
              onChange={(e) =>
                dispatch(actions.setSourceFolder(e.target.value))
              }
            />
          </Paths>

          <SearchSelectCreate
            options={options}
            isMulti
            isSearchable
            isClearable={false}
            className="multi-select"
            placeholder="Choose a tag"
            onCreateOption={(tagName: string) =>
              dispatch(createAndAddTag(tagName))
            }
            onChange={(a: any, actionMeta: ActionMeta & { option: any }) => {
              switch (actionMeta.action) {
                case 'select-option':
                  if (actionMeta.option?.value) {
                    dispatch(actions.addTagId(actionMeta.option.value))
                  }
                  break
                case 'remove-value':
                  if (actionMeta.removedValue?.value) {
                    dispatch(
                      actions.removeTagId(
                        actionMeta.removedValue.value as string,
                      ),
                    )
                  }
                  break
              }
            }}
            value={selectedOptions}
          />

          <SearchSelectCreate
            options={renderOptions}
            isMulti
            isSearchable
            isClearable={false}
            className="multi-select"
            placeholder="Choose a render"
            onChange={(a: any, actionMeta: ActionMeta & { option: any }) => {
              switch (actionMeta.action) {
                case 'select-option':
                  if (actionMeta.option?.value) {
                    dispatch(actions.addRender(actionMeta.option.value))
                  }
                  break
                case 'remove-value':
                  if (actionMeta.removedValue?.value) {
                    dispatch(
                      actions.removeRender(
                        actionMeta.removedValue.value as string,
                      ),
                    )
                  }
                  break
              }
            }}
            value={selectedRenderOptions}
          />

          <Paths>
            <textarea
              placeholder="DICOS files (csv)"
              onChange={(e) =>
                dispatch(actions.setDicosFileNameCsv(e.target.value))
              }
            />
          </Paths>

          <Button onClick={() => dispatch(importDicos())}>Import</Button>
        </div>
      )}
    </Panel>
  )
}
