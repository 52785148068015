import { SpinnerBox } from './styles'

interface ISpinnerProps {
  size: string
}

export const Spinner = (props: ISpinnerProps) => {
  var { size } = props

  return (
    <SpinnerBox size={size}>
      <div className="ripple">
        <div></div>
        <div></div>
      </div>
    </SpinnerBox>
  )
}
