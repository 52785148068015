import { ITab, MultiTabs } from 'components/tabs/tabs'
import { IImage } from 'infrastructure/types/image'
import { ILabel } from 'infrastructure/types/label'
import { ReactElement } from 'react'
import { Labels } from './components/labels'
import { Metadata } from './components/metadata'
import { SidePanelBox } from './styles'

export interface ISidePanelProps {
  image: IImage | null
  selectedLabel: ILabel | null
}

export const SidePanel = (props: ISidePanelProps): ReactElement => {
  const { image, selectedLabel } = props
  const tabs: ITab[] = [
    {
      label: 'Labels',
      component: <Labels image={image} selectedLabel={selectedLabel} />,
    },
    { label: 'Meta Data', component: <Metadata image={image} /> },
  ]
  return (
    <SidePanelBox>
      <MultiTabs tabs={tabs} />
    </SidePanelBox>
  )
}
