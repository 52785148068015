import styled from 'styled-components'
import { colours } from 'styles/colours'
import { spacing } from 'styles/spacing'

export const FlexBoxColumn = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${spacing.Small};
`

export const RowSpaced = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

export const RowActions = styled.div`
  display: flex;
  gap: ${spacing.Medium};
`

export const Panel = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacing.Medium};
  flex: 1;

  &.spaced {
    margin-bottom: ${spacing.Small};
  }

  .select-search {
    width: 100%;
  }
`

export const Label = styled.div`
  display: flex;
  gap: ${spacing.Small};
  align-items: center;
  text-transform: capitalize;
  flex: 1;
  margin-right: 10px;

  span {
    width: 1.5rem;
    height: 1.5rem;
  }

  &.active {
    background-color: ${colours.primary};
    color: white;
  }
`
