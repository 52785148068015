import { Roles } from './auth'

export const paths = {
  Landing: '/',
  Gallery: '/gallery',
  Image: '/gallery/:id',
  Labels: '/labels',
  Tags: '/tags',
  Training: '/training',
  TrainingRun: '/training/:id',
  Directory: '/directory',
  GroundTruthBuckets: '/ground-truths',
  GroundTruthList: '/ground-truths/:bucketName',
  GroundTruthItem: '/ground-truths/:bucketName/:id',
  GroundTruthRenderedImages: '/ground-truths/:bucketName/:id/rendered-images',
  GroundTruthIndex: '/ground-truths/dcs-idx',
  GroundTruthIndexBuild: '/ground-truths/:bucketName/dcs-idx',
  Render: '/render/:id',
}

interface IRoute {
  path: string
  allowableRoles: string[]
}

interface IRoutes {
  Landing: IRoute
  Gallery: IRoute
  Image: IRoute
  Labels: IRoute
  Tags: IRoute
  Training: IRoute
  TrainingRun: IRoute
  GroundTruthBuckets: IRoute
  GroundTruthList: IRoute
  GroundTruthItem: IRoute
  GroundTruthIndex: IRoute
  GroundTruthIndexBuild: IRoute
  GroundTruthRenderedImages: IRoute
  Directory: IRoute
  Render: IRoute
}

export const HasRequiredRole = (userRoles: string[], route: IRoute) =>
  route.allowableRoles.length === 0 ||
  userRoles.some((x) =>
    route.allowableRoles.map((x) => x.toLowerCase()).includes(x.toLowerCase()),
  )

export const HasSpecificRequiredRole = (
  userRoles: string[],
  requiredRole: string,
) => userRoles.some((x) => x.toLowerCase() === requiredRole.toLowerCase())

export const routes: IRoutes = {
  Landing: {
    path: paths.Landing,
    allowableRoles: [
      Roles.Admin,
      Roles.Labeller,
      Roles.LabellerAdmin,
      Roles.ModelTrainer,
      Roles.ReportViewer,
    ],
  },
  Gallery: {
    path: paths.Gallery,
    allowableRoles: [Roles.Admin, Roles.Labeller, Roles.LabellerAdmin],
  },
  Image: {
    path: paths.Image,
    allowableRoles: [Roles.Admin, Roles.Labeller, Roles.LabellerAdmin],
  },
  Labels: {
    path: paths.Labels,
    allowableRoles: [Roles.Admin, Roles.LabellerAdmin],
  },
  Tags: {
    path: paths.Tags,
    allowableRoles: [Roles.Admin, Roles.LabellerAdmin],
  },
  Training: {
    path: paths.Training,
    allowableRoles: [Roles.Admin, Roles.ModelTrainer],
  },
  TrainingRun: {
    path: paths.TrainingRun,
    allowableRoles: [Roles.Admin, Roles.ModelTrainer],
  },
  Directory: {
    path: paths.Directory,
    allowableRoles: [Roles.Admin],
  },
  GroundTruthBuckets: {
    path: paths.GroundTruthBuckets,
    allowableRoles: [Roles.Admin, Roles.LabellerAdmin],
  },
  GroundTruthList: {
    path: paths.GroundTruthList,
    allowableRoles: [Roles.Admin, Roles.Labeller, Roles.LabellerAdmin],
  },
  GroundTruthItem: {
    path: paths.GroundTruthItem,
    allowableRoles: [Roles.Admin, Roles.Labeller, Roles.LabellerAdmin],
  },
  GroundTruthIndex: {
    path: paths.GroundTruthIndex,
    allowableRoles: [Roles.Admin, Roles.Labeller],
  },
  GroundTruthIndexBuild: {
    path: paths.GroundTruthIndexBuild,
    allowableRoles: [Roles.Admin, Roles.LabellerAdmin],
  },
  GroundTruthRenderedImages: {
    path: paths.GroundTruthRenderedImages,
    allowableRoles: [Roles.Admin, Roles.Labeller, Roles.LabellerAdmin],
  },
  Render: {
    path: paths.Render,
    allowableRoles: [Roles.Admin, Roles.Labeller, Roles.LabellerAdmin],
  },
}
