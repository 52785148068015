/* eslint-disable no-console */
import * as React from 'react'
import { FC, useState, Suspense, useEffect } from 'react'
import * as THREE from 'three'
import { Canvas, useThree } from '@react-three/fiber'
import CameraController from './OrbitControls'
import ScanVolumeMesh from './ScanVolumeMesh'
import BoundingBox from './BoundingBox'
import { getCenteredLineCoords } from './BoundingBox'
import { IDataPointModel } from 'infrastructure/types/rendering'
import { Bounds3, ScanInference3D } from 'views/ground-truths/models'
import EditableBoundingBox from './EditableBoundingBox'
import { GizmoHelper, GizmoViewport } from '@react-three/drei'
import { ModelViewerOptions } from 'views/ground-truths/groundTruthReducer'

interface UseWindowDimensions {
  width: number
  height: number
}

const useWindowDimensions = (): UseWindowDimensions => {
  // Initial state
  const [windowDimensions, setWindowDimensions] = useState<UseWindowDimensions>(
    {
      width: typeof window !== 'undefined' ? window.innerWidth : 0,
      height: typeof window !== 'undefined' ? window.innerHeight : 0,
    },
  )

  useEffect(() => {
    // Only run this effect client side, where window is defined
    if (typeof window !== 'undefined') {
      const handleResize = () => {
        setWindowDimensions({
          width: window.innerWidth,
          height: window.innerHeight,
        })
      }

      window.addEventListener('resize', handleResize)

      // Clean up event listener on component unmount
      return () => window.removeEventListener('resize', handleResize)
    }
  }, []) // Empty array ensures effect only runs once on mount and unmount

  console.log('window stuff')
  console.log(windowDimensions)

  return windowDimensions
}

interface ModelViewerProps {
  scanId: string
  model: IDataPointModel
  displayOptions: ModelViewerOptions
  conclusions: ScanInference3D[]
  scaleFactor: number
  selectedConclusion: number
  onBoundsChanged?: (idx: number, bounds: Bounds3) => void
  onSelected?: (idx: number) => void
}

// Main model viewer. Displays stuff in main window
function ModelViewer({
  scanId,
  model,
  displayOptions,
  conclusions,
  scaleFactor,
  selectedConclusion = 0,
  onBoundsChanged,
  onSelected,
}: ModelViewerProps) {
  const h = 32 * 4 //512 * 4; // frustum height
  //   const { width, height } = useWindowDimensions()
  const width = 400
  const height = 300
  const aspect = width / height

  function getUpdatedDisplayOptions(
    options: ModelViewerOptions,
  ): ModelViewerOptions {
    // theshold should stay constant,
    // but make small changes to force a rerender
    // and make highlighted threats appear on top
    switch (options.renderStyle) {
      case 'iso':
        return {
          ...options,
          threshold: options.highlightThreats
            ? 20 + options.threshold * 0.000001
            : options.threshold,
        }
      default:
        return options
    }
  }

  function getScanMeshes(
    scanId: string,
    model: IDataPointModel,
    options: ModelViewerOptions,
    conclusions: ScanInference3D[],
    displayThreat: number,
  ) {
    const mainMesh =
      options.isolateThreats == true && conclusions.length > 0
        ? []
        : [
            <ScanVolumeMesh
              key={scanId}
              model={model}
              displayOptions={{ ...options, highlightThreats: false }}
              classNameModel={'ModelViewer'}
              boundingBox={null}
              toOriginMatrix={null}
              rotationMatrix={null}
              fromOriginMatrix={null}
            />,
          ]

    const highlightedThreatDisplayOptions = getUpdatedDisplayOptions(options)

    return conclusions.reduce((acc, conclusion, index) => {
      const selected = index === displayThreat
      if (conclusion.bounds == null) return acc

      const boxWorldCoords: Bounds3 = {
        x1: (conclusion.bounds.x1 * model.metadata.pixelWidth) / scaleFactor,
        y1: (conclusion.bounds.y1 * model.metadata.pixelHeight) / scaleFactor,
        z1: (conclusion.bounds.z1 * model.metadata.pixelDepth) / scaleFactor,
        x2: (conclusion.bounds.x2 * model.metadata.pixelWidth) / scaleFactor,
        y2: (conclusion.bounds.y2 * model.metadata.pixelHeight) / scaleFactor,
        z2: (conclusion.bounds.z2 * model.metadata.pixelDepth) / scaleFactor,
      }

      const boundingBoxDta: Bounds3 = {
        // Take the co-ordinates of the bounding box, which are always in the original scan co-ordinates
        // The model which we're rendering is potentially scaled so we need to calculate the offset
        // to center the bounding box by scaling up the expected model dimensions to their real size
        // we can then then subtract this to get the actual box position
        x1:
          boxWorldCoords.x1 -
          (model.metadata.width * model.metadata.pixelWidth) / 2,
        y1:
          boxWorldCoords.y1 -
          (model.metadata.height * model.metadata.pixelHeight) / 2,
        z1:
          boxWorldCoords.z1 -
          (model.slices.count * model.metadata.pixelDepth) / 2,
        x2:
          boxWorldCoords.x2 -
          (model.metadata.width * model.metadata.pixelWidth) / 2,
        y2:
          boxWorldCoords.y2 -
          (model.metadata.height * model.metadata.pixelHeight) / 2,
        z2:
          boxWorldCoords.z2 -
          (model.slices.count * model.metadata.pixelDepth) / 2,
      }

      const boundingBox =
        options.showBoundingBox == false ? (
          <></>
        ) : (
          // : <BoundingBox key={`${index}${selected ? "-selected" : ""}`}
          //     boundingBox={boundingBoxDta}
          //     selected={selected} />
          <EditableBoundingBox
            key={`${boundingBoxDta.x1},${boundingBoxDta.x2},${boundingBoxDta.y1}${boundingBoxDta.y2}${boundingBoxDta.z1}${boundingBoxDta.z2}`}
            bounds={boundingBoxDta}
            selected={selected}
            onSelected={() => (onSelected != null ? onSelected(index) : null)}
            onPositionChanged={(delta) => {
              const boundsInConclusionSpace = {
                x: delta.x,
                y: delta.y,
                z: delta.z,
              }
              if (onBoundsChanged == null) return
              onBoundsChanged(index, {
                x1: conclusion.bounds.x1 + boundsInConclusionSpace.x,
                x2: conclusion.bounds.x2 + boundsInConclusionSpace.x,
                y1: conclusion.bounds.y1 + boundsInConclusionSpace.y,
                y2: conclusion.bounds.y2 + boundsInConclusionSpace.y,
                z1: conclusion.bounds.z1 + boundsInConclusionSpace.z,
                z2: conclusion.bounds.z2 + boundsInConclusionSpace.z,
              })
            }}
            onScaleChanged={(delta) => {
              if (onBoundsChanged == null) return
              onBoundsChanged(index, {
                x1: conclusion.bounds.x1 + delta.x1,
                x2: conclusion.bounds.x2 + delta.x2,
                y1: conclusion.bounds.y1 + delta.y1,
                y2: conclusion.bounds.y2 + delta.y2,
                z1: conclusion.bounds.z1 + delta.z1,
                z2: conclusion.bounds.z2 + delta.z2,
              })
            }}
          />
        )

      const isolatedMesh =
        options.isolateThreats == false ? (
          <></>
        ) : (
          <ScanVolumeMesh
            key={`${scanId}_isolated_threat_${index}`}
            model={model}
            displayOptions={displayOptions}
            boundingBox={boxWorldCoords}
            classNameModel={'ModelViewer'}
            toOriginMatrix={null}
            rotationMatrix={null}
            fromOriginMatrix={null}
          />
        )

      const highlightedThreatMesh =
        options.highlightThreats == false ? (
          <></>
        ) : (
          <ScanVolumeMesh
            key={`${scanId}_highlighted_threat_${index}`}
            model={model}
            displayOptions={highlightedThreatDisplayOptions}
            boundingBox={boxWorldCoords}
            classNameModel={'ModelViewer'}
            toOriginMatrix={null}
            rotationMatrix={null}
            fromOriginMatrix={null}
          />
        )

      return [...acc, boundingBox, isolatedMesh, highlightedThreatMesh]
    }, mainMesh)
  }

  const scans = getScanMeshes(
    scanId,
    model,
    displayOptions,
    conclusions,
    selectedConclusion,
  )

  return (
    <div
      style={{
        // top: 0,
        // right: 0,
        height: '100vh-150px',
        // width: width,
      }}
    >
      <Canvas
        key={'canvas'}
        orthographic
        frameloop="demand"
        camera={{
          zoom: 1.3,
          top: h / 2,
          bottom: -h / 2,
          left: (-h * aspect) / 2,
          right: (h * aspect) / 2,
          near: 0.01,
          far: 3000,
          position: [480, -240, -10],
          up: [0, -1, 0],
        }}
      >
        <Suspense
          key={'suspense'}
          fallback={<p key={'suspense-loading'}>Loading</p>}
        >
          {scans}
          {/* <EditableBoundingBox 
              bounds={{x1: 0, x2: 300, y1: 0, y2: 300, z1: 0, z2: 300}} 
              onBoundsChanged={x => onBoundsChanged(i, x)} /> */}
        </Suspense>
        <CameraController
          key={'camera'}
          autoRotate={displayOptions.autoRotate}
        />
        <GizmoHelper alignment="bottom-right" margin={[100, 100]}>
          <GizmoViewport labelColor="white" axisHeadScale={1} />
        </GizmoHelper>
      </Canvas>
    </div>
  )
}

interface CameraUpdaterProps {
  boundingBoxCentre: THREE.Vector3
}

/**
 * Camera updater to change the camera position for isolated threats
 * @param boundingBoxCenter
 */
const CameraUpdater: FC<CameraUpdaterProps> = ({ boundingBoxCentre }) => {
  const { camera } = useThree()

  useEffect(() => {
    if (boundingBoxCentre) {
      const distance = 100
      camera.position.set(
        boundingBoxCentre.x + distance,
        boundingBoxCentre.y + distance,
        boundingBoxCentre.z + distance,
      )

      // Make the camera look at the center
      camera.lookAt(
        boundingBoxCentre.x,
        boundingBoxCentre.y,
        boundingBoxCentre.z,
      )
    }
  }, [boundingBoxCentre, camera])

  return null
}

export default ModelViewer
