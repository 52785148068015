import { ReactElement, useState } from 'react'
import { Label, Labels, LabelsBox, LabelsFilter } from './styles'
import { Search } from 'components/searchBar/searchBar'
import { useAppDispatch, useAppSelector } from 'infrastructure/hooks'
import { addLabel, deleteLabel } from './labelsActions'
import { CircularProgressbar } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'
import { Button } from 'components/button/styles'
import { Spinner } from 'components/spinner/spinner'

export const LabelsView = (): ReactElement => {
  const dispatch = useAppDispatch()
  const [searchFilter, setSearchFilter] = useState('')
  const [selectedLabel, setSelectedLabel] = useState('')
  const [newLabel, setNewLabel] = useState('')
  const { fetchState, upsertState } = useAppSelector((state) => state.labels)
  const labels = useAppSelector((state) => {
    return state.labels.labels.filter(
      (x) =>
        !searchFilter ||
        x.name.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1,
    )
  })

  const addNewLabel = () => {
    if (newLabel) {
      dispatch(addLabel(newLabel))
      setNewLabel('')
    }
  }

  return (
    <LabelsBox>
      <LabelsFilter>
        <Search placeholder="Search by name" onUpdate={setSearchFilter} />
      </LabelsFilter>
      {fetchState.loading ? (
        <Spinner size="80px" />
      ) : (
        <Labels>
          {labels.map((label, index) => (
            <Label
              key={index}
              colour={label.colour}
              expand={selectedLabel === label.labelId}
            >
              <div
                className="header"
                onClick={() =>
                  setSelectedLabel(
                    selectedLabel === label.labelId ? '' : label.labelId,
                  )
                }
              >
                <div className="info">
                  <div>{label.name}</div>
                  <div>325</div>
                </div>
                <div className="progress">
                  <CircularProgressbar value={70} text={`${35}%`} />
                </div>
              </div>
              <div className="body">
                <div className="variants">
                  <div className="variant">
                    <div className="name">Sig Sauer P938 SAS</div>
                    <progress value="320" max="1000" />
                  </div>
                  <div className="variant">
                    <div className="name">
                      Smith &amp; Wesson .38 Springfield
                    </div>
                    <progress value="450" max="1000" />
                  </div>
                  <div className="variant">
                    <div className="name">Ruger Blackhawk</div>
                    <progress value="250" max="1000" />
                  </div>
                  <div className="variant">
                    <div className="name">Glock 17</div>
                    <progress value="300" max="1000" />
                  </div>
                </div>
                <Button onClick={() => dispatch(deleteLabel(label.labelId))}>
                  Delete
                </Button>
              </div>
            </Label>
          ))}
          <Label key="add" colour="black" className="add-label">
            {upsertState.loading ? (
              <Spinner size="50px" />
            ) : (
              <>
                <input
                  type="text"
                  placeholder="new label"
                  value={newLabel}
                  onChange={(e) => setNewLabel(e.target.value)}
                />
                <Button onClick={addNewLabel}>+</Button>
              </>
            )}
          </Label>
        </Labels>
      )}
    </LabelsBox>
  )
}
