import styled from 'styled-components'
import { colours } from 'styles/colours'
import { spacing } from 'styles/spacing'
import { RemSize } from 'styles/utility'

export const Button = styled.button<{ width?: string; disabled?: boolean }>`
  background-color: ${(props) =>
    props.disabled ? colours.disabled : colours.contrastLight}};
  color: ${colours.primary};
  padding: ${spacing.Small};
  min-width: ${RemSize(250)};
  width: ${(props) => props.width ?? 'auto'};
  border: 1px solid ${(props) =>
    props.disabled ? colours.disabled : colours.primary}};
  cursor: pointer;
  margin-bottom: ${spacing.Small};
  pointer-events:${(props) => (props.disabled ? 'none' : null)};
`
export const ButtonLink = styled.a<{ width?: string; disabled?: boolean }>`
  background-color: ${(props) =>
    props.disabled ? colours.disabled : colours.contrastLight}};
  color: ${colours.primary};
  padding: ${spacing.Small};
  min-width: ${RemSize(250)};
  width: ${(props) => props.width ?? 'auto'};
  border: 1px solid ${(props) =>
    props.disabled ? colours.disabled : colours.primary}};
  cursor: pointer;
  margin-bottom: ${spacing.Small};
  pointer-events:${(props) => (props.disabled ? 'none' : null)};
  display: inline-block;
`

export const PrimaryButton = styled(Button)`
  color: ${colours.contrastLight};
  background-color: ${(props) =>
    props.disabled ? colours.disabled : colours.primary}};
`

export const PrimaryButtonLink = styled(ButtonLink)`
  color: ${colours.contrastLight};
  background-color: ${(props) =>
    props.disabled ? colours.disabled : colours.primary}};
`

export const PrimaryButtonExtended = styled(PrimaryButton)`
  display: flex;
  align-items: center;
  gap: ${spacing.Small};
  min-width: ${RemSize(150)};
`

export const PagingButton = styled(Button)`
  margin-top: ${spacing.Medium};
  width: 100%;
`
