import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApiState } from 'infrastructure/types/api'
import { IDicos, MapDicosResponsesToDicos } from 'infrastructure/types/dicos'
import { toast } from 'react-toastify'
import { loadDicos } from './directoryActions'

export interface IDirectoryState {
  fetchState: IApiState
  pageNumber: number
  pageSize: number
  dicos: IDicos[]
  searchText: string | null
}

const initialState: IDirectoryState = {
  fetchState: {
    loading: true,
    error: false,
  },
  pageNumber: 0,
  pageSize: 20,
  dicos: [],
  searchText: null,
}

const slice = createSlice({
  name: 'directory',
  initialState,
  reducers: {
    initialise: () => {
      return initialState
    },

    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload
    },
    setSearchText: (state, action: PayloadAction<string>) => {
      state.pageNumber = initialState.pageNumber
      state.pageSize = initialState.pageSize
      state.dicos = initialState.dicos
      state.searchText = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadDicos.pending, (state) => {
      state.fetchState.loading = true
      state.fetchState.error = false
    })

    builder.addCase(loadDicos.fulfilled, (state, action) => {
      state.fetchState.loading = false
      state.fetchState.error = false
      state.dicos = [
        ...state.dicos,
        ...MapDicosResponsesToDicos(action.payload),
      ]
    })

    builder.addCase(loadDicos.rejected, (state) => {
      state.fetchState.loading = false
      state.fetchState.error = true
    })
  },
})

export const { actions, reducer } = slice
