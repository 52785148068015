import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { endpoint } from 'infrastructure/api/endpoints'
import { ITrainingResponse } from 'infrastructure/api/response/training'
import { RootState } from 'infrastructure/store'
import { IApiError } from 'infrastructure/types/api'
import { toast } from 'react-toastify'

export const loadTrainings = createAsyncThunk<
  ITrainingResponse[],
  void,
  { state: RootState }
>('loadTrainings', async (_, { rejectWithValue, getState }) => {
  try {
    const state = getState().training
    return await endpoint.getTrainings(state.pageNumber, state.pageSize)
  } catch (error) {
    var castError = error as AxiosError<IApiError>

    toast.error(castError.response?.data.message, {
      pauseOnHover: true,
    })

    return rejectWithValue(castError.response?.data)
  }
})
