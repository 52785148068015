export const Roles = {
  Admin: 'Admin',
  Labeller: 'Labeller',
  LabellerAdmin: 'LabellerAdmin',
  ModelTrainer: 'ModelTrainer',
  ReportViewer: 'ReportViewer',
}

export const OidcConfiguration = {
  client_id: process.env.REACT_APP_CLIENT_ID ?? '',
  redirect_uri: window.location.origin + '/authentication/callback',
  silent_redirect_uri:
    window.location.origin + '/authentication/silent-callback', // Optional activate silent-signin that use cookies between OIDC server and client javascript to restore the session
  scope: 'profile email openid roles',
  authority: process.env.REACT_APP_OAUTH_ENDPOINT ?? '',
  refresh_time_before_tokens_expiration_in_second: 40,
  service_worker_relative_url: '/OidcServiceWorker.js',
  service_worker_only: true,
}
