import axios from 'axios'

axios.defaults.headers.common = {
  'X-XSS-Protection': 1,
  'X-Frame-Options': 'SAMEORIGIN',
  'Content-Type': 'application/json',
  Accept: 'application/json',
  mode: 'cors',
  'Cache-Control': 'no-cache',
  Pragma: 'no-cache',
  Expires: '-1',
}

export const apiClient = axios
