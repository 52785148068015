import styled from 'styled-components'
import { colours } from './colours'
import { spacing } from './spacing'
import { RemSize } from './utility'

export const Heading = styled.h1`
  color: ${colours.contrastDark};
  font-size: ${RemSize(68)};
`

export const Body = styled.span`
  color: ${colours.contrastDark};
  font-size: ${RemSize(14)};
`

export const Title = styled.h3`
  width: 100%;
  font-weight: 500;
  font-size: ${RemSize(16)};
  padding: ${spacing.ExtraSmall} 0;
  border-bottom: 1px solid ${colours.disabled};
  margin-bottom: ${spacing.Small};
`

export const SubTitle = styled.h4`
  font-weight: 300;
  font-size: ${RemSize(14)};
  padding-bottom: ${spacing.ExtraSmall};

  &:after {
    content: ':';
  }
`
