import {
  IGroundTruthData,
  IGroundTruthItem,
} from 'infrastructure/types/groundTruth'

export type Bounds3 = {
  x1: number
  y1: number
  z1: number
  x2: number
  y2: number
  z2: number
}

export type ScanInference3D = {
  label: string
  bounds: Bounds3
}

export const fromGroundTruthItem = (gt: IGroundTruthItem): ScanInference3D => ({
  label: gt.label,
  bounds: {
    x1: gt.x1,
    y1: gt.y1,
    z1: gt.z1,
    x2: gt.x2,
    y2: gt.y2,
    z2: gt.z2,
  },
})

export const toGroundTruthItem = (gt: ScanInference3D): IGroundTruthItem => ({
  label: gt.label,
  x1: gt.bounds.x1,
  y1: gt.bounds.y1,
  z1: gt.bounds.z1,
  x2: gt.bounds.x2,
  y2: gt.bounds.y2,
  z2: gt.bounds.z2,
})

export type RenderGroup = {
  hasRenders: boolean
  renderParametersName: string
  viewCount: number
  approvalState: 'Pending' | 'Approved' | 'Rejected'
  gT3DChecksum: string
}

export type TrackedDicos = {
  bucket: string
  folder: string
  name: string
  expectedItemIds: string[]
  approvalState: 'Pending' | 'Approved' | 'Rejected'
  indexed?: {
    groundTruths: string[]
    renderGroups: RenderGroup[]
    gtChecksum: string
  }
}

export type RenderGroupFilters = {
  renderParametersName: string
  viewCount: number
  staleOnly: boolean
  hasRenders?: boolean
  approvalState?: 'Pending' | 'Approved' | 'Rejected'
}

export type TrackedDicosFilters = {
  omni: string
  buckets: string[]
  folders: string[]
  legacyCsvFiles: string[]
  expectedItemIds: string[]
  groundTruths: string[]
  approvalStates: ('Pending' | 'Approved' | 'Rejected')[]
  renderGroups: RenderGroupFilters[]
  noGroundTruths: boolean
  groundTruthNotExpectedItemIds: boolean
  skip: number
  take: number
}

export type TrackedFilterOptions = {
  buckets: string[]
  folders: string[]
  legacyCsvFiles: string[]
  expectedItemIds: string[]
  groundTruths: string[]
}

export type DicosBucketCsvIndexCommand = {
  bucket: string
  folder?: string
  name: string
  expectedItemIds: string[]
  sourceCsvFile: string
}
