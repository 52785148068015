import { ILabelRequest } from './request/label'
import { ITagRequest } from './request/tag'
import { IImageResponse } from './response/image'
import { ILabelResponse } from './response/label'
import { ITagResponse } from './response/tag'
import { urls } from './urls'
import { apiClient } from './apiClient'
import { AxiosResponse } from 'axios'
import { IImageRequest } from './request/image'
import { EnvelopeResponse } from './response/generics'
import { IDicosImportRequest } from './request/dicos'
import { IDicosImportResponse } from './response/import'
import { ITrainingResponse } from './response/training'
import { IDicosResponse } from './response/dicos'
import { IRenderingPropertiesResponse } from './response/rendering'
import {
  IDicosBucketCsvIndexCommand,
  IDicosImageMetaResponse,
  IDicosImageMetaUpdateRequest,
  IFilterOptionsResponse,
  IGroundTruthDataRequest,
  IGroundTruthDataResponse,
  IGroundTruthResponse,
  ITrackedDicosFilters,
  ITrackedDicosResponse,
} from './response/groundTruth'
import { DecodeDpm } from 'infrastructure/types/groundTruth'
import { IDataPointModel, Renderers } from 'infrastructure/types/rendering'

const updateImage = async (image: IImageRequest) =>
  await apiClient
    .put<IImageRequest, AxiosResponse<EnvelopeResponse<IImageResponse>>>(
      urls.image(image.imageId),
      image,
    )
    .then((x) => x.data.data)

const getImage = async (imageId: string) =>
  await apiClient
    .get<EnvelopeResponse<IImageResponse>>(urls.image(imageId))
    .then((x) => x.data.data)

const getImages = async (
  pageNumber: number,
  pageSize: number,
  searchText: string,
  labels: string[],
  tags: string[],
  unlabelledOnly: boolean,
) =>
  await apiClient
    .get<EnvelopeResponse<IImageResponse[]>>(
      urls.images(
        pageNumber,
        pageSize,
        searchText,
        labels,
        tags,
        unlabelledOnly,
      ),
    )
    .then((x) => x.data.data)

const searchDicosImages = async (
  pageNumber: number,
  pageSize: number,
  searchText: string,
) =>
  await apiClient
    .get<EnvelopeResponse<IDicosResponse[]>>(
      urls.searchDicos(pageNumber, pageSize, searchText),
    )
    .then((x) => x.data.data)

const getRenderingProperties = async () =>
  await apiClient
    .get<EnvelopeResponse<IRenderingPropertiesResponse[]>>(
      urls.renderingProperties(),
    )
    .then((x) => x.data.data)

const getLabels = async () =>
  await apiClient
    .get<EnvelopeResponse<ILabelResponse[]>>(urls.labels())
    .then((x) => x.data.data)

const getTags = async () =>
  await apiClient
    .get<EnvelopeResponse<ITagResponse[]>>(urls.tags())
    .then((x) => x.data.data)

const upsertLabel = async (request: ILabelRequest) =>
  await apiClient
    .put<ILabelRequest, AxiosResponse<EnvelopeResponse<ILabelResponse>>>(
      urls.labels(),
      request,
    )
    .then((x) => x.data.data)

const upsertTag = async (request: ITagRequest) =>
  await apiClient
    .put<ITagRequest, AxiosResponse<EnvelopeResponse<ITagResponse>>>(
      urls.tags(),
      request,
    )
    .then((x) => x.data.data)

const deleteLabel = async (labelId: string) =>
  await apiClient.delete(urls.label(labelId))

const deleteTag = async (tagId: string) =>
  await apiClient.delete(urls.tag(tagId))

const importDicos = async (request: IDicosImportRequest) =>
  await apiClient
    .post<IDicosImportRequest, AxiosResponse>(urls.importDicos(), request)
    .then((x) => x.data.data)

const getDicosImports = async (pageNumber: number, pageSize: number) =>
  await apiClient
    .get<EnvelopeResponse<IDicosImportResponse[]>>(
      urls.dicosImports(pageNumber, pageSize),
    )
    .then((x) => x.data.data)

const triggerTraining = async (
  searchText: string,
  labels: string[],
  tags: string[],
  unlabelledOnly: boolean,
) =>
  await apiClient
    .get<EnvelopeResponse<string>>(
      urls.train(searchText, labels, tags, unlabelledOnly),
    )
    .then((x) => x.data.data)

const getTrainings = async (pageNumber: number, pageSize: number) =>
  await apiClient
    .get<EnvelopeResponse<ITrainingResponse[]>>(
      urls.trainings(pageNumber, pageSize),
    )
    .then((x) => x.data.data)

const getTraining = async (id: string) =>
  await apiClient
    .get<EnvelopeResponse<ITrainingResponse>>(urls.training(id))
    .then((x) => x.data.data)

const getDicosVtiSignedUrl = (dicosId: string) =>
  apiClient
    .get<EnvelopeResponse<string>>(urls.getDicosVtiUri(dicosId))
    .then((x) => x.data.data)

const uploadTagsFile = async (formData: FormData) =>
  await apiClient
    .post<IDicosImportRequest, AxiosResponse>(urls.dicosTags(), formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
    .then((x) => x.data.data)

const getGroundTruths = async (
  bucketName: string,
  folderName: string | undefined,
  datasetFile: string | undefined,
) =>
  await apiClient
    .get<EnvelopeResponse<IGroundTruthResponse[]>>(
      urls.groundTruthsList(bucketName, folderName, datasetFile),
    )
    .then((x) => x.data.data)

const getGroundTruth = async (
  bucketName: string,
  folderName: string | undefined,
  id: string,
) =>
  await apiClient
    .get<EnvelopeResponse<IGroundTruthDataResponse>>(
      urls.groundTruth(bucketName, folderName, id),
    )
    .then((x) => x.data.data)

const groundTruthBeginEditing = async (
  bucketName: string,
  folderName: string | undefined,
  id: string,
) =>
  await apiClient
    .put<EnvelopeResponse<IGroundTruthDataResponse>>(
      urls.groundTruthBeginEditing(bucketName, folderName, id),
    )
    .then((x) => x.data.data)

const groundTruthEndEditing = async (
  bucketName: string,
  folderName: string | undefined,
  id: string,
) =>
  await apiClient
    .put<EnvelopeResponse<IGroundTruthDataResponse>>(
      urls.groundTruthEndEditing(bucketName, folderName, id),
    )
    .then((x) => x.data.data)

const getGroundTruthRaw = (() => {
  const groundTruthCache: {
    key: {
      bucketName: string
      folderName: string | undefined
      id: string
      scaleFactor: number
    }
    value: IDataPointModel
  }[] = []
  const getGroundTruthRaw = async (
    bucketName: string,
    folderName: string | undefined,
    id: string,
    scaleFactor: number,
  ) => {
    const cached = groundTruthCache.find(
      (x) =>
        x.key.bucketName === bucketName &&
        x.key.folderName === folderName &&
        x.key.id === id &&
        x.key.scaleFactor === scaleFactor,
    )?.value
    if (cached) return cached

    const response = await apiClient.get(
      urls.groundTruthRaw(bucketName, folderName, id, scaleFactor),
      {
        responseType: 'arraybuffer',
      },
    )
    const dpm = DecodeDpm(response.data)
    groundTruthCache.push({
      key: { bucketName, folderName, id, scaleFactor },
      value: dpm,
    })
    // clean up older stuff
    if (groundTruthCache.length > 10)
      groundTruthCache.splice(0, groundTruthCache.length - 10)

    return dpm
  }
  return getGroundTruthRaw
})()

const saveGroundTruth = async (
  bucketName: string,
  folderName: string | undefined,
  id: string,
  data: IGroundTruthDataRequest,
) =>
  await apiClient.put<
    string,
    AxiosResponse<EnvelopeResponse<IGroundTruthDataResponse>>
  >(urls.groundTruth(bucketName, folderName, id), data)
const approveGroundTruth = async (
  bucketName: string,
  folderName: string | undefined,
  id: string,
) =>
  await apiClient
    .put<EnvelopeResponse<Record<string, unknown>>>(
      urls.groundTruthApprove(bucketName, folderName, id),
    )
    .then((x) => x.data.data)
const rejectGroundTruth = async (
  bucketName: string,
  folderName: string | undefined,
  id: string,
) =>
  await apiClient
    .put<EnvelopeResponse<Record<string, unknown>>>(
      urls.groundTruthReject(bucketName, folderName, id),
    )
    .then((x) => x.data.data)

const seedGroundTruth = async (
  bucketName: string,
  folderName: string | undefined,
  id: string,
) =>
  await apiClient
    .post<EnvelopeResponse<IGroundTruthDataResponse>>(
      urls.groundTruthSeed(bucketName, folderName, id),
    )
    .then((x) => x.data.data)
const seedGroundTruthEnabled = async () =>
  await apiClient
    .get<EnvelopeResponse<boolean>>(urls.groundTruthSeedEnabled)
    .then((x) => x.data.data)

const renderImages = async (
  bucketName: string,
  folderName: string | undefined,
  id: string,
  data: {
    viewCount: number
    renderParametersName: Renderers
  },
) =>
  await apiClient
    .post<EnvelopeResponse<void>>(
      urls.renderImages(bucketName, folderName, id),
      data,
    )
    .then((x) => x.data.data)

const renderImagesBeginViewing = async (
  bucketName: string,
  folderName: string | undefined,
  id: string,
  viewCount: number,
  renderParametersName: Renderers,
) =>
  await apiClient
    .put<EnvelopeResponse<void>>(
      urls.renderedImagesBeginViewing(
        bucketName,
        folderName,
        id,
        viewCount,
        renderParametersName,
      ),
    )
    .then((x) => x.data.data)

const renderImagesEndViewing = async (
  bucketName: string,
  folderName: string | undefined,
  id: string,
  viewCount: number,
  renderParametersName: Renderers,
) =>
  await apiClient
    .put<EnvelopeResponse<void>>(
      urls.renderedImagesEndViewing(
        bucketName,
        folderName,
        id,
        viewCount,
        renderParametersName,
      ),
    )
    .then((x) => x.data.data)

const renderedImages = async (
  bucketName: string,
  folderName: string | undefined,
  id: string,
  viewCount: number,
  renderParametersName: Renderers,
) =>
  await apiClient
    .get<EnvelopeResponse<IDicosImageMetaResponse[]>>(
      urls.renderedImages(
        bucketName,
        folderName,
        id,
        viewCount,
        renderParametersName,
      ),
    )
    .then((x) => x.data.data)
const saveRenderedImages = async (
  bucketName: string,
  folderName: string | undefined,
  id: string,
  viewCount: number,
  data: IDicosImageMetaUpdateRequest[],
) =>
  await apiClient.put<
    string,
    AxiosResponse<EnvelopeResponse<IDicosImageMetaResponse[]>>
  >(
    urls.renderedImages(bucketName, folderName, id, viewCount, 'blue-red'),
    data,
  )

const approveOrRejectImages = async (
  bucketName: string,
  folderName: string | undefined,
  id: string,
  viewCount: number,
  renderParametersName: Renderers,
  operation: 'approve' | 'reject',
) =>
  await apiClient
    .put<EnvelopeResponse<Record<string, unknown>>>(
      urls.groundTruthImagesApproveOrReject(
        bucketName,
        folderName,
        id,
        viewCount,
        renderParametersName,
        operation,
      ),
    )
    .then((x) => x.data.data)

const queryIndex = async (request: ITrackedDicosFilters) =>
  await apiClient
    .put<{ items: ITrackedDicosResponse[]; count: number }>(
      urls.queryIndex(),
      request,
    )
    .then((x) => x.data)

const querySingleItem = async (request: {
  name: string
  folder?: string
  bucketName: string
}) =>
  await apiClient
    .get<ITrackedDicosResponse>(urls.querySingleItem(request))
    .then((x) => x.data)

const queryIndexQueue = async () =>
  await apiClient
    .get<IDicosBucketCsvIndexCommand[]>(urls.queryIndexQueue())
    .then((x) => x.data)

const queryFilterOptions = async () =>
  await apiClient
    .get<IFilterOptionsResponse>(urls.queryFilterOptions())
    .then((x) => x.data)

const reindexFromFile = async (
  bucketName: string,
  datasetFile: string,
  folder?: string,
) =>
  await apiClient
    .put<EnvelopeResponse<void>>(
      urls.rebuildIndexFromDatasetFile(bucketName, datasetFile, folder),
      {},
    )
    .then((x) => x.data.data)

export const endpoint = {
  updateImage,
  getImage,
  getImages,
  getLabels,
  getTags,
  upsertLabel,
  upsertTag,
  deleteLabel,
  deleteTag,
  importDicos,
  searchDicosImages,
  triggerTraining,
  getDicosImports,
  getTrainings,
  getTraining,
  getDicosVtiSignedUrl,
  getRenderingProperties,
  uploadTagsFile,
  getGroundTruths,
  getGroundTruth,
  getGroundTruthRaw,
  saveGroundTruth,
  approveGroundTruth,
  rejectGroundTruth,
  seedGroundTruth,
  seedGroundTruthEnabled,
  renderImages,
  renderedImages,
  saveRenderedImages,
  approveOrRejectImages,
  queryIndex,
  querySingleItem,
  queryIndexQueue,
  queryFilterOptions,
  reindexFromFile,
  groundTruthBeginEditing,
  groundTruthEndEditing,
  renderImagesBeginViewing,
  renderImagesEndViewing,
}
