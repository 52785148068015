import * as React from 'react'
import { useState } from 'react'
import * as THREE from 'three'
import { useThree, useFrame } from '@react-three/fiber'
import { Bounds3 } from 'views/ground-truths/models'
import { IDataPointModel } from 'infrastructure/types/rendering'

const boxColour = '#9966ff'

interface BoundingBoxProps {
  boundingBox: Bounds3
  selected: boolean
}

function BoundingBox({ boundingBox, selected }: BoundingBoxProps) {
  const points = getCenteredLineCoords(boundingBox)
  const { camera } = useThree()
  const [lines, setLines] = useState(getBoxLines(points, camera.position))

  // this appears to keep the model turning the correct way when you rotate it
  // in the viewer. I tried removing it and it inverted the controls.
  let a = 0
  useFrame((frame) => {
    a++
    if (a % 10 === 0) {
      setLines(getBoxLines(points, camera.position))
    }
  })

  return (
    <>
      {lines.map((line, i) => (
        <mesh key={`boundingBox-line-${i}`}>
          <tubeGeometry
            args={[line, points.length, selected ? 1.0 : 0.5, 8, false]}
          />
          <lineBasicMaterial color={boxColour} />
        </mesh>
      ))}
    </>
  )
}

export default BoundingBox

export const getCenteredLineCoords = (boundingBox: Bounds3): number[][] => {
  const x1 = boundingBox.x1
  const x2 = boundingBox.x2
  const y1 = boundingBox.y1
  const y2 = boundingBox.y2
  const z1 = boundingBox.z1
  const z2 = boundingBox.z2
  // Get pairs of co-ordinates from which we
  // will later draw lines
  // the ordering matters when converting to box lines
  return [
    [x1, y1, z1],
    [x2, y1, z1],
    [x2, y2, z1],
    [x1, y2, z1],
    [x1, y1, z1],
    [x1, y1, z2],
    [x1, y2, z2],
    [x2, y2, z2],
    [x2, y1, z2],
    [x1, y1, z2],
    [x2, y1, z2],
    [x2, y1, z1],
    [x2, y2, z1],
    [x2, y2, z2],
    [x1, y2, z2],
    [x1, y2, z1],
  ]
}

// Points are ordered so that iterating through p -> p + 1 will draw a box
function getBoxLines(points: number[][], cameraPosition: THREE.Vector3) {
  const lines = []
  for (let i = 0; i < points.length - 1; i++) {
    lines.push(
      new THREE.LineCurve3(
        new THREE.Vector3(...points[i]),
        new THREE.Vector3(...points[i + 1]),
      ),
    )
  }
  return lines
}
