import styled from 'styled-components'
import { spacing } from 'styles/spacing'

export const DirectoryViewBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing.Small};
`

export const TabPanelContent = styled.div`
  flex: 1;
  margin-top: ${spacing.Medium};
`
