import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApiState } from 'infrastructure/types/api'
import {
  IImage,
  IImageLabel,
  MapImageResponseToImage,
} from 'infrastructure/types/image'
import { ILabel } from 'infrastructure/types/label'
import { toast } from 'react-toastify'
import { loadImage, saveImage } from './imageActions'

interface IImageState {
  fetchState: IApiState
  value: IImage | null
  selectedLabel: ILabel | null
  selectedImageLabel: IImageLabel | null
  isEditing: boolean
}

const initialState: IImageState = {
  fetchState: {
    loading: true,
    error: false,
  },
  value: null,
  selectedLabel: null,
  selectedImageLabel: null,
  isEditing: false,
}

const slice = createSlice({
  name: 'image',
  initialState,
  reducers: {
    selectLabel: (state, action: PayloadAction<ILabel>) => {
      if (state.selectedLabel?.labelId === action.payload.labelId) {
        state.selectedLabel = null
      } else {
        state.selectedLabel = action.payload
      }
    },
    setActive: (state, action: PayloadAction<boolean>) => {
      if (state.value) {
        state.value.active = action.payload
      }
    },
    toggleImageLabel: (state, action: PayloadAction<IImageLabel>) => {
      if (
        state.selectedImageLabel &&
        state.selectedImageLabel.labelId === action.payload.labelId
      ) {
        state.selectedImageLabel = null
      } else {
        state.selectedImageLabel = action.payload
      }
    },
    selectImageLabel: (state, action: PayloadAction<IImageLabel | null>) => {
      state.selectedImageLabel = action.payload
    },
    verifyImageLabel: (state, action: PayloadAction<string>) => {
      if (state.value) {
        state.value.labels = state.value.labels.map((label) => {
          if (label.id === action.payload) {
            return { ...label, verified: true }
          }

          return label
        })
      }
    },
    addImageLabel: (state, action: PayloadAction<IImageLabel>) => {
      if (state.value) {
        state.value.labels = [...state.value.labels, action.payload]
      }
    },
    updateImageLabel: (state, action: PayloadAction<IImageLabel>) => {
      if (state.value) {
        state.value.labels = state.value.labels.map((label) => {
          if (label.id === action.payload.id) {
            return action.payload
          }

          return label
        })
      }
    },
    removeSelectedImageLabel: (state) => {
      if (state.value && state.selectedImageLabel) {
        state.value.labels = state.value.labels.filter(
          (x) => x.id !== state.selectedImageLabel?.id,
        )
      }
    },
    addImageTag: (state, action: PayloadAction<string>) => {
      if (state.value) {
        state.value.tags = [...state.value.tags, action.payload]
      }
    },
    removeImageTag: (state, action: PayloadAction<string>) => {
      if (state.value) {
        state.value.tags = state.value.tags.filter((x) => x !== action.payload)
      }
    },
    setIsEditing: (state, action: PayloadAction<boolean>) => {
      state.isEditing = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadImage.pending, (state) => {
      state.fetchState.loading = true
      state.fetchState.error = false
    })

    builder.addCase(loadImage.fulfilled, (state, action) => {
      state.fetchState.loading = false
      state.fetchState.error = false
      state.value = MapImageResponseToImage(action.payload)
    })

    builder.addCase(loadImage.rejected, (state) => {
      state.fetchState.loading = false
      state.fetchState.error = true
    })
    builder.addCase(saveImage.fulfilled, (state, action) => {
      if (state.value) state.value.version = action.payload.version
    })
  },
})

export const { actions, reducer } = slice
