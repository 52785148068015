import { Layout } from 'components/layout/layout'
import { HasRequiredRole, routes } from 'infrastructure/routes'
import React, { useEffect } from 'react'
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { LandingView } from 'views/landing/landingView'
import { createGlobalStyle } from 'styled-components'
import { GalleryView } from 'views/gallery/galleryView'
import { ImageView } from 'views/image/imageView'
import { colours } from 'styles/colours'
import { Provider } from 'react-redux'
import { store } from 'infrastructure/store'
import { LabelsView } from 'views/labels/labelsView'
import { TagsView } from 'views/tags/tagsView'
import { TrainingListView } from 'views/training/trainingListView'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import { DirectoryView } from 'views/directory/directoryView'
import { OidcProvider, OidcSecure, useOidcUser } from '@axa-fr/react-oidc'
import { OidcUserInfo } from '@axa-fr/react-oidc/dist/vanilla/vanillaOidc'
import { OidcConfiguration } from 'infrastructure/auth'
import { loadTags } from 'views/tags/tagsActions'
import { useAppDispatch } from 'infrastructure/hooks'
import { loadLabels } from 'views/labels/labelsActions'
import { TrainingRunView } from 'views/training/trainingRun/trainingRunView'
import { GroundTruthList } from 'views/ground-truths/groundTruthList'
import { GroundTruthView } from 'views/ground-truths/groundTruthView'
import { GroundTruthBuckets } from 'views/ground-truths/groundTruthBuckets'
import { GroundTruthRenderedImagesView } from 'views/ground-truths/groundTruthRenderedImagesView'
import GroundTruthIndexList from 'views/ground-truths/groundTruthIndexList'
import GroundTruthIndexBuildView from 'views/ground-truths/groundTruthIndexBuildView'

const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline:0;
    box-sizing:border-box;
    font-family: 'Open Sans', sans-serif; 
  }

  a {
    text-decoration: none;
    color: ${colours.primary};
  }

  body {
    min-height: 100vh;
    display: flex;
    flex-flow: column;
  }

  #root {
    display: flex;
    flex-flow: column;
    flex: 1 1 auto;
  }

  .clickable{
    cursor: pointer;
  }

  .right{
    text-align: right;
  }
 `

export const App: React.FC = () => {
  return (
    <Provider store={store}>
      <AppContent />
    </Provider>
  )
}

const AppContent: React.FC = () => {
  return (
    <>
      <GlobalStyle />
      <ToastContainer />
      <OidcProvider configuration={OidcConfiguration}>
        <SecureAppContent />
      </OidcProvider>
    </>
  )
}

const SecureAppContent: React.FC = () => {
  const { oidcUser } = useOidcUser<OidcUserInfo & { role: string[] }>()
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(loadTags())
    dispatch(loadLabels())
  }, [dispatch])
  return (
    <OidcSecure>
      <Router>
        <Routes>
          {oidcUser && oidcUser.role && (
            <Route element={<Layout />}>
              <>
                {HasRequiredRole(oidcUser.role, routes.Landing) && (
                  <Route index element={<LandingView />} />
                )}
                {HasRequiredRole(oidcUser.role, routes.Gallery) && (
                  <Route path={routes.Gallery.path} element={<GalleryView />} />
                )}

                {HasRequiredRole(oidcUser.role, routes.Image) && (
                  <Route path={routes.Image.path} element={<ImageView />} />
                )}

                {HasRequiredRole(oidcUser.role, routes.Labels) && (
                  <Route path={routes.Labels.path} element={<LabelsView />} />
                )}

                {HasRequiredRole(oidcUser.role, routes.Tags) && (
                  <Route path={routes.Tags.path} element={<TagsView />} />
                )}

                {HasRequiredRole(oidcUser.role, routes.Training) && (
                  <Route
                    path={routes.Training.path}
                    element={<TrainingListView />}
                  />
                )}

                {HasRequiredRole(oidcUser.role, routes.TrainingRun) && (
                  <Route
                    path={routes.TrainingRun.path}
                    element={<TrainingRunView />}
                  />
                )}

                {HasRequiredRole(oidcUser.role, routes.Directory) && (
                  <Route
                    path={routes.Directory.path}
                    element={<DirectoryView />}
                  />
                )}
                {HasRequiredRole(oidcUser.role, routes.GroundTruthBuckets) && (
                  <Route
                    path={routes.GroundTruthBuckets.path}
                    element={<GroundTruthBuckets />}
                  />
                )}
                {HasRequiredRole(oidcUser.role, routes.GroundTruthList) && (
                  <Route
                    path={routes.GroundTruthList.path}
                    element={<GroundTruthList />}
                  />
                )}
                {HasRequiredRole(oidcUser.role, routes.GroundTruthItem) && (
                  <Route
                    path={routes.GroundTruthItem.path}
                    element={<GroundTruthView />}
                  />
                )}
                {HasRequiredRole(oidcUser.role, routes.GroundTruthIndex) && (
                  <Route
                    path={routes.GroundTruthIndex.path}
                    element={<GroundTruthIndexList />}
                  />
                )}
                {HasRequiredRole(
                  oidcUser.role,
                  routes.GroundTruthIndexBuild,
                ) && (
                  <Route
                    path={routes.GroundTruthIndexBuild.path}
                    element={<GroundTruthIndexBuildView />}
                  />
                )}
                {HasRequiredRole(
                  oidcUser.role,
                  routes.GroundTruthRenderedImages,
                ) && (
                  <Route
                    path={routes.GroundTruthRenderedImages.path}
                    element={<GroundTruthRenderedImagesView />}
                  />
                )}
              </>
            </Route>
          )}
        </Routes>
      </Router>
    </OidcSecure>
  )
}
