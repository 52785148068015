import { IImage, IImageLabel } from 'infrastructure/types/image'
import { ReactElement } from 'react'
import { Rectangle } from './rectangle'

export interface IViewCanvasProps {
  image: IImage | null
  labelsMap: Map<string, string>
  canvasWidth: number
  canvasHeight: number
  selectedImageLabel: IImageLabel | null
  onSelectImageLabel: (label: IImageLabel) => void
  onToggleImageLabel: (label: IImageLabel) => void
  onUpdateRectangle: (label: IImageLabel) => void
  setIsEditing: (isEditing: boolean) => void
}

export const ViewCanvas = (props: IViewCanvasProps): ReactElement => {
  const {
    image,
    labelsMap,
    canvasWidth,
    canvasHeight,
    selectedImageLabel,
    onSelectImageLabel,
    onToggleImageLabel,
    onUpdateRectangle,
    setIsEditing,
  } = props

  const DrawBoundingBox = (label: IImageLabel, index: number) => {
    if (!canvasWidth || !canvasHeight) return

    const { x1, y1, x2, y2 } = label

    const x = x1 * canvasWidth
    const y = y1 * canvasHeight
    const width = (x2 - x1) * canvasWidth
    const height = (y2 - y1) * canvasHeight

    const UpdateImageLabel = (
      x: number,
      y: number,
      width: number,
      height: number,
    ) => {
      onUpdateRectangle({
        ...label,
        x1: x / canvasWidth,
        y1: y / canvasHeight,
        x2: (x + width) / canvasWidth,
        y2: (y + height) / canvasHeight,
      })
    }

    return (
      <Rectangle
        key={index}
        colour={labelsMap.get(label.labelId) ?? ''}
        x={x}
        y={y}
        width={width}
        height={height}
        canvasWidth={canvasWidth}
        canvasHeight={canvasHeight}
        isSelected={selectedImageLabel === label}
        onToggle={() => onToggleImageLabel(label)}
        onSelect={() => onSelectImageLabel(label)}
        onEditRectangle={(
          x: number,
          y: number,
          width: number,
          height: number,
        ) => UpdateImageLabel(x, y, width, height)}
        setIsEditing={(isEditing: boolean) => setIsEditing(isEditing)}
      />
    )
  }

  return (
    <>
      {image && (
        <>{image.labels.map((label, index) => DrawBoundingBox(label, index))}</>
      )}
    </>
  )
}
