import {
  IDicosImportFileResponse,
  IDicosImportResponse,
} from 'infrastructure/api/response/import'

export enum ImportStatus {
  AwaitingTransfer = 'AwaitingTransfer',
  AwaitingRenders = 'AwaitingRenders',
  Complete = 'Complete',
  Error = 'Error',
}

export const ImportStatusMapper = new Map<ImportStatus, string>([
  [ImportStatus.AwaitingTransfer, 'Awaiting Transfer'],
  [ImportStatus.AwaitingRenders, 'Awaiting Renders'],
  [ImportStatus.Complete, 'Complete'],
  [ImportStatus.Error, 'Error'],
])

export interface IDicosImport {
  id: string
  dateCreated: string
  complete: boolean
  dicosImportFiles: IDicosImportFile[]
}

export interface IDicosImportFile {
  sourceBucket: string
  sourceFolder: string
  fileName: string
  status: ImportStatus
  renderParameterName: string
}

export const MapDicosImportFilesResponseToDicosImportFiles = (
  response: IDicosImportFileResponse[],
): IDicosImportFile[] =>
  response.map((item) => ({
    sourceBucket: item.sourceBucket,
    sourceFolder: item.sourceFolder,
    fileName: item.fileName,
    status: item.status,
    renderParameterName: item.renderParameterName,
  }))

export const MapDicosImportsResponseToDicosImports = (
  response: IDicosImportResponse[],
): IDicosImport[] =>
  response.map((item) => ({
    id: item.id,
    dateCreated: item.dateCreated,
    complete: item.complete,
    dicosImportFiles: MapDicosImportFilesResponseToDicosImportFiles(
      item.dicosImportFiles,
    ),
  }))
