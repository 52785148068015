import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApiState } from 'infrastructure/types/api'
import {
  IDicosImport,
  MapDicosImportsResponseToDicosImports,
} from 'infrastructure/types/import'
import { toast } from 'react-toastify'
import {
  createAndAddTag,
  importDicos,
  loadImports,
  uploadTagsFile,
} from './directoryActions'

interface IDirectoryImportState {
  importState: IApiState
  sourceBucket: string
  sourceFolder: string | null
  dicosFileNames: string[]
  tagIds: string[]
  renderParameterNames: string[]
  fetchState: IApiState
  imports: IDicosImport[]
  pageNumber: number
  pageSize: number
}

const initialState: IDirectoryImportState = {
  importState: {
    loading: false,
    error: false,
  },
  sourceBucket: '',
  sourceFolder: null,
  dicosFileNames: [],
  tagIds: [],
  renderParameterNames: [],
  fetchState: {
    loading: true,
    error: false,
  },
  imports: [],
  pageNumber: 0,
  pageSize: 20,
}

const slice = createSlice({
  name: 'directoryImport',
  initialState,
  reducers: {
    initialise: () => {
      return initialState
    },
    setSourceBucket: (state, action: PayloadAction<string>) => {
      state.sourceBucket = action.payload
    },
    setSourceFolder: (state, action: PayloadAction<string>) => {
      state.sourceFolder = action.payload
    },
    addTagId: (state, action: PayloadAction<string>) => {
      state.tagIds = [...state.tagIds, action.payload]
    },
    removeTagId: (state, action: PayloadAction<string>) => {
      state.tagIds = state.tagIds.filter((x) => x !== action.payload)
    },
    setDicosFileNameCsv: (state, action: PayloadAction<string>) => {
      state.dicosFileNames = action.payload
        .split(/\r\n|\r|\n/)
        .map((x) => x.trim())
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload
    },
    addRender: (state, action: PayloadAction<string>) => {
      state.renderParameterNames = [
        ...state.renderParameterNames,
        action.payload,
      ]
    },
    removeRender: (state, action: PayloadAction<string>) => {
      state.renderParameterNames = state.renderParameterNames.filter(
        (x) => x !== action.payload,
      )
    },
  },
  extraReducers: (builder) => {
    builder.addCase(importDicos.pending, (state) => {
      state.importState.loading = true
      state.importState.error = false
    })
    builder.addCase(importDicos.fulfilled, (state) => {
      toast.success('The dicos files were imported successfully', {
        pauseOnHover: true,
      })
      state.importState.loading = false
      state.importState.error = false
    })
    builder.addCase(importDicos.rejected, (state) => {
      state.importState.loading = false
      state.importState.error = true
    })
    builder.addCase(createAndAddTag.fulfilled, (state, action) => {
      toast.success('The new tag was created successfully', {
        pauseOnHover: true,
      })

      state.tagIds = [...state.tagIds, action.payload.tagId]
    })

    builder.addCase(loadImports.pending, (state) => {
      state.fetchState.loading = true
      state.fetchState.error = false
    })

    builder.addCase(loadImports.fulfilled, (state, action) => {
      state.fetchState.loading = false
      state.fetchState.error = false
      state.imports = [
        ...state.imports,
        ...MapDicosImportsResponseToDicosImports(action.payload),
      ]
    })

    builder.addCase(loadImports.rejected, (state) => {
      state.fetchState.loading = false
      state.fetchState.error = true
    })

    builder.addCase(uploadTagsFile.fulfilled, () => {
      toast.success('The tag file was uploaded successfully', {
        pauseOnHover: true,
      })
    })
  },
})

export const { actions, reducer } = slice
