/* eslint-disable no-console */
import * as React from 'react'
import { useRef, useLayoutEffect, useState, useEffect } from 'react'
import * as THREE from 'three'
import { useFrame } from '@react-three/fiber'
import { VolumeRenderShader1 } from './shaders/VolumeShader'
// textures
import Viridis from './textures/Viridis.png'
import Gray from './textures/Gray.png'
import RedBlue from './textures/RedBlue.png'
import WhiteRedBlue from './textures/WhiteRedBlue.png'
import WhiteRedBlue2 from './textures/WhiteRedBlue2.png'
import RedHighlight from './textures/RedHighlight.png'
import FSS from './textures/FSS.png'
import { IDataPointModel } from 'infrastructure/types/rendering'
import { Bounds3 } from 'views/ground-truths/models'

type ColourBand = {
  r: number
  g: number
  b: number
}

// 0 ---------------- 255
// 2 colours
// a                   b
// a --------|-------- b
// 3 colours
// a         b         c
// a ---|----b----|--- c
function makeTexture(bands: ColourBand[]) {
  // Create an array to hold the color data
  const data = new Uint8Array(256 * 4)

  // Calculate the number of pixels per band
  const pixelsPerBand = Math.floor(256 / (bands.length - 1))

  // Loop through each band
  for (let i = 0; i < bands.length - 1; i++) {
    // Get the start and end colors for this band
    const start = bands[i]
    const end = bands[i + 1]

    // Loop through the pixels in this band
    for (let j = 0; j < pixelsPerBand; j++) {
      // Get the offset for this pixel
      const offset = (i * pixelsPerBand + j) * 4

      // Calculate the percentage of the way through the band this pixel is
      const percent = j / pixelsPerBand

      // Calculate the color of this pixel
      const r = Math.floor(start.r + (end.r - start.r) * percent)
      const g = Math.floor(start.g + (end.g - start.g) * percent)
      const b = Math.floor(start.b + (end.b - start.b) * percent)

      // Set the pixel in the data array
      data[offset] = r
      data[offset + 1] = g
      data[offset + 2] = b
      data[offset + 3] = 128
    }
  }

  // Create a data texture
  const texture = new THREE.DataTexture(data, 256, 1, THREE.RGBAFormat)
  texture.needsUpdate = true
  return texture
}

// preload textures
const cmtextures = {
  Viridis: new THREE.TextureLoader().load(Viridis),
  Gray: new THREE.TextureLoader().load(Gray),
  RedBlue: new THREE.TextureLoader().load(RedBlue),
  WhiteRedBlue: new THREE.TextureLoader().load(WhiteRedBlue),
  WhiteRedBlue2: new THREE.TextureLoader().load(WhiteRedBlue2),
  RedHighlight: new THREE.TextureLoader().load(RedHighlight),
  FSS: new THREE.TextureLoader().load(FSS),
  RGB: makeTexture([
    { r: 255, g: 0, b: 0 },
    { r: 0, g: 255, b: 0 },
    { r: 0, g: 0, b: 255 },
  ]),
}

interface RenderOptions {
  style: number
  threshold: number
  maxThreshold: number
}

type ModelViewerOptions = {
  isolateThreats: boolean // toggle to display only the threats or the whole model
  renderStyle: 'iso' | 'mip' | 'isomip' | 'dvr' // Isosurface ray tracing or Maximum Intensity Projection ray tracing
  showBoundingBox: boolean
  highlightThreats: boolean
  threshold: number
  maxThreshold: number
  colourMap: 'Viridis' | 'Gray' | 'RedBlue' | 'WhiteRedBlue' | 'FSS' | 'RGB'
}

interface ScanVolumeMeshProps {
  model: IDataPointModel
  displayOptions: ModelViewerOptions
  boundingBox: Bounds3 | null
  toOriginMatrix: THREE.Matrix4 | null
  rotationMatrix: THREE.Matrix4 | null
  fromOriginMatrix: THREE.Matrix4 | null
  classNameModel: string
}

const MIP = 0
const ISO = 1
const ISOMIP = 2
const DVR = 3

function ScanVolumeMesh({
  model,
  displayOptions,
  boundingBox = null,
  toOriginMatrix = null,
  rotationMatrix = null,
  fromOriginMatrix = null,
  classNameModel,
}: ScanVolumeMeshProps) {
  // Scale height width and depth
  const depth = model.slices.count
  const u_width = model.metadata.width * model.metadata.pixelWidth
  const u_height = model.metadata.height * model.metadata.pixelHeight
  const u_depth = depth * model.metadata.pixelDepth
  const half_u_width = u_width / 2
  const half_u_height = u_height / 2
  const half_u_depth = u_depth / 2

  // create a counter that we will use to force re-renders
  const [renderCounter, setRenderCounter] = useState(0)
  const renderOptions: RenderOptions = {
    style:
      displayOptions.renderStyle === 'mip'
        ? MIP
        : displayOptions.renderStyle === 'iso'
        ? ISO
        : displayOptions.renderStyle === 'isomip'
        ? ISOMIP
        : DVR,
    threshold: displayOptions.threshold,
    maxThreshold: displayOptions.maxThreshold,
  }

  const rawData = new Uint8Array(model.slices.data)
  const texture = new THREE.Data3DTexture(
    rawData,
    model.metadata.width,
    model.metadata.height,
    depth,
  )
  texture.format = THREE.RGFormat
  texture.type = THREE.UnsignedByteType
  texture.minFilter = THREE.LinearFilter
  texture.magFilter = THREE.LinearFilter
  texture.generateMipmaps = true
  texture.needsUpdate = true

  // Configure shader uniforms
  const uniforms = THREE.UniformsUtils.clone(VolumeRenderShader1.uniforms)
  uniforms['u_data'].value = texture
  uniforms['u_size'].value.set(u_width, u_height, u_depth)
  uniforms['u_clim'].value.set(0, 1)
  // highlighted threats can only be done in ISO rendering
  // when we get the renderOptions a default value of 0.5 is returned for the threshold
  // else we just use the normal options for rendering
  uniforms['u_renderstyle'].value = renderOptions.style
  uniforms['u_renderthreshold'].value = renderOptions.threshold // For ISO renderstyle
  uniforms['u_highthreshold'].value = renderOptions.maxThreshold // For ISO renderstyle
  uniforms['u_rescaleslope'].value = model.metadata.rescaleSlope
  uniforms['u_rescaleintercept'].value = model.metadata.rescaleIntercept
  // console.log('u_rescaleslope', model.metadata.rescaleSlope, 'u_rescaleintercept', model.metadata.rescaleIntercept, rawData)
  // console.log("maxValue", rawData.reduce((a, b) => Math.max(a, b)))
  // console.log("averageValue", rawData.reduce((a, b) => a + b) / rawData.length)
  // Set the texture which we're going to apply
  const colourMap = displayOptions.highlightThreats
    ? cmtextures.RedHighlight
    : (function () {
        switch (displayOptions.colourMap) {
          case 'Viridis':
            return cmtextures.Viridis
          case 'Gray':
            return cmtextures.Gray
          case 'RedBlue':
            return cmtextures.RedBlue
          case 'WhiteRedBlue':
            return cmtextures.WhiteRedBlue
          case 'FSS':
            return cmtextures.FSS
          case 'RGB':
            return cmtextures.RGB
          default:
            return cmtextures.WhiteRedBlue2
        }
      })()
  uniforms['u_cmdata'].value = colourMap
  uniforms['u_palette2'].value = colourMap

  const [animationNeeded, setAnimationNeeded] = useState(
    !displayOptions.isolateThreats && !boundingBox,
  )

  if (boundingBox) {
    // bounding box is using 1:1 scale co-ordinates which are consistent
    // with the u_<dimension> properties. However, the comparison made in the shader
    // will be against 0 - 1 range normalised vectors. By dividing the bounding box
    // value against the max dimension value we'll get a normalised value which we can use.
    const u_bbox_x1 = boundingBox.x1 / u_width
    const u_bbox_y1 = boundingBox.y1 / u_height
    const u_bbox_z1 = boundingBox.z1 / u_depth
    const u_bbox_x2 = boundingBox.x2 / u_width
    const u_bbox_y2 = boundingBox.y2 / u_height
    const u_bbox_z2 = boundingBox.z2 / u_depth

    uniforms['u_bbox'].value = {
      min: new THREE.Vector3(u_bbox_x1, u_bbox_y1, u_bbox_z1),
      max: new THREE.Vector3(u_bbox_x2, u_bbox_y2, u_bbox_z2),
    }
    uniforms['u_isolatedthreat'].value = true
  }

  const ref = useRef<THREE.Mesh>(null!)

  // when user changes threshold
  useEffect(() => {
    var material = ref.current.material as THREE.ShaderMaterial
    material.uniforms.u_renderthreshold.value = renderOptions.threshold
    return () => {
      setAnimationNeeded(false) // stop animating
    }
  }, [renderOptions.threshold])

  // force rerenders when any other uniform changes
  useEffect(() => {
    setRenderCounter(renderCounter + 1)
  }, [
    displayOptions.renderStyle,
    displayOptions.highlightThreats,
    renderOptions.threshold,
    renderOptions.maxThreshold,
    displayOptions.renderStyle,
    displayOptions.colourMap,
    boundingBox,
  ])

  // const ref = useRef();

  useFrame(({ clock }) => {
    if (
      classNameModel === 'ModelViewer' ||
      toOriginMatrix === null ||
      rotationMatrix === null ||
      fromOriginMatrix === null
    )
      return

    // Apply the translation to the origin, the rotation, and then the translation back
    ref.current.applyMatrix4(toOriginMatrix)
    ref.current.applyMatrix4(rotationMatrix)
    ref.current.applyMatrix4(fromOriginMatrix)
  })

  // center the model
  useLayoutEffect(() => {
    ref.current.geometry.translate(half_u_width, half_u_height, half_u_depth)
  }, [model])

  return (
    <>
      <mesh ref={ref} position={[-half_u_width, -half_u_height, -half_u_depth]}>
        <boxGeometry args={[u_width, u_height, u_depth]} />
        <shaderMaterial
          key={`material-${renderCounter}`}
          uniforms={uniforms}
          vertexShader={VolumeRenderShader1.vertexShader}
          fragmentShader={VolumeRenderShader1.fragmentShader}
          side={THREE.BackSide}
        />
      </mesh>
    </>
  )
}

export default ScanVolumeMesh
