import styled from 'styled-components'
import { colours } from 'styles/colours'

export const SpinnerBox = styled.div<{ size: string }>`
  text-align: center;
  margin: 0 auto;

  .ripple {
    display: inline-block;
    position: relative;
    width: ${(props) => props.size};
    height: ${(props) => props.size};
  }
  .ripple div {
    position: absolute;
    border: 5px solid ${colours.primary};
    opacity: 1;
    border-radius: 50%;
    animation: ripple 2s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }
  .ripple div:nth-child(2) {
    animation-delay: -1s;
  }
  @keyframes ripple {
    0% {
      top: 45%;
      left: 45%;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 45%;
      left: 45%;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 45%;
      left: 45%;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 90%;
      height: 90%;
      opacity: 0;
    }
  }
`
