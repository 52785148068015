import { createAsyncThunk } from '@reduxjs/toolkit'
import { AxiosError } from 'axios'
import { endpoint } from 'infrastructure/api/endpoints'
import { ITagRequest } from 'infrastructure/api/request/tag'
import { IApiError } from 'infrastructure/types/api'
import { toast } from 'react-toastify'

export const loadTags = createAsyncThunk(
  'loadTags',
  async (_, { rejectWithValue }) => {
    try {
      return await endpoint.getTags()
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })

      return rejectWithValue(castError.response?.data)
    }
  },
)

export const addTag = createAsyncThunk(
  'addTag',
  async (tagName: string, { rejectWithValue }) => {
    try {
      const tag: ITagRequest = {
        name: tagName,
      }
      return await endpoint.upsertTag(tag)
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })

      return rejectWithValue(castError.response?.data)
    }
  },
)

export const deleteTag = createAsyncThunk(
  'deleteTag',
  async (tagId: string, { rejectWithValue }) => {
    try {
      await endpoint.deleteTag(tagId)
      return tagId
    } catch (error) {
      var castError = error as AxiosError<IApiError>

      toast.error(castError.response?.data.message, {
        pauseOnHover: true,
      })

      return rejectWithValue(castError.response?.data)
    }
  },
)
