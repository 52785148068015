import styled from 'styled-components'
import { colours } from 'styles/colours'
import { spacing } from 'styles/spacing'

export const TagsBox = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${spacing.Small};
`

export const TagsFilter = styled.div`
  border-bottom: 1px solid ${colours.disabled};
  margin-bottom: ${spacing.Medium};
  display: flex;
  gap: ${spacing.Small};
`

export const Tags = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  grid-gap: ${spacing.Small};
`

export const Tag = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: ${spacing.ExtraSmall};
  background-color: ${colours.contrastLight};
  border-left: 3px solid ${colours.disabled};
  padding: ${spacing.Small};
  text-transform: capitalize;
  min-width: 300px;

  .info {
    display: flex;
    flex-direction: column;
    gap: ${spacing.Small};
  }

  button {
    margin-bottom: 0;
    min-width: 50px;
  }

  input {
    flex: 1;
    padding: ${spacing.ExtraSmall};
  }
`
