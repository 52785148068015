import { FC, useEffect, useState } from 'react'
import { DatasetFileEditor } from './groundTruthList'
import { Button } from 'components/button/styles'
import { useQuery } from './util'
import { useAppDispatch, useAppSelector } from 'infrastructure/hooks'
import { queryIndexQueue, reindexFromFile } from './groundTruthActions'
import { Link, useNavigate, useParams } from 'react-router-dom'
import {
  selectIsLoading,
  selectTrackedDicosIndexQueue,
} from './groundTruthSelectors'
import styled from 'styled-components'
import { Spinner } from 'components/spinner/spinner'

const FlexH = styled.div`
  display: flex;
  flex-direction: column;
`
const Queue: FC = () => {
  const data = useAppSelector(selectTrackedDicosIndexQueue)

  return (
    <div>
      {data.map((d, i) => (
        <FlexH key={i}>
          <div>{d.bucket}</div>
          <div>{d.folder}</div>
          <div>{d.name}</div>
          <div>{d.expectedItemIds}</div>
          <div>{d.sourceCsvFile}</div>
        </FlexH>
      ))}
    </div>
  )
}

const GroundTruthIndexBuildView: FC = () => {
  const { bucketName } = useParams()
  const folder = useQuery('folder')
  const datasetFile = useQuery('datasetFile')
  const nav = useNavigate()
  const dispatch = useAppDispatch()
  const isLoading = useAppSelector(selectIsLoading)
  const [isDispatched, setIsDispatched] = useState(false)
  const reindex = () => {
    if (datasetFile == null) return
    if (bucketName == null) return

    dispatch(
      reindexFromFile({
        bucketName,
        folder: folder == null ? undefined : folder,
        datasetFile,
      }),
    )
    dispatch(queryIndexQueue({}))
    setIsDispatched(true)
  }

  useEffect(() => {
    dispatch(queryIndexQueue({}))
    const tId = setTimeout(() => {
      dispatch(queryIndexQueue({}))
    }, 10000)
    return () => clearTimeout(tId)
  }, [])

  // if (datasetFile == null) return <div>no dataset file</div>

  return (
    <div>
      <DatasetFileEditor
        datasetFile={datasetFile ?? ''}
        onDatasetFileChanged={(ds) => nav('./?datasetFile=' + ds)}
      />
      {isLoading ? (
        <Spinner size="80px" />
      ) : isDispatched ? (
        <div>
          Success. Rows added and will be indexed in the background. Please
          leave up to half an hour to propagate. You should be able to see your
          new entries in the <Link to={'/ground-truths/dcs-idx'}>Index</Link>{' '}
          page when selecting the file name filter.
        </div>
      ) : (
        <div>
          <div>
            About to reindex files in bucket <b>{bucketName}</b> with dataset
            file: <b>{datasetFile}</b>
          </div>
          <Button onClick={reindex}>Reindex</Button>
        </div>
      )}
      <Queue />
    </div>
  )
}

export default GroundTruthIndexBuildView
