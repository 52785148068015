import { renderOptions } from 'infrastructure/types/rendering'
import { useLocation, useNavigate } from 'react-router-dom'

export function useQuery(key: string) {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  return searchParams.get(key)
}

export function useQueryAll(key: string) {
  const location = useLocation()
  const searchParams = new URLSearchParams(location.search)
  return searchParams.getAll(key)
}

export function useStateInQuery<T>(key: string, defaultValue: T) {
  const nav = useNavigate()
  const encode = (t: T) => {
    return encodeURIComponent(JSON.stringify(t))
  }
  const decode = (t: string) => {
    return JSON.parse(decodeURIComponent(t))
  }
  const setState = (next: T) => {
    nav({
      search: new URLSearchParams({
        [key]: encode(next),
      }).toString(),
    })
  }
  const stateString = useQuery(key)
  const state =
    stateString != null
      ? decode(stateString) // note - there are no type guards here, so this could be used to insert partially formed objects
      : defaultValue
  return [state, setState] as [T, (t: T) => void]
}

const viewCounts = [6, 25]

export const renderOptionsWithViews = renderOptions
  .flatMap((renderOption) =>
    viewCounts.map((viewCount) => ({
      renderParametersName: renderOption.value,
      viewCount,
    })),
  )
  .concat([
    {
      renderParametersName: 'blue-red',
      viewCount: 100,
    },
  ])
