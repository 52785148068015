import styled from 'styled-components'
import { colours } from 'styles/colours'
import { spacing } from 'styles/spacing'

export const ToggleWrapper = styled.div`
  display: flex;
  width: 100%;
`

export const ToggleItem = styled.div`
  padding: ${spacing.ExtraSmall};
  border: 1px solid ${colours.primary};
  color: ${colours.primary};
  width: 100%;
  text-align: center;
  cursor: pointer;

  &.active {
    border: 1px solid ${colours.disabled};
    color: ${colours.disabled};
    cursor: default;
  }
`
