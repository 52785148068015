import styled from 'styled-components'
import { colours } from 'styles/colours'
import { spacing } from 'styles/spacing'

export const Pill = styled.button`
  background-color: ${colours.contrastLight};
  color: ${colours.primary};
  padding: ${spacing.Tiny};
  border: 1px solid ${colours.primary};
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: ${spacing.Small};
`
