import { ReactElement } from 'react'
import { SubTitle, Title } from 'styles/textography'
import { RowSpaced, FlexBoxColumn, Panel } from './styles'
import { TrashIcon } from '@heroicons/react/outline'
import { colours } from 'styles/colours'
import { IImage } from 'infrastructure/types/image'
import { useAppDispatch, useAppSelector } from 'infrastructure/hooks'
import { actions } from 'views/image/imageReducer'
import SelectSearch from 'react-select-search'
import 'react-select-search/style.css'
import { saveImage } from 'views/image/imageActions'

export interface IMetadataProps {
  image: IImage | null
}

export const Metadata = (props: IMetadataProps): ReactElement => {
  const dispatch = useAppDispatch()
  const { image } = props
  const tags = useAppSelector((state) => state.tags.tags)
  const tagsMap = new Map<string, string>(
    tags.map((tag) => {
      return [tag.tagId, tag.name]
    }),
  )
  const availableTags = tags.filter((x) => !image?.tags.includes(x.tagId))

  const addTag = (tagId: any) => {
    dispatch(actions.addImageTag(tagId))
    dispatch(saveImage())
  }

  const removeTag = (tagId: string) => {
    dispatch(actions.removeImageTag(tagId))
    dispatch(saveImage())
  }

  return (
    <FlexBoxColumn>
      {image && (
        <>
          <Panel className="spaced">
            <Title>File Info</Title>

            <div>
              <SubTitle>Scan</SubTitle>
              <a href={image.dicosLocation}>DICOSNAME</a>
            </div>

            <div>
              <SubTitle>Image</SubTitle>
              <a href={image.location}>{image.name}</a>
            </div>

            <div>
              <SubTitle>Orientation</SubTitle>
              <RowSpaced>
                <div>x: {image.renderOrientationX}</div>
                <div>y: {image.renderOrientationY}</div>
                <div>z: {image.renderOrientationZ}</div>
              </RowSpaced>
            </div>
          </Panel>

          <Panel>
            <Title>Tags</Title>
            <SelectSearch
              options={availableTags.map((x) => ({
                name: x.name,
                value: x.tagId,
              }))}
              search={true}
              emptyMessage="No more tags"
              placeholder="Add a tag"
              closeOnSelect
              multiple={false}
              onChange={addTag}
              filterOptions={() => (f) => {
                return availableTags
                  .filter((x) =>
                    x.name.toLowerCase().startsWith(f.toLowerCase()),
                  )
                  .map((x) => ({
                    name: x.name,
                    value: x.tagId,
                  }))
              }}
            />
            {image.tags.map((value, index) => (
              <RowSpaced key={index}>
                <div>{tagsMap.get(value)}</div>
                <TrashIcon
                  className="clickable"
                  width={18}
                  height={18}
                  color={colours.primary}
                  onClick={() => removeTag(value)}
                />
              </RowSpaced>
            ))}
          </Panel>
        </>
      )}
    </FlexBoxColumn>
  )
}
