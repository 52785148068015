import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApiState } from 'infrastructure/types/api'
import { IImage, MapImagesResponseToImages } from 'infrastructure/types/image'
import { ILabel } from 'infrastructure/types/label'
import { ITag } from 'infrastructure/types/tag'
import { toast } from 'react-toastify'
import { loadImages, triggerTraining } from './galleryActions'

export interface IGalleryState {
  fetchState: IApiState
  images: IImage[]
  searchText: string | null
  filters: IGalleryFilters
  pageNumber: number
  pageSize: number
}

export interface IGalleryFilters {
  labels: ILabel[]
  tags: ITag[]
  unlabelledOnly: boolean
}

const initialState: IGalleryState = {
  fetchState: {
    loading: true,
    error: false,
  },
  images: [],
  searchText: null,
  filters: {
    labels: [],
    tags: [],
    unlabelledOnly: false,
  },
  pageNumber: 0,
  pageSize: 20,
}

const slice = createSlice({
  name: 'gallery',
  initialState,
  reducers: {
    setSearchText: (state, action: PayloadAction<string>) => {
      state.pageNumber = initialState.pageNumber
      state.pageSize = initialState.pageSize
      state.images = initialState.images
      state.searchText = action.payload
    },
    setFilters: (state, action: PayloadAction<IGalleryFilters>) => {
      state.pageNumber = initialState.pageNumber
      state.pageSize = initialState.pageSize
      state.images = initialState.images
      state.filters = action.payload
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadImages.pending, (state) => {
      state.fetchState.loading = true
      state.fetchState.error = false
    })

    builder.addCase(loadImages.fulfilled, (state, action) => {
      state.fetchState.loading = false
      state.fetchState.error = false
      state.images = [
        ...state.images,
        ...MapImagesResponseToImages(action.payload),
      ]
    })

    builder.addCase(loadImages.rejected, (state) => {
      state.fetchState.loading = false
      state.fetchState.error = true
    })

    builder.addCase(triggerTraining.fulfilled, () => {
      toast.success('Training on labelled images triggered successfully', {
        pauseOnHover: true,
      })
    })
  },
})

export const { actions, reducer } = slice
