import { ReactElement } from 'react'
import { Link } from 'react-router-dom'

export const GroundTruthBuckets = (): ReactElement => {
  const buckets = [
    { name: 'test-bucket', folder: 'test-folder' },
    { name: 'temp-import-bucket-byron', folder: '' },
    { name: 'bootcamp-dicos', folder: '' },
    { name: 'bootcamp-pangiam-assessment-set', folder: '' },
    { name: 'heathrow-assessment-data', folder: 'winter' },
  ]
  return (
    <div>
      {buckets.map((l) => (
        <div key={`${l}`}>
          <Link to={`./${l.name}?folderName=${l.folder}`}>
            {l.name} {l.folder}
          </Link>
        </div>
      ))}
    </div>
  )
}
