import { Breadcrumbs } from 'components/breadcrumbs/breadcrumbs'
import { Header } from 'components/header/header'
import { Outlet } from 'react-router-dom'
import { LayoutBox, LayoutWrapper } from './styles'

export const Layout = () => {
  return (
    <LayoutWrapper>
      <Header />
      <LayoutBox>
        <Breadcrumbs />
        <Outlet />
      </LayoutBox>
    </LayoutWrapper>
  )
}
