import { RootState } from 'infrastructure/store'
import { fromGroundTruthItem } from './models'
import { IGroundTruth } from 'infrastructure/types/groundTruth'

const selectGroundTruthArea = (state: RootState) => state.groundTruth

export const selectFocusedGroundTruthEditAsInferences = (state: RootState) =>
  selectGroundTruthArea(state).focusedFile?.dataEdit?.groundTruth?.map(
    fromGroundTruthItem,
  ) ?? []

export const selectedFocusedGroundTruthSummary = (state: RootState) => {
  const nstate = selectGroundTruthArea(state)
  const id = nstate.focusedFile?.id ?? ''
  const idx = selectGroundTruthArea(state).filesIdx[id]
  return nstate.files[idx]?.summary as IGroundTruth | undefined
}

export const selectGroundTruthRenderedImages = (state: RootState) =>
  selectGroundTruthArea(state).focusedRenderedImages ?? []

export const selectGroundTruthRenderedImagesLoadSaveState = (
  state: RootState,
) => selectGroundTruthArea(state).focusedRenderedImagesState

export const selectedFocusedGroundTruthApprovals = (state: RootState) =>
  selectGroundTruthArea(state).focusedFile?.dataEdit?.approvals ?? []

export const selectTrackedDicos = (state: RootState) => ({
  count: selectGroundTruthArea(state).trackedDicos.count,
  items: selectGroundTruthArea(state).trackedDicos.items.map((dcs) => ({
    ...dcs,
    isUserEditing: selectGroundTruthArea(
      state,
    ).userTracking.userEditingDicos.some(
      (d) =>
        d.name === dcs.name &&
        d.bucket === dcs.bucket &&
        d.folder === dcs.folder,
    ),
    indexed:
      dcs.indexed != null
        ? {
            ...dcs.indexed,
            renderGroups: dcs.indexed?.renderGroups.map((rg) => ({
              ...rg,
              isUserEditing: selectGroundTruthArea(
                state,
              ).userTracking.userViewingImages.some(
                (r) =>
                  r.bucket === dcs.bucket &&
                  r.folder === dcs.folder &&
                  r.name === dcs.name &&
                  r.renderParametersName === rg.renderParametersName &&
                  r.viewCount === rg.viewCount,
              ),
              isRendering: selectGroundTruthArea(
                state,
              ).renderTracking.rendering.some(
                (r) =>
                  r.bucket === dcs.bucket &&
                  r.folder === dcs.folder &&
                  r.name === dcs.name &&
                  r.renderParametersName === rg.renderParametersName &&
                  r.viewCount === rg.viewCount,
              ),
            })),
          }
        : undefined,
  })),
})

export const selectTrackedDicosIndexQueue = (state: RootState) =>
  selectGroundTruthArea(state).trackedDicosIndexQueue

export const selectQueryFilterOptions = (state: RootState) =>
  selectGroundTruthArea(state).filterOptions

export const selectIsLoading = (state: RootState) =>
  selectGroundTruthArea(state).fetchState.loading

export const selectGroundTruthEdited =
  (state: RootState) =>
  (dcs: { bucket: string; folder?: string; name: string }) =>
    selectGroundTruthArea(state).userTracking.userEditingDicos.some(
      (d) =>
        d.bucket === dcs.bucket &&
        d.folder === dcs.folder &&
        d.name === dcs.name,
    )
