import { ReactElement, useState } from 'react'
import { Tag, Tags, TagsBox, TagsFilter } from './styles'
import { Search } from 'components/searchBar/searchBar'
import { useAppDispatch, useAppSelector } from 'infrastructure/hooks'
import { addTag, deleteTag } from './tagsActions'
import 'react-circular-progressbar/dist/styles.css'
import { Button } from 'components/button/styles'
import { Spinner } from 'components/spinner/spinner'

export const TagsView = (): ReactElement => {
  const dispatch = useAppDispatch()
  const [searchFilter, setSearchFilter] = useState('')
  const [newTag, setNewTag] = useState('')
  const { fetchState, upsertState } = useAppSelector((state) => state.tags)
  const tags = useAppSelector((state) => {
    return state.tags.tags.filter(
      (x) =>
        !searchFilter ||
        x.name.toLowerCase().indexOf(searchFilter.toLowerCase()) > -1,
    )
  })

  const addNewTag = () => {
    if (newTag) {
      dispatch(addTag(newTag))
      setNewTag('')
    }
  }

  return (
    <TagsBox>
      <TagsFilter>
        <Search placeholder="Search by name" onUpdate={setSearchFilter} />
      </TagsFilter>
      {fetchState.loading ? (
        <Spinner size="80px" />
      ) : (
        <Tags>
          {tags.map((tag, index) => (
            <Tag key={index}>
              <div className="info">
                <div>{tag.name}</div>
                <div>325</div>
              </div>
              <Button onClick={() => dispatch(deleteTag(tag.tagId))}>
                Delete
              </Button>
            </Tag>
          ))}
          <Tag key="add">
            {upsertState.loading ? (
              <Spinner size="50px" />
            ) : (
              <>
                <input
                  type="text"
                  value={newTag}
                  placeholder="new tag"
                  onChange={(e) => setNewTag(e.target.value)}
                />
                <Button onClick={addNewTag}>+</Button>
              </>
            )}
          </Tag>
        </Tags>
      )}
    </TagsBox>
  )
}
