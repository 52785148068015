import { ReactElement, useState } from 'react'
import { TabLabels, TabLabel, TabContent, Tabs } from './styles'

export interface ITab {
  label: string
  component: ReactElement
}

export interface IMultiTabsProps {
  tabs: ITab[]
}

export const MultiTabs = (props: IMultiTabsProps): ReactElement => {
  const { tabs } = props
  const [active, setActive] = useState(0)

  return (
    <Tabs>
      <TabLabels>
        {tabs.map((tab, index) => (
          <TabLabel
            key={`tab-${index}`}
            className={active === index ? 'active' : ''}
            onClick={() => setActive(index)}
          >
            {tab.label}
          </TabLabel>
        ))}
      </TabLabels>

      {tabs.map((tab, index) => (
        <TabContent
          key={`content-${index}`}
          className={active === index ? 'active' : ''}
          onClick={() => setActive(index)}
        >
          {tab.component}
        </TabContent>
      ))}
    </Tabs>
  )
}
