import { createSlice } from '@reduxjs/toolkit'
import { IApiState } from 'infrastructure/types/api'
import {
  ILabel,
  MapLabelResponseToModel,
  MapLabelsResponseToModels,
} from 'infrastructure/types/label'
import { toast } from 'react-toastify'
import { addLabel, deleteLabel, loadLabels } from './labelsActions'

interface ILabelsState {
  fetchState: IApiState
  upsertState: IApiState
  labels: ILabel[]
}

const initialState: ILabelsState = {
  fetchState: {
    loading: true,
    error: false,
  },
  upsertState: {
    loading: false,
    error: false,
  },
  labels: [],
}

const slice = createSlice({
  name: 'labels',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(loadLabels.pending, (state) => {
      state.fetchState.loading = true
      state.fetchState.error = false
    })
    builder.addCase(loadLabels.fulfilled, (state, action) => {
      state.fetchState.loading = false
      state.fetchState.error = false
      state.labels = MapLabelsResponseToModels(action.payload)
    })
    builder.addCase(loadLabels.rejected, (state) => {
      state.fetchState.loading = false
      state.fetchState.error = true
    })

    builder.addCase(addLabel.pending, (state) => {
      state.upsertState.loading = true
      state.upsertState.error = false
    })
    builder.addCase(addLabel.fulfilled, (state, action) => {
      state.upsertState.loading = false
      state.upsertState.error = false
      state.labels = [...state.labels, MapLabelResponseToModel(action.payload)]
    })
    builder.addCase(addLabel.rejected, (state) => {
      state.upsertState.loading = false
      state.upsertState.error = true
    })

    builder.addCase(deleteLabel.pending, (state) => {
      state.upsertState.loading = true
      state.upsertState.error = false
    })
    builder.addCase(deleteLabel.fulfilled, (state, action) => {
      state.upsertState.loading = false
      state.upsertState.error = false
      if (state.labels) {
        state.labels = state.labels.filter((x) => x.labelId !== action.payload)
      }
    })
    builder.addCase(deleteLabel.rejected, (state) => {
      state.upsertState.loading = false
      state.upsertState.error = true
    })
  },
})

export const { actions, reducer } = slice
