import { useNavigate, useLocation } from 'react-router-dom'
import { BreadcrumbBox, BreadcrumbLink, BreadcrumbText } from './styles'

export const Breadcrumbs = () => {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const pathnames = pathname.split('/').filter(Boolean)

  return (
    <>
      {!!pathnames.length && (
        <BreadcrumbBox>
          {pathnames.length ? (
            <div className="with-separator" key="home">
              <BreadcrumbLink onClick={() => navigate('/')}>
                Home
              </BreadcrumbLink>
              <div className="separator">&gt;</div>
            </div>
          ) : (
            <BreadcrumbText key="home">Home</BreadcrumbText>
          )}

          {pathnames.map((name, index) => {
            const routeTo = `/${pathnames.slice(0, index + 1).join('/')}`
            const isLast = index === pathnames.length - 1
            return isLast ? (
              <BreadcrumbText key={name}>{name}</BreadcrumbText>
            ) : (
              <div className="with-separator" key={name}>
                <BreadcrumbLink onClick={() => navigate(routeTo)}>
                  {name}
                </BreadcrumbLink>
                <div className="separator">&gt;</div>
              </div>
            )
          })}
        </BreadcrumbBox>
      )}
    </>
  )
}
