import { configureStore } from '@reduxjs/toolkit'
import { reducer as galleryReducer } from 'views/gallery/galleryReducer'
import { reducer as groundTruthReducer } from 'views/ground-truths/groundTruthReducer'
import { reducer as imageReducer } from 'views/image/imageReducer'
import { reducer as labelsReducer } from 'views/labels/labelsReducer'
import { reducer as tagsReducer } from 'views/tags/tagsReducer'
import { reducer as directoryReducer } from 'views/directory/directoryReducer'
import { reducer as directoryImportReducer } from 'views/directory/directoryImportReducer'
import { reducer as trainingReducer } from 'views/training/trainingReducer'
import { reducer as trainingRunReducer } from 'views/training/trainingRun/trainingRunReducer'

export const store = configureStore({
  reducer: {
    directory: directoryReducer,
    directoryImport: directoryImportReducer,
    labels: labelsReducer,
    tags: tagsReducer,
    gallery: galleryReducer,
    groundTruth: groundTruthReducer,
    image: imageReducer,
    training: trainingReducer,
    trainingRun: trainingRunReducer,
  },
})

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
