import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { IApiState } from 'infrastructure/types/api'
import {
  ITraining,
  MapTrainingResponsesToTraining,
} from 'infrastructure/types/training'
import { loadTrainings } from './trainingActions'

interface ITrainingState {
  fetchState: IApiState
  trainings: ITraining[]
  pageNumber: number
  pageSize: number
}

const initialState: ITrainingState = {
  fetchState: {
    loading: true,
    error: false,
  },
  trainings: [],
  pageNumber: 0,
  pageSize: 20,
}

const slice = createSlice({
  name: 'training',
  initialState,
  reducers: {
    initialise: () => {
      return initialState
    },
    setPageNumber: (state, action: PayloadAction<number>) => {
      state.pageNumber = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadTrainings.pending, (state) => {
      state.fetchState.loading = true
      state.fetchState.error = false
    })

    builder.addCase(loadTrainings.fulfilled, (state, action) => {
      state.fetchState.loading = false
      state.fetchState.error = false
      state.trainings = [
        ...state.trainings,
        ...MapTrainingResponsesToTraining(action.payload),
      ]
    })

    builder.addCase(loadTrainings.rejected, (state) => {
      state.fetchState.loading = false
      state.fetchState.error = true
    })
  },
})

export const { actions, reducer } = slice
