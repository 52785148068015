import styled from 'styled-components'
import { colours } from 'styles/colours'
import { spacing } from 'styles/spacing'

export const Tabs = styled.div`
  display: flex;
  flex-direction: column;
`

export const TabLabels = styled.div`
  display: flex;
  flex-direction: row;
`

export const TabLabel = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${colours.primary};
  cursor: pointer;
  padding: ${spacing.Small};
  width: 100%;

  &:not(:last-child) {
    border-right: 1px solid ${colours.disabled};
  }
  &:last-child {
    border-left: 1px solid ${colours.disabled};
  }

  border-bottom: 1px solid ${colours.disabled};

  &.active {
    border: none;
    color: ${colours.contrastDark};
    cursor: default;
  }
`

export const TabContent = styled.div`
  display: none;

  &.active {
    display: flex;
    padding: ${spacing.Small};
  }
`
