import { CreatableSelect } from '@atlaskit/select'
import styled from 'styled-components'
import { colours } from 'styles/colours'
import { spacing } from 'styles/spacing'
import { RemSize } from 'styles/utility'

export const DicosTableBox = styled.div`
  figure {
    margin: 0;
    padding: 0;
    border: 1px solid ${colours.disabled};
    cursor: pointer;
    min-width: ${RemSize(200)};
    max-width: ${RemSize(400)};
    background-color: transparent;

    img {
      display: block;
      width: 100%;
      height: auto;
    }
  }
`

export const Panel = styled.div<{ active: boolean }>`
  padding: ${spacing.Small};
  border: 1px solid ${colours.disabled};

  button {
    margin-bottom: 0;
    width: 100%;
  }

  margin-bottom: ${spacing.Medium};

  .toggle {
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    margin-bottom: ${(props) => (props.active ? spacing.Medium : '0')};
  }
`

export const Paths = styled.div`
  display: flex;
  gap: ${spacing.Small};
  margin-bottom: ${spacing.Small};

  input {
    flex: 1;
    padding: ${spacing.ExtraSmall};
  }

  textarea {
    flex: 1;
    padding: ${spacing.ExtraSmall};
  }
`
export const SearchSelectCreate = styled(CreatableSelect)`
  &.multi-select {
    margin-bottom: ${spacing.Small};
  }
`

export const DirectoryFilter = styled.div`
  border-bottom: 1px solid ${colours.disabled};
  margin-bottom: ${spacing.Medium};
  display: flex;
  gap: ${spacing.Small};
`
