import styled from 'styled-components'
import { colours } from 'styles/colours'
import { spacing } from 'styles/spacing'

export const LayoutWrapper = styled.div`
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
  background-color: ${colours.backdrop};
`

export const LayoutBox = styled.main`
  padding: ${spacing.Small};
  display: flex;
  flex-flow: column;
  flex: 1 1 auto;
`
