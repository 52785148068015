import { NavigationBox, NavigationItem } from './styles'
import {
  PhotographIcon,
  AcademicCapIcon,
  PencilAltIcon,
  TagIcon,
  FolderIcon,
  CubeIcon,
} from '@heroicons/react/outline'
import { useNavigate } from 'react-router-dom'
import { HasRequiredRole, routes } from 'infrastructure/routes'
import { useOidcUser } from '@axa-fr/react-oidc'
import { OidcUserInfo } from '@axa-fr/react-oidc/dist/vanilla/vanillaOidc'

export const Navigation = () => {
  const navigate = useNavigate()
  const { oidcUser } = useOidcUser<OidcUserInfo & { role: string[] }>()

  return (
    <NavigationBox>
      {oidcUser && HasRequiredRole(oidcUser.role, routes.Directory) && (
        <NavigationItem onClick={() => navigate(routes.Directory.path)}>
          <FolderIcon width={18} height={18} />
          <div>Directory</div>
        </NavigationItem>
      )}

      {oidcUser && HasRequiredRole(oidcUser.role, routes.Gallery) && (
        <NavigationItem onClick={() => navigate(routes.Gallery.path)}>
          <PhotographIcon width={18} height={18} />
          <div>Gallery</div>
        </NavigationItem>
      )}

      {oidcUser && HasRequiredRole(oidcUser.role, routes.Training) && (
        <NavigationItem onClick={() => navigate(routes.Training.path)}>
          <AcademicCapIcon width={18} height={18} />
          <div>Training</div>
        </NavigationItem>
      )}

      {oidcUser && HasRequiredRole(oidcUser.role, routes.Labels) && (
        <NavigationItem onClick={() => navigate(routes.Labels.path)}>
          <PencilAltIcon width={18} height={18} />
          <div>Labels</div>
        </NavigationItem>
      )}

      {oidcUser && HasRequiredRole(oidcUser.role, routes.Tags) && (
        <NavigationItem onClick={() => navigate(routes.Tags.path)}>
          <TagIcon width={18} height={18} />
          <div>Tags</div>
        </NavigationItem>
      )}

      {oidcUser && HasRequiredRole(oidcUser.role, routes.GroundTruthIndex) && (
        <NavigationItem onClick={() => navigate(routes.GroundTruthIndex.path)}>
          <CubeIcon width={18} height={18} />
          <div>Index</div>
        </NavigationItem>
      )}
    </NavigationBox>
  )
}
