import { FC, useEffect, useState } from 'react'
import {
  queryFilterOptions,
  queryIndex,
  renderImages,
} from './groundTruthActions'
import { useAppDispatch, useAppSelector } from 'infrastructure/hooks'
import { renderOptionsWithViews, useStateInQuery } from './util'
import {
  selectIsLoading,
  selectQueryFilterOptions,
  selectTrackedDicos,
} from './groundTruthSelectors'
import { Spinner } from 'components/spinner/spinner'
import {
  RenderGroup,
  RenderGroupFilters,
  TrackedDicos,
  TrackedDicosFilters,
  TrackedFilterOptions,
} from './models'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Renderers } from 'infrastructure/types/rendering'
import GroundTruthHubConnector from './groundTruthHubConnector'
import { Search } from 'components/searchBar/searchBar'
import { Button } from 'components/button/styles'
import { CheckboxSelect } from '@atlaskit/select'
import { CheckBox } from 'views/gallery/components/filter/styles'
import { BanIcon, CheckIcon, XIcon } from '@heroicons/react/outline'

const TinyButton = styled(Button)`
  min-width: 0;
  margin: 0;
  padding: 0.5rem;
`

const ListSelector = <T extends string>({
  value,
  options,
  onChange,
}: {
  value: T
  options: T[]
  onChange: (t: T) => void
}) => {
  return (
    <select
      value={value}
      onChange={(e) => onChange(e.target.value as T)}
      style={{ width: '100%' }}
    >
      {options.map((o, i) => (
        <option key={i} value={o}>
          {o}
        </option>
      ))}
    </select>
  )
}

const StringListFilter = <T extends string>({
  placeholder,
  allowedValues,
  selectedValues,
  onSelectedValuesChanged,
}: {
  placeholder: string
  allowedValues: T[]
  selectedValues: T[]
  onSelectedValuesChanged: (s: T[]) => void
}) => {
  return (
    <div title={selectedValues.join(', ')}>
      <CheckboxSelect
        placeholder={placeholder}
        options={allowedValues.map((v) => ({
          label: v,
          value: v,
        }))}
        isMulti
        isOptionSelected={(x) => selectedValues.includes(x.value as any as T)}
        onChange={(x) =>
          onSelectedValuesChanged(x.map((x) => x.value as any as T))
        }
      />
    </div>
  )
}

const FlexH = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: center;
`

const RenderGroupFilter: FC<{
  renderGroup: RenderGroupFilters
  onRenderGroupChanged: (r: RenderGroupFilters) => void
}> = ({ renderGroup, onRenderGroupChanged }) => (
  <div
    style={{
      padding: '10px',
    }}
  >
    <h3>
      {renderGroup.renderParametersName}-{renderGroup.viewCount}
    </h3>
    <div>Has renders:</div>
    <div style={{ width: '100%' }}>
      <ListSelector
        options={['none', 'true', 'false']}
        value={
          renderGroup.hasRenders == null
            ? 'none'
            : renderGroup.hasRenders
            ? 'true'
            : 'false'
        }
        onChange={(s) =>
          onRenderGroupChanged({
            ...renderGroup,
            hasRenders: s === 'true' ? true : s === 'false' ? false : undefined,
          })
        }
      />
    </div>
    Approval state:
    <div style={{ width: '100%' }}>
      <ListSelector
        options={['None', 'Pending', 'Approved', 'Rejected']}
        value={
          renderGroup.approvalState == null ? 'None' : renderGroup.approvalState
        }
        onChange={(s) =>
          s === 'None'
            ? onRenderGroupChanged({ ...renderGroup, approvalState: undefined })
            : onRenderGroupChanged({ ...renderGroup, approvalState: s })
        }
      />
    </div>
    <CheckBox>
      <input
        type="checkbox"
        checked={renderGroup.staleOnly}
        onChange={() =>
          onRenderGroupChanged({
            ...renderGroup,
            staleOnly: !renderGroup.staleOnly,
          })
        }
      />
      <label>Only stale views</label>
    </CheckBox>
  </div>
)

const GridMin150 = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
`

const RenderGroupsFilter: FC<{
  allowedRenderGroups: { renderParametersName: string; viewCount: number }[]
  renderGroups: RenderGroupFilters[]
  onRenderGroupsChanged: (r: RenderGroupFilters[]) => void
}> = ({ allowedRenderGroups, renderGroups, onRenderGroupsChanged }) => (
  <div>
    <StringListFilter
      placeholder="Render groups"
      allowedValues={allowedRenderGroups.map(
        (rg) => `${rg.renderParametersName} ${rg.viewCount}`,
      )}
      selectedValues={renderGroups.map(
        (rg) => `${rg.renderParametersName} ${rg.viewCount}`,
      )}
      onSelectedValuesChanged={(s) =>
        onRenderGroupsChanged(
          s.map((s) => {
            const [name, viewCount] = s.split(' ')
            const existing = renderGroups.find(
              (rg) =>
                rg.renderParametersName === name &&
                rg.viewCount === parseInt(viewCount),
            )!
            return {
              ...existing,
              renderParametersName: name,
              viewCount: parseInt(viewCount),
            }
          }),
        )
      }
    />

    <GridMin150>
      {allowedRenderGroups
        .map((rg) =>
          renderGroups.find(
            (r) =>
              r.renderParametersName === rg.renderParametersName &&
              r.viewCount === rg.viewCount,
          ),
        )
        .filter((q) => q != null)
        .map((rg) => rg as RenderGroupFilters)
        .map((rg, i) => (
          <RenderGroupFilter
            key={i}
            renderGroup={rg}
            onRenderGroupChanged={(rg) =>
              onRenderGroupsChanged(
                renderGroups.map((r) =>
                  r.renderParametersName === rg.renderParametersName &&
                  r.viewCount === rg.viewCount
                    ? rg
                    : r,
                ),
              )
            }
          />
        ))}
    </GridMin150>
  </div>
)

const DebouncedInput: FC<{
  text: string
  onTextChanged: (t: string) => void
}> = ({ text, onTextChanged }) => {
  const [debouncedText, setDebouncedText] = useState(text)
  useEffect(() => {
    const timeout = setTimeout(() => {
      setDebouncedText(debouncedText)
      onTextChanged(debouncedText)
    }, 500)
    return () => clearTimeout(timeout)
  }, [debouncedText])

  return (
    <input
      type="text"
      value={debouncedText}
      onChange={(e) => setDebouncedText(e.target.value)}
    />
  )
}

const filterDefault: TrackedDicosFilters = {
  omni: '',
  buckets: [],
  expectedItemIds: [],
  folders: [],
  legacyCsvFiles: [],
  groundTruths: [],
  approvalStates: [],
  renderGroups: [],
  groundTruthNotExpectedItemIds: false,
  noGroundTruths: false,
  skip: 0,
  take: 10,
}

const Filters: FC<{
  options: TrackedFilterOptions
  filters: TrackedDicosFilters
  onFiltersChanged: (t: TrackedDicosFilters) => void
}> = ({ options, filters, onFiltersChanged }) => {
  const [showFilters, setShowFilters] = useState(false)
  const [showExtended, setShowExtended] = useState(false)
  const onFiltersChangedEx = (t: TrackedDicosFilters) =>
    onFiltersChanged({ ...t, skip: 0 })
  return (
    <div>
      <Search
        placeholder="Search"
        value={filters.omni}
        onUpdate={(t) => onFiltersChangedEx({ ...filters, omni: t })}
      />
      {!showFilters ? (
        <Button onClick={() => setShowFilters(true)}>Show filters</Button>
      ) : (
        <FlexH>
          <StringListFilter
            placeholder="ItemIds"
            allowedValues={options.expectedItemIds}
            selectedValues={filters.expectedItemIds}
            onSelectedValuesChanged={(s) =>
              onFiltersChangedEx({ ...filters, expectedItemIds: s })
            }
          />
          <StringListFilter
            placeholder="3D Ground truths"
            allowedValues={options.groundTruths}
            selectedValues={filters.groundTruths}
            onSelectedValuesChanged={(s) =>
              onFiltersChangedEx({ ...filters, groundTruths: s })
            }
          />
          <CheckBox>
            <input
              type="checkbox"
              checked={filters.noGroundTruths}
              onChange={() =>
                onFiltersChangedEx({
                  ...filters,
                  noGroundTruths: !filters.noGroundTruths,
                })
              }
            />
            <label>With no ground truths</label>
          </CheckBox>
          <CheckBox>
            <input
              type="checkbox"
              checked={filters.groundTruthNotExpectedItemIds}
              onChange={() =>
                onFiltersChangedEx({
                  ...filters,
                  groundTruthNotExpectedItemIds:
                    !filters.groundTruthNotExpectedItemIds,
                })
              }
            />
            <label>Ground truths not matching expected</label>
          </CheckBox>
          <StringListFilter
            placeholder="Approval states"
            allowedValues={['Pending', 'Approved', 'Rejected']}
            selectedValues={filters.approvalStates}
            onSelectedValuesChanged={(s) =>
              onFiltersChangedEx({ ...filters, approvalStates: s })
            }
          />
          {!showExtended ? (
            <TinyButton onClick={() => setShowExtended(true)}>
              More...
            </TinyButton>
          ) : (
            <></>
          )}
          {showExtended ? (
            <>
              <StringListFilter
                placeholder="Buckets"
                allowedValues={options.buckets}
                selectedValues={filters.buckets}
                onSelectedValuesChanged={(s) =>
                  onFiltersChangedEx({ ...filters, buckets: s })
                }
              />
              <StringListFilter
                placeholder="Folders"
                allowedValues={options.folders}
                selectedValues={filters.folders}
                onSelectedValuesChanged={(s) =>
                  onFiltersChangedEx({ ...filters, folders: s })
                }
              />
              <StringListFilter
                placeholder="Legacy Source File"
                allowedValues={options.legacyCsvFiles}
                selectedValues={filters.legacyCsvFiles}
                onSelectedValuesChanged={(s) =>
                  onFiltersChangedEx({ ...filters, legacyCsvFiles: s })
                }
              />
            </>
          ) : (
            <></>
          )}
          <div
            style={{
              gridColumn: 'span 2',
            }}
          >
            <RenderGroupsFilter
              allowedRenderGroups={renderOptionsWithViews}
              renderGroups={filters.renderGroups}
              onRenderGroupsChanged={(r) =>
                onFiltersChangedEx({ ...filters, renderGroups: r })
              }
            />
          </div>
          <TinyButton onClick={() => onFiltersChanged(filterDefault)}>
            Reset
          </TinyButton>
          <TinyButton onClick={() => setShowFilters(false)}>Close</TinyButton>
        </FlexH>
      )}
    </div>
  )
}

const TableGrid = styled.div`
  display: grid;
  grid-template-columns: auto minmax(auto, 150px) minmax(auto, 150px) auto;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; // always 10 rows to reduce paging buttons bouncing around
  grid-column-gap: 10px;
  grid-row-gap: 20px;
  padding: 10px;
  align-items: center;
`

const ApprovalWidget: FC<{ state?: 'Approved' | 'Pending' | 'Rejected' }> = ({
  state,
}) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-around',
        backgroundColor:
          state === 'Approved'
            ? 'green'
            : state === 'Pending'
            ? 'orange'
            : 'red',
        color: 'white',
        padding: '5px 10px',
        borderRadius: '4px',
      }}
    >
      {state}
      <div style={{ width: '30px', height: '30px' }}>
        {state === 'Approved' ? (
          <CheckIcon />
        ) : state === 'Pending' ? (
          <BanIcon />
        ) : state === 'Rejected' ? (
          <XIcon />
        ) : (
          <></>
        )}
      </div>
    </div>
  )
}

const RGGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(120px, 1fr));
  grid-row-gap: 10px;
  grid-column-gap: 10px;
`
const RenderGroupWidget: FC<{
  group: RenderGroup
  bucket: string
  folder?: string
  name: string
  isUserEditing: boolean
  isRendering: boolean
  hasDiverged: boolean
  onRender: () => void
}> = ({
  group,
  bucket,
  folder,
  name,
  isUserEditing,
  isRendering,
  hasDiverged,
  onRender,
}) => {
  return (
    <RGGrid>
      {group.hasRenders ? (
        <>
          {isUserEditing ? 'Currently being edited' : <></>}
          <Link
            to={`/ground-truths/${bucket}/${name}/rendered-images?viewCount=${
              group.viewCount
            }&renderParametersName=${group.renderParametersName}${
              folder != null ? '&folderName=' + folder : ''
            }`}
          >
            {group.renderParametersName} {group.viewCount}
          </Link>
          {isRendering ? (
            <Spinner size="small" />
          ) : hasDiverged ? (
            <TinyButton
              onClick={onRender}
              style={{ backgroundColor: 'orange' }}
            >
              Stale: Rerender
            </TinyButton>
          ) : (
            <TinyButton onClick={onRender}>ReRender</TinyButton>
          )}
        </>
      ) : (
        <>
          {isRendering ? (
            <>
              <div></div>
              <Spinner size="small" />
            </>
          ) : (
            <>
              No renders for {group.renderParametersName} {group.viewCount}
              <TinyButton onClick={onRender}>Render</TinyButton>
            </>
          )}
        </>
      )}

      <ApprovalWidget state={group.approvalState} />
    </RGGrid>
  )
}

const Rows: FC<{
  rows: (Omit<TrackedDicos, 'indexed'> & {
    isUserEditing: boolean
    indexed?: {
      groundTruths: string[]
      renderGroups: (RenderGroup & {
        hasRenders?: boolean | undefined
        isRendering: boolean
        isUserEditing: boolean
      })[]
      gtChecksum: string
    }
  })[]
  renderGroupsSelected: RenderGroupFilters[]
  onRender: (
    bucketName: string,
    folderName: string,
    id: string,
    viewCount: number,
    renderParametersName: Renderers,
  ) => void
}> = ({ rows, renderGroupsSelected, onRender }) => {
  return (
    <TableGrid>
      <h3>File</h3>
      <h3>Item Ids</h3>
      <h3>3D Ground truths</h3>
      <h3>Renders</h3>
      {rows.map((r) => {
        const filtered =
          renderGroupsSelected.length > 0
            ? r.indexed?.renderGroups.filter((g) =>
                renderGroupsSelected.some(
                  (rg) =>
                    rg.renderParametersName === g.renderParametersName &&
                    rg.viewCount === g.viewCount,
                ),
              ) ?? []
            : r.indexed?.renderGroups.slice(0, 1) ?? []
        return (
          <>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <Link
                to={`/ground-truths/${r.bucket}/${r.name}?scaleFactor=1&${
                  r.folder != null ? 'folderName=' + r.folder : ''
                }&${r.expectedItemIds.map((e) => 'expected=' + e).join('&')}`}
              >
                {r.name}
              </Link>
              {r.isUserEditing ? 'User is editing' : <></>}
              <ApprovalWidget state={r.approvalState} />
            </div>
            <div>{r.expectedItemIds.join(',')}</div>
            <div>{r.indexed?.groundTruths.join(',') ?? ''}</div>
            <div
              style={{
                display: 'grid',
                gridRowGap: '5px',
              }}
            >
              {filtered.map((g, i) => (
                <RenderGroupWidget
                  key={i}
                  group={g}
                  bucket={r.bucket}
                  folder={r.folder}
                  name={r.name}
                  isUserEditing={g.isUserEditing}
                  isRendering={g.isRendering}
                  hasDiverged={g.gT3DChecksum !== r.indexed?.gtChecksum}
                  onRender={() =>
                    onRender(
                      r.bucket,
                      r.folder,
                      r.name,
                      g.viewCount,
                      g.renderParametersName as Renderers,
                    )
                  }
                />
              ))}
            </div>
          </>
        )
      })}
    </TableGrid>
  )
}

const Pager: FC<{
  skip: number
  take: number
  total: number
  onChanged: (skip: number, take: number) => void
}> = ({ skip, take, total, onChanged }) => {
  return (
    <div>
      <TinyButton onClick={() => onChanged(0, take)} disabled={skip === 0}>
        First
      </TinyButton>
      <TinyButton
        onClick={() => onChanged(skip - take * 50, take)}
        disabled={skip - take * 50 < 0}
      >
        {'<<<'}
      </TinyButton>
      <TinyButton
        onClick={() => onChanged(skip - take * 5, take)}
        disabled={skip - take * 5 < 0}
      >
        {'<<'}
      </TinyButton>
      <Button
        onClick={() => onChanged(skip - take, take)}
        disabled={skip === 0}
      >
        {'<'}
      </Button>
      <Button
        onClick={() => onChanged(skip + take, take)}
        disabled={skip + take >= total}
      >
        {'>'}
      </Button>
      <TinyButton
        onClick={() => onChanged(skip + take * 5, take)}
        disabled={skip + take * 5 >= total}
      >
        {'>>'}
      </TinyButton>
      <TinyButton
        onClick={() => onChanged(skip + take * 50, take)}
        disabled={skip + take * 50 >= total}
      >
        {'>>>'}
      </TinyButton>
      <TinyButton
        onClick={() => onChanged((Math.ceil(total / take) - 1) * take, take)}
        disabled={skip + take >= total}
      >
        Last
      </TinyButton>
    </div>
  )
}

const Summary: FC<{
  skip: number
  take: number
  total: number
}> = ({ skip, take, total }) => (
  <div>
    {skip + 1} - {skip + take} of {total}
  </div>
)

const CenteredH = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const GroundTruthIndexView: FC = () => {
  const dispatch = useAppDispatch()
  const [filters, setFilters] = useStateInQuery<TrackedDicosFilters>(
    'filters',
    filterDefault,
  )

  useEffect(() => {
    dispatch(queryFilterOptions(filters))
  }, [])
  useEffect(() => {
    dispatch(queryIndex(filters))
  }, [JSON.stringify(filters)])

  const trackedDicosRes = useAppSelector(selectTrackedDicos)
  const options = useAppSelector(selectQueryFilterOptions)
  const isLoading = useAppSelector(selectIsLoading)

  const render = (
    bucketName: string,
    folderName: string,
    id: string,
    viewCount: number,
    renderParametersName: Renderers,
  ) => {
    dispatch(
      renderImages({
        bucketName,
        folderName,
        id,
        viewCount,
        renderParametersName,
      }),
    )
  }

  return (
    <div>
      <GroundTruthHubConnector />
      <Filters
        options={options}
        filters={filters}
        onFiltersChanged={setFilters}
      />
      {isLoading ? <Spinner size="large" /> : <></>}
      <Rows
        rows={trackedDicosRes.items}
        renderGroupsSelected={filters.renderGroups}
        onRender={render}
      />
      <CenteredH>
        <Pager
          skip={filters.skip}
          take={filters.take}
          total={trackedDicosRes.count}
          onChanged={(skip, take) => {
            setFilters({ ...filters, skip, take })
          }}
        />
      </CenteredH>
      <CenteredH>
        <Summary
          skip={filters.skip}
          take={filters.take}
          total={trackedDicosRes.count}
        />
      </CenteredH>
    </div>
  )
}

export default GroundTruthIndexView
