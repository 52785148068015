import { SearchIcon } from '@heroicons/react/outline'
import { DebounceInput } from 'react-debounce-input'
import { colours } from 'styles/colours'
import { SearchBar } from './styles'

interface ISearchProps {
  placeholder: string
  value?: string
  onUpdate: (searchText: string) => void
}

export const Search = (props: ISearchProps) => {
  var { placeholder, value, onUpdate } = props

  return (
    <SearchBar>
      <SearchIcon width={18} height={18} color={colours.disabled} />
      <DebounceInput
        placeholder={placeholder}
        minLength={2}
        debounceTimeout={300}
        onChange={(event) => onUpdate(event.target.value)}
        value={value}
      />
    </SearchBar>
  )
}
