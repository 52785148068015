import {
  ITrainingResponse,
  ITrainingResultLabelReponse,
  ITrainingResultResponse,
} from 'infrastructure/api/response/training'

export enum TrainingStatus {
  Unknown = 'Unknown',
  TrainingStarted = 'TrainingStarted',
  TrainingComplete = 'TrainingComplete',
  TestingStarted = 'TestingStarted',
  TestingComplete = 'TestingComplete',
  Error = 'Error',
}

export const TrainingStatusMapper = new Map<TrainingStatus, string>([
  [TrainingStatus.Unknown, 'Unknown'],
  [TrainingStatus.TrainingStarted, 'Training Started'],
  [TrainingStatus.TrainingComplete, 'Training Complete'],
  [TrainingStatus.TestingStarted, 'Testing Started'],
  [TrainingStatus.TestingComplete, 'Testing Complete'],
  [TrainingStatus.Error, 'Error'],
])

export interface ITraining {
  id: string
  dateCreated: string
  dateTrainingCompleted: string
  dateTestingCompleted: string
  status: TrainingStatus
  result: ITrainingResult | null
}

export interface ITrainingResult {
  averagePrecision: number
  precision: number
  recall: number
  totalImages: number
  trainingImages: number
  validationImages: number
  testImages: number
  labels: ITrainingResultLabel[]
}

export interface ITrainingResultLabel {
  label: string
  averagePrecision: number
  precision: number
  recall: number
}

export const MapTrainingResultLabelsResponseToTrainingResultLabels = (
  response: ITrainingResultLabelReponse[],
): ITrainingResultLabel[] =>
  response.map((item) => ({
    label: item.label,
    averagePrecision: item.averagePrecision,
    precision: item.precision,
    recall: item.recall,
  }))

export const MapTrainingResultResponseToTrainingResult = (
  response: ITrainingResultResponse,
): ITrainingResult => ({
  averagePrecision: response.averagePrecision,
  precision: response.precision,
  recall: response.recall,
  totalImages: response.totalImages,
  trainingImages: response.trainingImages,
  validationImages: response.validationImages,
  testImages: response.testImages,
  labels: MapTrainingResultLabelsResponseToTrainingResultLabels(
    response.labels,
  ),
})

export const MapTrainingResponseToTraining = (
  response: ITrainingResponse,
): ITraining => ({
  id: response.id,
  dateCreated: response.dateCreated,
  dateTrainingCompleted: response.dateTrainingCompleted,
  dateTestingCompleted: response.dateTestingCompleted,
  status: response.status,
  result: response.result
    ? MapTrainingResultResponseToTrainingResult(response.result)
    : null,
})

export const MapTrainingResponsesToTraining = (
  response: ITrainingResponse[],
): ITraining[] => response.map((item) => MapTrainingResponseToTraining(item))
