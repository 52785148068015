/* eslint-disable react/prop-types */
import { useAppDispatch, useAppSelector } from 'infrastructure/hooks'
import { FC, ReactElement, useEffect, useState } from 'react'
import { useParams } from 'react-router'
import {
  approveGroundTruth,
  groundTruthBeginEditing,
  groundTruthEndEditing,
  loadGroundTruth,
  rejectGroundTruth,
  saveGroundTruth,
} from './groundTruthActions'
import { IDataPointModel } from 'infrastructure/types/rendering'
import { IGroundTruthData } from 'infrastructure/types/groundTruth'
import { endpoint } from 'infrastructure/api/endpoints'
import {
  selectFocusedGroundTruthEditAsInferences,
  selectedFocusedGroundTruthApprovals,
  selectedFocusedGroundTruthSummary,
} from './groundTruthSelectors'
import {
  Bounds3,
  ScanInference3D,
  toGroundTruthItem,
} from 'views/ground-truths/models'
import styled from 'styled-components'
import { ModelViewerOptions, actions } from './groundTruthReducer'
import { Button } from 'components/button/styles'
import { Spinner } from 'components/spinner/spinner'
import ModelViewerControls from './components/modelViewer/ModelViewerControls'
import Slider from './components/modelViewer/widgets/Slider'
import { useQuery, useQueryAll } from './util'
import ModelViewer from './components/modelViewer/ModelViewer'
import { useOidcUser } from '@axa-fr/react-oidc'
import { Roles } from 'infrastructure/auth'
import { OidcUserInfo } from '@axa-fr/react-oidc/dist/vanilla/vanillaOidc'
import ErrorBoundary from './components/ErrorBoundary'

const FlexDiv = styled.div({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
})

const FlexDivSB = styled(FlexDiv)({
  justifyContent: 'space-between',
})

const ButtonPL = styled.button`
  padding-left: 4px;
  padding-right: 4px;
`

const DecInc: React.FC<{ value: number; onChange: (x: number) => void }> = ({
  value,
  onChange,
}) => (
  <FlexDiv>
    <ButtonPL onClick={() => onChange(value - 50)}>---</ButtonPL>
    <ButtonPL onClick={() => onChange(value - 10)}>--</ButtonPL>
    <ButtonPL onClick={() => onChange(value - 1)}>-</ButtonPL>
    <ButtonPL onClick={() => onChange(value + 1)}>+</ButtonPL>
    <ButtonPL onClick={() => onChange(value + 10)}>++</ButtonPL>
    <ButtonPL onClick={() => onChange(value + 50)}>+++</ButtonPL>
  </FlexDiv>
)

const BBGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`

// Copied from https://pangiamuk.atlassian.net/wiki/spaces/DARTMOUTH/pages/148635688/Taxonomy
const possibleLabels = [
  'revolver_component_base',
  'revolver_cylinder',
  'revolver_magazine',
  'pistol',
  'pistol_component_barrel',
  'pistol_component_magazine',
  'pistol_plastic_handle',
  'rifle',
  'rifle_component',
  'rifle_component_barrel',
  'rifle_component_body',
  'rifle_component_magazine',
  'rifle_component_stock',
  'plastic_rifle_magazine',
  'shotgun',
  'bullet',
  'knife',
  'butterfly_knife',
  'butterfly_knife_open',
  'butter_knife',
  'folding_knife',
  'folding_knife_open',
  'hunting_knife',
  'kitchen_knife',
  'ceramic_knife',
  'letter_opener',
  'curved_knife',
  'cleaver',
  'serrated_knife',
  'sheathed_knife',
  'switch_knife',
  'knife',
  'machete',
  'opinel_knife',
  'retractable_knife',
  'scalpel',
  'cut_throat_razor',
  'cut_throat_razor_open',
  'stanley_knife',
  'stanley_knife_closed',
  'box_cutter',
  'hammer',
  'crowbar',
  'screwdriver',
  'axe_head',
  'pickaxe',
  'ice_pickaxe',
  'tactical_axe',
  'sword',
  'safety_scissors',
  'scissors',
  'scissors_open',
  'grenade',
  'grenade_fuze',
  'explosive',
  'pipe_bomb',
  'torch',
  'toy_gun',
  'dagger',
  'kamas',
  'revolver',
  'assault_rifle',

  // additional missing stuff
  'samurai_sword',
  'commercial_detonator',
  'tool',
  'metal_bar',
  'wood_splitter',
  'rifle_magazine',
  'toy_pistol',
  'improvised_detonator',
  'pistol_component_base',
  'axe',
  'ice_pick',
  'sling_shot',
].sort()

const Input = styled.input`
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 10px;
  font-size: 14px;
`

const Select = styled.select`
  border: 1px solid #ccc;
  border-radius: 6px;
  padding: 40px;
  font-size: 14px;
`

const ButtonGP2 = styled(Button)`
  grid-column: 2;
  min-width: 0;
`

const ButtonSmaller = styled(Button)`
  min-width: 5rem;
`

const LabelSelector: React.FC<{
  expectedLabels: string[]
  label: string
  onChange: (x: string) => void
}> = ({ label, expectedLabels, onChange }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const { oidcUser } = useOidcUser<OidcUserInfo & { role: string[] }>()
  const userHasLabellerAdmin = oidcUser?.role.includes(Roles.LabellerAdmin)
  const userCanEdit = userHasLabellerAdmin

  const allPossibleLabels = expectedLabels.concat(possibleLabels)

  if (allPossibleLabels.includes(label) && !isEditing)
    return (
      <>
        <Select value={label} onChange={(e) => onChange(e.target.value)}>
          {allPossibleLabels.map((x) => (
            <option key={x} value={x}>
              {x}
            </option>
          ))}
        </Select>
        {userCanEdit && (
          <ButtonGP2 onClick={() => setIsEditing(true)}>Edit</ButtonGP2>
        )}
      </>
    )

  return (
    <>
      <Input value={label} onChange={(e) => onChange(e.target.value)} />
      <ButtonGP2
        onClick={() => {
          setIsEditing(false)
          onChange(allPossibleLabels[0])
        }}
      >
        Reset
      </ButtonGP2>
    </>
  )
}

const asFlexCol = { display: 'flex', flexDirection: 'column' }

const FlexColSec = styled.div<{
  gridCol1?: boolean
  backgroundColor: 'red' | 'green' | 'blue'
}>`
  display: flex;
  flex-direction: column;
  background-color: ${(props) => props.backgroundColor};
  ${(props) => (props.gridCol1 ? 'grid-column: 1;' : '')}
`

const BoundingBox: React.FC<{
  expectedLabels: string[]
  value: ScanInference3D
  onChange: (x: ScanInference3D) => void
  volumeBounds: Bounds3
}> = ({ expectedLabels, value, onChange, volumeBounds }) => (
  <BBGrid>
    <LabelSelector
      expectedLabels={expectedLabels}
      label={value.label}
      onChange={(x) => onChange({ ...value, label: x })}
    />
    <FlexColSec gridCol1 backgroundColor="red">
      x1
      <input
        value={value.bounds.x1}
        onChange={(e) =>
          onChange({
            ...value,
            bounds: { ...value.bounds, x1: parseFloat(e.target.value) },
          })
        }
      />
      <DecInc
        value={value.bounds.x1}
        onChange={(next) =>
          onChange({ ...value, bounds: { ...value.bounds, x1: next } })
        }
      />
      <Slider
        value={value.bounds.x1}
        onChange={(next) =>
          onChange({ ...value, bounds: { ...value.bounds, x1: next } })
        }
        min={volumeBounds.x1}
        max={volumeBounds.x2}
      />
    </FlexColSec>

    <FlexColSec backgroundColor="red">
      x2
      <input
        value={value.bounds.x2}
        onChange={(e) =>
          onChange({
            ...value,
            bounds: { ...value.bounds, x2: parseFloat(e.target.value) },
          })
        }
      />
      <DecInc
        value={value.bounds.x2}
        onChange={(next) =>
          onChange({ ...value, bounds: { ...value.bounds, x2: next } })
        }
      />
      <Slider
        value={value.bounds.x2}
        onChange={(next) =>
          onChange({ ...value, bounds: { ...value.bounds, x2: next } })
        }
        min={volumeBounds.x1}
        max={volumeBounds.x2}
      />
    </FlexColSec>

    <FlexColSec gridCol1 backgroundColor="green">
      y1
      <input
        value={value.bounds.y1}
        onChange={(e) =>
          onChange({
            ...value,
            bounds: { ...value.bounds, y1: parseFloat(e.target.value) },
          })
        }
      />
      <DecInc
        value={value.bounds.y1}
        onChange={(next) =>
          onChange({ ...value, bounds: { ...value.bounds, y1: next } })
        }
      />
      <Slider
        value={value.bounds.y1}
        onChange={(next) =>
          onChange({ ...value, bounds: { ...value.bounds, y1: next } })
        }
        min={volumeBounds.y1}
        max={volumeBounds.y2}
      />
    </FlexColSec>

    <FlexColSec backgroundColor="green">
      y2
      <input
        value={value.bounds.y2}
        onChange={(e) =>
          onChange({
            ...value,
            bounds: { ...value.bounds, y2: parseFloat(e.target.value) },
          })
        }
      />
      <DecInc
        value={value.bounds.y2}
        onChange={(next) =>
          onChange({ ...value, bounds: { ...value.bounds, y2: next } })
        }
      />
      <Slider
        value={value.bounds.y2}
        onChange={(next) =>
          onChange({ ...value, bounds: { ...value.bounds, y2: next } })
        }
        min={volumeBounds.y1}
        max={volumeBounds.y2}
      />
    </FlexColSec>

    <FlexColSec gridCol1 backgroundColor="blue">
      z1
      <input
        value={value.bounds.z1}
        onChange={(e) =>
          onChange({
            ...value,
            bounds: { ...value.bounds, z1: parseFloat(e.target.value) },
          })
        }
      />
      <DecInc
        value={value.bounds.z1}
        onChange={(next) =>
          onChange({ ...value, bounds: { ...value.bounds, z1: next } })
        }
      />
      <Slider
        value={value.bounds.z1}
        onChange={(next) =>
          onChange({ ...value, bounds: { ...value.bounds, z1: next } })
        }
        min={volumeBounds.z1}
        max={volumeBounds.z2}
      />
    </FlexColSec>

    <FlexColSec backgroundColor="blue">
      z2
      <input
        value={value.bounds.z2}
        onChange={(e) =>
          onChange({
            ...value,
            bounds: { ...value.bounds, z2: parseFloat(e.target.value) },
          })
        }
      />
      <DecInc
        value={value.bounds.z2}
        onChange={(next) =>
          onChange({ ...value, bounds: { ...value.bounds, z2: next } })
        }
      />
      <Slider
        value={value.bounds.z2}
        onChange={(next) =>
          onChange({ ...value, bounds: { ...value.bounds, z2: next } })
        }
        min={volumeBounds.z1}
        max={volumeBounds.z2}
      />
    </FlexColSec>
  </BBGrid>
)

const Grid1 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  flex-direction: column;
  grid-column-gap: 10px;
  grid-row-gap: 10px;
`
const GridStretch = styled.div`
  grid-column: 1 / span 3;
`
const TopSectionWithBoundingBoxControls = styled.div`
  grid-column: 1 / span 3;
  min-height: 380px;
`

const GridCell1 = styled.div<{
  focusedIdx: number
  idx: number
  warning?: boolean
}>`
  background-color: ${(props) =>
    props.focusedIdx == props.idx
      ? 'lightblue'
      : props.warning
      ? 'red'
      : 'white'};
  border: 1px solid black;
  cursor: pointer;
`
const FR = styled.div`
  float: right;
  :hover {
    background-color: red;
  }
`
const Small = styled.div`
  font-size: 7px;
`

const BoundingBoxes: React.FC<{
  value: ScanInference3D[]
  volumeBounds: Bounds3
  canUndo: boolean
  canRedo: boolean
  canSave: boolean
  canApprove: boolean
  onChange: (x: ScanInference3D[]) => void
  onSave: () => void
  onUndo: () => void
  onRedo: () => void
  focusedIdx: number
  setFocusedIdx: (x: number) => void
  expectedLabels?: string[]
}> = ({
  value,
  volumeBounds,
  canUndo,
  canRedo,
  canSave,
  canApprove,
  onChange,
  onSave,
  onUndo,
  onRedo,
  focusedIdx,
  setFocusedIdx,
  expectedLabels = [],
}) => {
  const focused: ScanInference3D | undefined = value[focusedIdx]
  const labels = value.map((x) => x.label)
  const legacyLabelsNotAdded = expectedLabels.filter((x) => !labels.includes(x))
  const labelsNotInLegacyCollection = labels.filter(
    (x) => !expectedLabels.includes(x),
  )
  return (
    <Grid1>
      <TopSectionWithBoundingBoxControls>
        {focused != undefined && (
          <BoundingBox
            key={focusedIdx}
            value={focused}
            expectedLabels={expectedLabels}
            onChange={(next) =>
              onChange(value.map((v, i) => (i == focusedIdx ? next : v)))
            }
            volumeBounds={volumeBounds}
          />
        )}
        {focused == undefined && <Pad>No bounding box selected</Pad>}
      </TopSectionWithBoundingBoxControls>
      {value.map((v, idx) => (
        <GridCell1
          key={`${idx}`}
          focusedIdx={focusedIdx}
          idx={idx}
          onClick={() => setFocusedIdx(idx)}
          style={{
            backgroundColor:
              expectedLabels.length > 0 && !expectedLabels.includes(v.label)
                ? 'red'
                : undefined,
          }}
        >
          <Pad>
            <FR onClick={() => onChange(value.filter((x, i) => i != idx))}>
              X
            </FR>
            {v.label}
          </Pad>
        </GridCell1>
      ))}
      {legacyLabelsNotAdded.length > 0 &&
        legacyLabelsNotAdded.map((x) => (
          <GridCell1
            key={`${x}`}
            warning
            focusedIdx={focusedIdx}
            idx={value.length}
            onClick={() => {
              // confirm built in browser dialog?

              onChange(
                value.concat({
                  label: x,
                  bounds: volumeBounds,
                }),
              )
              setFocusedIdx(value.length)
            }}
          >
            <Pad>
              {x}
              <Small>Legacy label</Small>
            </Pad>
          </GridCell1>
        ))}
      <GridStretch>
        <FlexDivSB>
          <ButtonSmaller
            onClick={() => {
              onChange([
                ...value,
                {
                  label: 'knife',
                  bounds: volumeBounds,
                },
              ])
              setFocusedIdx(value.length + 1)
            }}
          >
            Add
          </ButtonSmaller>
          <ButtonSmaller
            onClick={() => (canUndo ? onUndo() : {})}
            disabled={!canUndo}
          >
            Undo
          </ButtonSmaller>
          <ButtonSmaller
            onClick={() => (canRedo ? onRedo() : {})}
            disabled={!canRedo}
          >
            Redo
          </ButtonSmaller>
          <ButtonSmaller
            onClick={() => (canSave ? onSave() : {})}
            disabled={!canSave}
          >
            Save
          </ButtonSmaller>
        </FlexDivSB>
        {expectedLabels.length > 0 &&
        (labelsNotInLegacyCollection.length > 0 ||
          legacyLabelsNotAdded.length > 0) ? (
          <p style={{ color: 'red' }}>
            Ground truths do not line up with legacy ground truths for{' '}
            {expectedLabels.reduce((a, b) => a + ', ' + b)}
          </p>
        ) : null}
      </GridStretch>
    </Grid1>
  )
}

const LHControlPanel = styled.div`
  padding: 10px;
  z-index: 1;
  height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const Grid2_50 = styled.div`
  display: grid;
  grid-template-columns: 400px auto;
`

const Pad = styled.div`
  padding: 10px;
`

const KeyDownListener: FC<{ onUndo: () => void; onRedo: () => void }> = ({
  onUndo,
  onRedo,
}) => {
  useEffect(() => {
    const listener = (e: KeyboardEvent) => {
      if (e.ctrlKey && e.key === 'z') onUndo()
      if (e.ctrlKey && e.key === 'y') onRedo()
    }
    document.addEventListener('keydown', listener)
    return () => document.removeEventListener('keydown', listener)
  }, [])
  return null
}

const Pad2 = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const ApproveMsg = styled.div`
  color: #00ff00;
`
const RejectMsg = styled.div`
  color: red;
`
const NotApprovedMsg = styled.div`
  color: orange;
`

const Approvals: FC<{
  approvals: IGroundTruthData['approvals']
  canApprove: boolean
  onApprove: () => void
  onReject: () => void
}> = ({ approvals, canApprove, onApprove, onReject }) => {
  const { oidcUser } = useOidcUser<OidcUserInfo & { role: string[] }>()
  const userHasLabellerAdmin = oidcUser?.role.includes(Roles.LabellerAdmin)
  const userHasLabeller = oidcUser?.role.includes(Roles.Labeller)

  const approvedLabelAdmin = approvals.find(
    (q) =>
      q.roles.some((r) => r === Roles.LabellerAdmin) && q.state === 'Approved',
  )
  const rejectedLabelAdmin = approvals.find(
    (q) =>
      q.roles.some((r) => r === Roles.LabellerAdmin) && q.state === 'Rejected',
  )
  const isApprovedByLabelAdmin = approvedLabelAdmin != null
  const isRejctedByLabelAdmin = rejectedLabelAdmin != null
  const approvedLADetailString = `${
    approvedLabelAdmin?.userId === oidcUser?.sub
      ? 'You'
      : approvedLabelAdmin?.userId
  } ${approvedLabelAdmin?.date}`
  const approvedLabeler = approvals.find((q) =>
    q.roles.some((r) => r === Roles.Labeller),
  )
  const isApprovedByLabeler = approvedLabeler != null
  const approvedLDetailString = `${
    approvedLabeler?.userId === oidcUser?.sub ? 'You' : approvedLabeler?.userId
  } ${approvedLabeler?.date}`

  const userHasAlreadyApproved = approvals.some(
    (q) => q.userId === oidcUser?.sub,
  )
  const canApproveAll = canApprove && (userHasLabellerAdmin || userHasLabeller)

  return (
    <Pad2>
      <div>
        {isRejctedByLabelAdmin ? (
          <RejectMsg title={approvedLADetailString}>
            Rejected by label admin
          </RejectMsg>
        ) : isApprovedByLabelAdmin ? (
          <ApproveMsg title={approvedLADetailString}>
            Approved by label admin
          </ApproveMsg>
        ) : (
          <NotApprovedMsg>Not approved by label admin</NotApprovedMsg>
        )}
        {isApprovedByLabeler ? (
          <ApproveMsg title={approvedLDetailString}>
            Approved by labeler
          </ApproveMsg>
        ) : (
          <NotApprovedMsg>Not approved by labeler</NotApprovedMsg>
        )}
      </div>
      <ButtonSmaller disabled={!canApproveAll} onClick={onReject}>
        Reject
      </ButtonSmaller>
      <ButtonSmaller disabled={!canApproveAll} onClick={onApprove}>
        {userHasAlreadyApproved ? 'Re-approve' : 'Approve'}
      </ButtonSmaller>
    </Pad2>
  )
}

export const GroundTruthView = (): ReactElement => {
  const summary = useAppSelector(selectedFocusedGroundTruthSummary)
  const boundingBoxes = useAppSelector(selectFocusedGroundTruthEditAsInferences)
  const approvals = useAppSelector(selectedFocusedGroundTruthApprovals)
  const { isSaved, isSaving, isModified, isLoading, canUndo, canRedo } =
    useAppSelector((x) => ({
      isSaved: x.groundTruth?.focusedFile?.saveState === 'saved',
      isSaving: x.groundTruth?.focusedFile?.saveState === 'is-saving',
      isModified: x.groundTruth?.focusedFile?.saveState === 'is-modified',
      isLoading: x.groundTruth.fetchState.loading,
      canUndo: (x.groundTruth?.focusedFile?.undoSteps.length ?? 0) > 0,
      canRedo: (x.groundTruth?.focusedFile?.redoSteps.length ?? 0) > 0,
    }))
  const dispatch = useAppDispatch()
  const setBoundingBoxes = (x: ScanInference3D[]) => {
    dispatch(actions.editSetGroundTruths(x.map(toGroundTruthItem)))
  }

  const undo = () => dispatch(actions.editUndo())
  const redo = () => dispatch(actions.editRedo())
  const { bucketName, id } = useParams()
  const folderName = useQuery('folderName') ?? ''
  const scaleFactor = useQuery('scaleFactor')
  const expectedItemIds = useQueryAll('expected')
  const save = () => {
    if (bucketName == null || id == null) return
    dispatch(
      saveGroundTruth({
        bucketName,
        folderName,
        id,
        data: {
          groundTruth: boundingBoxes.map(toGroundTruthItem),
        },
      }),
    ).then(() => dispatch(loadGroundTruth({ bucketName, folderName, id })))
  }
  const approve = () => {
    if (bucketName == null || id == null) return
    dispatch(
      approveGroundTruth({
        bucketName,
        folderName,
        id,
      }),
    ).then(() => dispatch(loadGroundTruth({ bucketName, folderName, id })))
  }
  const reject = () => {
    if (bucketName == null || id == null) return
    dispatch(
      rejectGroundTruth({
        bucketName,
        folderName,
        id,
      }),
    ).then(() => dispatch(loadGroundTruth({ bucketName, folderName, id })))
  }
  const scaleFactorInt = parseInt(scaleFactor ?? '1')

  const [dpm, setDpm] = useState<IDataPointModel | null | 'loading' | 'error'>(
    null,
  )
  const [focusedBoundingBoxIdx, setFocusedBoundingBoxIdx] = useState<number>(0)
  const options = useAppSelector((x) => x.groundTruth.modelViewerOptions)
  const setOptions = (options: Partial<ModelViewerOptions>) =>
    dispatch(actions.editSetModelViewerOptions(options))
  const resetOptions = () => dispatch(actions.editResetModelViewerOptions())

  useEffect(() => {
    if (bucketName == null || id == null) return

    dispatch(
      groundTruthBeginEditing({
        bucketName,
        folderName,
        id,
      }),
    )

    const handleBeforeUnload = () => {
      dispatch(
        groundTruthEndEditing({
          bucketName,
          folderName,
          id,
        }),
      )
    }

    // This is required to deal with the user closing tab scenario
    window.addEventListener('beforeunload', handleBeforeUnload)
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
      handleBeforeUnload()
    }
  }, [bucketName, id])

  useEffect(() => {
    if (bucketName != null && id != null && dpm == null) {
      setDpm('loading')
      try {
        endpoint
          .getGroundTruthRaw(bucketName, folderName, id, scaleFactorInt)
          .then(setDpm)
          .catch((e) => {
            setDpm('error')
          })
      } catch (e) {
        setDpm('error')
      }
    }
  }, [scaleFactorInt])
  useEffect(() => {
    if (id != null) dispatch(actions.setFocusedFile(id))
    if (bucketName != null && id != null)
      dispatch(loadGroundTruth({ bucketName, folderName, id }))
  }, [])
  useEffect(() => {
    if (isSaved) {
      dispatch(actions.resetFocusedFileSavedState())
    }
  }, [isSaved])

  if (isLoading || isSaving || dpm === 'loading') return <Spinner size="40px" />
  if (dpm === 'error') return <div>Error</div>
  if (dpm == null) return <div>No data</div>

  const volumeBounds: Bounds3 = {
    x1: 0,
    x2: (dpm.metadata.width ?? 0) * scaleFactorInt,
    y1: 0,
    y2: (dpm.metadata.height ?? 0) * scaleFactorInt,
    z1: 0,
    z2: (dpm.slices.count ?? 0) * scaleFactorInt,
  }

  return (
    <>
      <Grid2_50>
        <LHControlPanel>
          <BoundingBoxes
            value={boundingBoxes}
            volumeBounds={volumeBounds}
            canUndo={canUndo}
            canRedo={canRedo}
            canSave={isModified}
            canApprove={!isModified}
            onChange={setBoundingBoxes}
            onSave={() => save()}
            onUndo={() => undo()}
            onRedo={() => redo()}
            focusedIdx={focusedBoundingBoxIdx}
            setFocusedIdx={setFocusedBoundingBoxIdx}
            expectedLabels={expectedItemIds}
          />
          <Approvals
            approvals={approvals ?? []}
            canApprove={!isModified}
            onApprove={() => approve()}
            onReject={() => reject()}
          />
          <ModelViewerControls
            options={options}
            onOptionsChanged={setOptions}
            onReset={resetOptions}
          />
        </LHControlPanel>
        <ErrorBoundary>
          <ModelViewer
            conclusions={boundingBoxes}
            selectedConclusion={focusedBoundingBoxIdx}
            model={dpm}
            onSelected={(idx) => setFocusedBoundingBoxIdx(idx)}
            onBoundsChanged={(idx, bounds) =>
              setBoundingBoxes(
                boundingBoxes.map((v, i) => (i == idx ? { ...v, bounds } : v)),
              )
            }
            scanId="123"
            scaleFactor={scaleFactorInt}
            displayOptions={options}
          />
        </ErrorBoundary>
      </Grid2_50>
      <KeyDownListener onUndo={undo} onRedo={redo} />
    </>
  )
}
