import styled from 'styled-components'
import { colours } from 'styles/colours'
import { spacing } from 'styles/spacing'
import { RemSize } from 'styles/utility'

export const SearchBarBox = styled.div``

export const SearchBar = styled.div`
  border: 1px solid ${colours.disabled};
  background-color: ${colours.backdrop};
  width: ${RemSize(400)};
  display: flex;
  align-items: center;
  padding: ${spacing.Small};
  gap: ${spacing.Small};

  input {
    background-color: transparent;
    border: none;
    width: 100%;
  }

  input::placeholder {
    color: ${colours.disabled};
  }
`

export const SearchResults = styled.div`
  background-color: ${colours.contrastLight};
  border: 1px solid ${colours.disabled};
  padding: ${spacing.ExtraSmall};

  display: flex;
  flex-direction: column;
  gap: ${spacing.Small};
  border-top: none;

  .result {
    cursor: pointer;
  }
`
