import { createSlice } from '@reduxjs/toolkit'
import { IApiState } from 'infrastructure/types/api'
import {
  ITraining,
  MapTrainingResponseToTraining,
} from 'infrastructure/types/training'
import { loadTrainingRun } from './trainingRunActions'

interface ITrainingRunState {
  fetchState: IApiState
  trainingRun: ITraining | null
}

const initialState: ITrainingRunState = {
  fetchState: {
    loading: true,
    error: false,
  },
  trainingRun: null,
}

const slice = createSlice({
  name: 'trainingRun',
  initialState,
  reducers: {
    initialise: () => {
      return initialState
    },
  },
  extraReducers: (builder) => {
    builder.addCase(loadTrainingRun.pending, (state) => {
      state.fetchState.loading = true
      state.fetchState.error = false
    })

    builder.addCase(loadTrainingRun.fulfilled, (state, action) => {
      state.fetchState.loading = false
      state.fetchState.error = false
      state.trainingRun = MapTrainingResponseToTraining(action.payload)
    })

    builder.addCase(loadTrainingRun.rejected, (state) => {
      state.fetchState.loading = false
      state.fetchState.error = true
    })
  },
})

export const { actions, reducer } = slice
