import {
  IColorSpace,
  IColorTransferFunctionResponse,
  IPiecewiseTransferFunctionResponse,
  IRenderingParametersResponse,
  IRenderingPropertiesResponse,
} from 'infrastructure/api/response/rendering'
import { decode, Arr } from 'messagepack'

export interface IMetaDataModel {
  width: number
  height: number
  rescaleIntercept: number
  rescaleSlope: number
  pixelWidth: number
  pixelHeight: number
  pixelDepth: number
}

export interface ISlices {
  data: ArrayBuffer
  count: number
}

export interface IDataPointModel {
  metadata: IMetaDataModel
  slices: ISlices
}

export const MapArrayBufferResponsesToDataPointModel = (
  buffer: ArrayBuffer,
): IDataPointModel => {
  const rootRaw = decode(buffer, Arr)
  const meta = rootRaw[1]
  var metaRaw = meta
  const width = metaRaw[0] as number
  const height = metaRaw[1] as number
  const rescaleIntercept = metaRaw[2] as number
  const rescaleSlope = metaRaw[3] as number
  const pixelWidth = metaRaw[4] as number
  const pixelHeight = metaRaw[5] as number
  const pixelDepth = metaRaw[6] as number
  const slices = rootRaw[2]
  var slicesRaw = slices
  const count = slicesRaw[0] as number
  const data = slicesRaw[1] as ArrayBuffer
  return {
    metadata: {
      width,
      height,
      rescaleIntercept,
      rescaleSlope,
      pixelWidth,
      pixelHeight,
      pixelDepth,
    },
    slices: {
      count,
      data,
    },
  }
}

interface IPiecewiseTransferFunctionPoint {
  x: number
  y: number
}

interface IPiecewiseTransferFunction {
  enableClamping: boolean
  points: IPiecewiseTransferFunctionPoint[]
}

interface IColorTransferFunctionPoint {
  x: number
  r: number
  g: number
  b: number
}

interface IColorTransferFunction {
  enableClamping: boolean
  points: IColorTransferFunctionPoint[]
}

interface IRenderingParameters {
  interpolationType: 'Linear' | 'NearestNeighbour'
  shadingEnabled: boolean
  diffuseReflection: number
  ambientReflection: number
  specularReflection: number
  specularReflectionPower: number
  scalarOpacityFunction: IPiecewiseTransferFunction
  colorTransferFunction: IColorTransferFunction
  gradientTransferFunction: IPiecewiseTransferFunction
}

export interface IRenderingProperties {
  name: string
  parameters: IRenderingParameters
}

const MapColorSpaceAsRgb = (
  x: number,
  colorSpace: IColorSpace,
): IColorTransferFunctionPoint => ({
  x: x,
  r: colorSpace.r,
  g: colorSpace.g,
  b: colorSpace.b,
})

const MapColorTransferFunctionResponseToColorTransferFunction = (
  response: IColorTransferFunctionResponse,
): IColorTransferFunction => ({
  enableClamping: response.enableClamping,
  points: response.points.map((x) =>
    MapColorSpaceAsRgb(x[0] as number, x[1] as IColorSpace),
  ),
})

const MapPiecewiseTransferFunctionResponseToPiecewiseTransferFunction = (
  response: IPiecewiseTransferFunctionResponse,
): IPiecewiseTransferFunction => ({
  enableClamping: response.enableClamping,
  points: response.points.map((x) => ({ x: x[0], y: x[1] })),
})

const MapRenderingParametersResponseToRenderingParameters = (
  response: IRenderingParametersResponse,
): IRenderingParameters => ({
  interpolationType: response.interpolationType,
  shadingEnabled: response.shadingEnabled,
  diffuseReflection: response.diffuseReflection,
  ambientReflection: response.ambientReflection,
  specularReflection: response.specularReflection,
  specularReflectionPower: response.specularReflectionPower,
  gradientTransferFunction:
    MapPiecewiseTransferFunctionResponseToPiecewiseTransferFunction(
      response.gradientTransferFunction,
    ),
  scalarOpacityFunction:
    MapPiecewiseTransferFunctionResponseToPiecewiseTransferFunction(
      response.scalarOpacityFunction,
    ),
  colorTransferFunction:
    MapColorTransferFunctionResponseToColorTransferFunction(
      response.colorTransferFunction,
    ),
})

export const MapRenderingPropertiesResponseToRenderingProperties = (
  response: IRenderingPropertiesResponse[],
): IRenderingProperties[] =>
  response.map((item) => ({
    name: item.name,
    parameters: MapRenderingParametersResponseToRenderingParameters(
      item.parameters,
    ),
  }))

export type Renderers =
  | 'blue-red'
  | 'plastic'
  | 'detonators-v1'
  | 'detonators-v2'
// TODO: DRT-1007 Keep rendering consistent with eagle
export const renderOptions = [
  { label: 'blue-red', value: 'blue-red' },
  { label: 'plastic', value: 'plastic' },
  { label: 'detonators-v1', value: 'detonators-v1' },
  { label: 'detonators-v2', value: 'detonators-v2' },
] as const

export const asValidRenderer = (x: string): x is Renderers =>
  renderOptions.some((y) => y.value === x)
