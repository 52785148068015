import styled from 'styled-components'
import { colours } from 'styles/colours'
import { spacing } from 'styles/spacing'

export const BreadcrumbBox = styled.nav`
  background-color: ${colours.contrastLight};
  padding: ${spacing.ExtraSmall};
  font-size: 12px;
  margin-bottom: ${spacing.Small};
  display: flex;
  gap: ${spacing.ExtraSmall};

  .with-separator {
    display: flex;
    gap: ${spacing.ExtraSmall};
    .separator {
      color: ${colours.disabled};
    }
  }
`

export const BreadcrumbLink = styled.a`
  color: ${colours.primary};
  text-decoration: none;
  cursor: pointer;
  text-transform: capitalize;
`

export const BreadcrumbText = styled.div`
  color: ${colours.disabled};
  text-transform: capitalize;
`
