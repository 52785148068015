import styled from 'styled-components'
import { colours } from 'styles/colours'
import { spacing } from 'styles/spacing'
import { RemSize } from 'styles/utility'

export const GalleryFilter = styled.div`
  border-bottom: 1px solid ${colours.disabled};
  margin-bottom: ${spacing.Medium};
  display: flex;
  justify-content: space-between;
  font-size: 1rem;
  gap: ${spacing.Large};
  flex-wrap: wrap;

  z-index: 2;

  .filter-control-container {
    display: flex;
    position: relative;
    gap: ${spacing.Small};
    flex-wrap: wrap;

    .filter-control {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .filter-text {
        display: flex;
        gap: ${spacing.ExtraSmall};
        align-items: center;
      }

      .filter-count {
        padding: ${spacing.Tiny};
        background-color: ${colours.contrastLight};
        color: ${colours.primary};
        width: 24px;
        border-radius: 12px;
        text-align: center;
      }
    }
  }
`

export const FilterDropDown = styled.div`
  position: absolute;
  display: flex;
  background-color: ${colours.contrastLight};
  padding: ${spacing.Medium};
  top: 100%;
  border: 1px solid ${colours.disabled};
  z-index: 2;
  flex-direction: column;
  gap: ${spacing.Small};

  .filters {
    display: flex;
    gap: ${spacing.ExtraSmall};
    max-width: ${RemSize(400)};
    flex-wrap: wrap;
  }
`

export const FilterMask = styled.div`
  z-index: 1;
  position: fixed;
  display: block;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${colours.contrastDark};
  opacity: 0.25;
`

export const CheckBox = styled.div`
  display: flex;
  align-items: center;
  label {
    display: flex;
    align-items: center;
  }
  input[type='checkbox'] {
    margin-right: ${spacing.ExtraSmall};
    width: 1.15em;
    height: 1.15em;
  }
`
