import { useAppDispatch, useAppSelector } from 'infrastructure/hooks'
import { ReactElement, useEffect, useState } from 'react'
import { loadImports } from '../directoryActions'
import { actions } from '../directoryImportReducer'
import { Spinner } from 'components/spinner/spinner'
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/outline'
import { colours } from 'styles/colours'
import { IDicosImport, ImportStatusMapper } from 'infrastructure/types/import'
import { PagingButton } from 'components/button/styles'
import { ExpandableTable } from 'components/table/styles'
import { format, parseISO } from 'date-fns'

export const ImportTable = (): ReactElement => {
  const dispatch = useAppDispatch()
  const { imports, fetchState, pageSize, pageNumber } = useAppSelector(
    (state) => state.directoryImport,
  )

  const [expandedRowIds, setExpandedRowIds] = useState<string[]>([])

  useEffect(() => {
    dispatch(actions.initialise())
    dispatch(loadImports())
  }, [dispatch])

  const onLoadMoreImports = () => {
    dispatch(actions.setPageNumber(pageNumber + 1))
    dispatch(loadImports())
  }

  const expandRow = (id: string) => {
    setExpandedRowIds([...expandedRowIds, id])
  }

  const collapseRow = (id: string) => {
    setExpandedRowIds(expandedRowIds.filter((x) => x !== id))
  }

  const rowIsExpanded = (row: IDicosImport) =>
    expandedRowIds.find((x) => x === row.id)

  return (
    <div>
      <ExpandableTable subColumns={5}>
        <div className="row header">
          <span>Date</span>
          <span>Complete</span>
          <span>Files</span>
          <span></span>
        </div>

        {imports.map((value, index) => (
          <div className="row" key={index}>
            <span>
              {format(parseISO(value.dateCreated), 'dd/MM/yyyy HH:mm')}
            </span>
            <span>{`${value.complete}`}</span>
            <span>{value.dicosImportFiles.length}</span>
            <span>
              {!rowIsExpanded(value) && (
                <ChevronDownIcon
                  width={18}
                  height={18}
                  color={colours.disabled}
                  onClick={() => expandRow(value.id)}
                />
              )}
              {rowIsExpanded(value) && (
                <ChevronUpIcon
                  width={18}
                  height={18}
                  color={colours.disabled}
                  onClick={() => collapseRow(value.id)}
                />
              )}
            </span>

            {rowIsExpanded(value) && (
              <div className="sub-row">
                <div className="row header">
                  <span>File Name</span>
                  <span>Source Bucket</span>
                  <span>Source Folder</span>
                  <span>Render</span>
                  <span>Status</span>
                </div>

                {value.dicosImportFiles.map((file, fileIndex) => (
                  <div className="row" key={`sub-${fileIndex}`}>
                    <span>{file.fileName}</span>
                    <span>{file.sourceBucket}</span>
                    <span>{file.sourceFolder}</span>
                    <span>{file.renderParameterName}</span>
                    <span>{ImportStatusMapper.get(file.status)}</span>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </ExpandableTable>
      {fetchState.loading ? (
        <Spinner size="80px" />
      ) : (
        <>
          {imports.length % pageSize === 0 && (
            <PagingButton onClick={onLoadMoreImports}>Load More</PagingButton>
          )}
        </>
      )}
    </div>
  )
}
