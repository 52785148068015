import { Button } from 'components/button/styles'
import { useAppDispatch, useAppSelector } from 'infrastructure/hooks'
import { routes } from 'infrastructure/routes'
import { IImage } from 'infrastructure/types/image'
import { ReactElement, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { loadImages } from 'views/gallery/galleryActions'
import { actions } from 'views/gallery/galleryReducer'
import { loadImage } from 'views/image/imageActions'
import { ButtonContainer } from './styles'

export interface INavigationButtonsProps {
  image: IImage | null
}

export const NavigationButtons = (
  props: INavigationButtonsProps,
): ReactElement => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const { id } = useParams()
  const { images, pageNumber } = useAppSelector((state) => state.gallery)
  const { image } = props
  const [nextIndex, setNextIndex] = useState(-1)

  const currentImageIndex = images.findIndex((image) => image.imageId === id)

  const navigateToNextImage = async () => {
    const nextIndex = currentImageIndex + 1
    if (nextIndex >= images.length) {
      dispatch(actions.setPageNumber(pageNumber + 1))
      await dispatch(loadImages())
    }
    setNextIndex(nextIndex)
  }

  const navigateToPreviousImage = async () => {
    const prevIndex = currentImageIndex - 1
    if (prevIndex < 0 && pageNumber > 0) {
      dispatch(actions.setPageNumber(pageNumber - 1))
      await dispatch(loadImages())
    }
    setNextIndex(prevIndex)
  }

  const loadSelectedImage = (index: number) => {
    if (index > images.length - 1) return
    const id = images[index].imageId
    navigate(`${routes.Gallery.path}/${id}`)
    dispatch(loadImage(id))
  }

  useEffect(() => {
    if (nextIndex > -1) loadSelectedImage(nextIndex)
  }, [nextIndex])

  useEffect(() => {
    const handleKeyboardNavigation = (event: KeyboardEvent) => {
      if (event.key === 'ArrowLeft') {
        navigateToPreviousImage()
      } else if (event.key === 'ArrowRight') {
        navigateToNextImage()
      }
    }

    window.addEventListener('keydown', handleKeyboardNavigation)

    return () => {
      window.removeEventListener('keydown', handleKeyboardNavigation)
    }
  }, [navigateToNextImage, navigateToPreviousImage])

  return (
    <>
      {image && images.length > 0 && (
        <ButtonContainer>
          <Button onClick={() => navigateToPreviousImage()}>{'<'}</Button>
          <Button onClick={() => navigateToNextImage()}>{'>'}</Button>
        </ButtonContainer>
      )}
    </>
  )
}
