import { v4 as uuidv4 } from 'uuid'
import {
  IImageLabelResponse,
  IImageResponse,
} from 'infrastructure/api/response/image'
import {
  IImageLabelRequest,
  IImageRequest,
} from 'infrastructure/api/request/image'

export interface IImage {
  imageId: string
  dicosId: string
  name: string
  location: string
  dicosLocation: string
  renderOrientationX: number
  renderOrientationY: number
  renderOrientationZ: number
  active: boolean
  labels: IImageLabel[]
  tags: string[]
  version: string
}

export interface IImageLabel {
  id: string
  labelId: string
  x1: number
  y1: number
  x2: number
  y2: number
  verified: boolean
}

export const MapImagesResponseToImages = (
  response: IImageResponse[],
): IImage[] => response.map((item) => MapImageResponseToImage(item))

export const MapImageResponseToImage = (response: IImageResponse): IImage => ({
  imageId: response.imageId,
  dicosId: response.dicosId,
  name: response.name,
  location: response.location,
  dicosLocation: response.dicosLocation,
  renderOrientationX: response.renderOrientationX,
  renderOrientationY: response.renderOrientationY,
  renderOrientationZ: response.renderOrientationZ,
  active: response.active,
  labels: MapImageLabelsResponseToLabels(response.labels),
  tags: response.tags,
  version: response.version,
})

export const MapImageLabelsResponseToLabels = (
  response: IImageLabelResponse[],
): IImageLabel[] =>
  response.map((item) => ({
    id: uuidv4(),
    labelId: item.labelId,
    verified: item.verified,
    x1: item.x1,
    y1: item.y1,
    x2: item.x2,
    y2: item.y2,
  }))

export const MapImageToImageRequest = (model: IImage): IImageRequest => ({
  imageId: model.imageId,
  active: model.active,
  labels: MapImageLabelsToRequest(model.imageId, model.labels),
  tags: model.tags,
  version: model.version,
})

export const MapImageLabelsToRequest = (
  imageId: string,
  model: IImageLabel[],
): IImageLabelRequest[] =>
  model.map((item) => ({
    imageId: imageId,
    labelId: item.labelId,
    verified: item.verified,
    x1: item.x1,
    y1: item.y1,
    x2: item.x2,
    y2: item.y2,
  }))
